import * as actions from '@actions';
import { useQuery } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxStore } from 'store';

type Dispatcher = ReturnType<typeof useDispatch>;
type User = ReduxStore['auth']['user'];

async function fetchPolicies(user: User, dispatch: Dispatcher) {
  if (!user) return undefined;
  const data = await dispatch(actions.userPolicy('USER_POLICY'));
  return data?.payload;
}

export function usePolicies() {
  const { user } = useSelector((state: ReduxStore) => state.auth);
  const dispatch = useDispatch();
  const { data: policies, refetch } = useQuery(['usePolicies', user], () => fetchPolicies(user, dispatch), {
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: !!user
  });

  return {
    refetch,
    policies,
    policiesAccepted: user?.policies_accepted
  };
}

export default usePolicies;
