import React, { useState } from 'react';
import { Modal, Button, Row, Input } from 'antd';
import * as HAR from 'har-format';

interface DumpModalProps {
  visible: boolean;
  onCancel: () => void;
  har?: HAR.Har;
}

const DumpModal = ({ visible, onCancel, har }: DumpModalProps) => {
  const [filename, setFilename] = useState('');
  return (
    <Modal title="Dump HAR file" open={visible} onCancel={onCancel} footer={[]}>
      <>
        <Row>
          Please confirm the filename to download to:
          <Input
            type="text"
            autoComplete="off"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFilename(e.target.value);
            }}
          />
        </Row>
        <Row style={{ width: '100%', marginTop: '16px' }}>
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <Button
              key="close"
              style={{ marginRight: '8px' }}
              onClick={() => {
                onCancel();
              }}
              data-testid="close-button"
            >
              Close
            </Button>
            <Button key="download-har" type="primary" data-testid="download-har-button">
              <a href={`data:text/json;charset=utf-8,${JSON.stringify(har)}`} download={`${filename || 'new'}.har`}>
                Download HAR
              </a>
            </Button>
          </div>
        </Row>
      </>
    </Modal>
  );
};

export default DumpModal;
