import { useState, useEffect, useMemo, useCallback } from 'react';
import { Mutex } from 'async-mutex';

// WebKit is very picky about which events are acceptable to start audio: https://arc.net/l/quote/mfxflsxz
const InteractionStartEvents = ['touchend', 'click', 'doubleclick', 'keydown'];

// TODO - the "playing" return is a state variable which will cause re-renders. I don't think we use it so we should
// remove it.
const useAudio = (url: string, volume = 1): [playing: boolean, play: () => void, pause: () => void] => {
  const audio = useMemo(() => new Audio(url), [url]);
  const playPauseMutex = useMemo(() => new Mutex(), []);
  const [playing, setPlaying] = useState(false);
  const [audioInitialised, setAudioInitialised] = useState(false);

  useEffect(() => {
    // If the ringer is playing whilst the user does a refresh it can (sometimes) continue to play even *after* the
    // refresh. This ensures that it stops playing *before* the page starts refreshing.
    // NB: React does not unmount when you refresh!
    window.addEventListener('beforeunload', () => {
      // this is the only way to reliably stop audio playing
      audio.src = '';
    });
  }, [audio]);

  useEffect(() => {
    if (audio instanceof HTMLMediaElement && 'loop' in audio && 'volume' in audio) {
      audio.loop = true;
      audio.volume = volume;
    }
  }, [audio, volume]);

  /** A quick, silent, play() followed immediately by a pause() */
  const blip = useCallback(
    () =>
      playPauseMutex.runExclusive(async () => {
        audio.muted = true;
        await audio.play();
        audio.pause();
        audio.currentTime = 0;
        audio.muted = false;
      }),
    [audio, playPauseMutex]
  );

  // This effect adds a global event listener on 'touchend', 'click', 'doubleclick' and 'keydown' events so that they
  // start to play a sound. This then means that we are allowed to play "unsolicited" sounds (like the clinician ringer)
  // from then on. Note that the first such event will also set the 'audioInitialised' state variable which
  // causes the effect to re-trigger *after* executing the cleanup of the original execution, which un-registers all
  // the event handlers. The net effect is that the audio should play just once per useAudio instance. And the user will
  // never hear anything since (i) the audio is muted and (ii) we stop as soon as we start.
  useEffect(() => {
    if (audioInitialised) return () => {};

    const audioInitialiser = async () => {
      await blip();
      setAudioInitialised(true);
    };

    InteractionStartEvents.forEach((type) => document.body.addEventListener(type, audioInitialiser));
    return () => InteractionStartEvents.forEach((type) => document.body.removeEventListener(type, audioInitialiser));
  }, [audioInitialised, blip]);

  const play = useCallback(
    () =>
      playPauseMutex.runExclusive(async () => {
        audio.muted = false;
        await audio.play();
        setPlaying(true);
      }),
    [audio, playPauseMutex]
  );

  const pause = useCallback(
    () =>
      playPauseMutex.runExclusive(async () => {
        audio.muted = true;
        audio.pause();
        setPlaying(false);
      }),
    [audio, playPauseMutex]
  );

  // ensure that "playing" is reset when the audio completes playing
  useEffect(() => {
    function resetPlaying() {
      setPlaying(false);
    }
    audio.addEventListener('ended', resetPlaying);
    return () => audio.removeEventListener('ended', resetPlaying);
  }, [audio]);

  // ensure that the audio stops playing if/when this component unmounts
  // see https://developer.mozilla.org/en-US/docs/Web/API/HTMLAudioElement/Audio#memory_usage_and_management
  useEffect(() => {
    return () => {
      pause().then();
    };
  }, [pause]);

  return [playing, play, pause];
};

export default useAudio;
