import React from 'react';
import { Modal, Result, Button, ModalFuncProps, ModalProps, ResultProps } from 'antd';

type AlertModalProps = Omit<ModalProps, 'onOk' | 'footer'> & {
  'data-testid'?: String;
  subTitle?: React.ReactNode;
  resultProps?: Omit<ResultProps, 'extra' | 'status' | 'subtitle'>;
};

export const AlertModal = ({
  title,
  subTitle = null,
  onCancel,
  cancelText = 'OK',
  'data-testid': dataTestid,
  resultProps,
  ...rest
}: AlertModalProps) => (
  <Modal centered footer={null} closable={false} onCancel={onCancel} {...rest}>
    <Result
      status="info"
      title={title}
      subTitle={subTitle}
      extra={[
        <Button key="submit" onClick={onCancel} data-testid={dataTestid}>
          {cancelText}
        </Button>
      ]}
      {...resultProps}
    />
  </Modal>
);

export const alertModal = ({ ...otherProps }: ModalFuncProps) =>
  Modal.warning({
    centered: true,
    ...otherProps
  });
