import React from 'react';
import styled from '@emotion/styled';

const StyledNHSLoginButton = styled.button`
  border: none;
  cursor: pointer;
  position: relative;
  background: #005eb8;
  padding: 10px 12px;
  border-radius: 3px;
  box-shadow: 0 3px 0 0 #002f5c;

  img {
    margin: 0 auto;
    height: 27px;
    width: 201px;
  }

  &:before {
    background: 0 0;
    bottom: -6px;
    content: '';
    display: block;
    left: -2px;
    position: absolute;
    right: -2px;
  }

  &:hover {
    background: #003f7b;
  }

  &:active,
  &:focus:active,
  &:focus:visited {
    background: #002f5c;
    box-shadow: none;
    top: 4px;
  }

  &:active::before {
    top: -6px;
  }

  &[data-visited],
  &:focus:not(:active) {
    outline: none;
    background: #ffeb3b;
    box-shadow: 0 4px 0 #212b32;

    img {
      filter: invert(1);
      opacity: 0.9;
    }
  }

  @media (min-width: 768px) {
    padding: 14px 16px;
    border-radius: 4px;
    box-shadow: 0 4px 0 0 #002f5c;
  }
`;

const NHSLoginButton = ({
  onClick,
  ...buttonProps
}: Omit<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, 'type' | 'ref'>) => {
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const wrappedOnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (buttonRef.current) buttonRef.current.toggleAttribute('data-visited');
    if (onClick) onClick(e);

    return null;
  };

  return (
    <StyledNHSLoginButton
      type="button"
      data-testid="nhs-login-button"
      ref={buttonRef}
      onClick={wrappedOnClick}
      {...buttonProps}
    >
      <img src="/static/images/nhs/nhslogin.svg" width={201} height={27} alt="Continue to NHS login" />
    </StyledNHSLoginButton>
  );
};

export default NHSLoginButton;
