import React from 'react';
import formatDate, { HBSFormatToDateFnsFormat } from '../../lib/formatDate';

interface DatestampProps {
  date: Date;
  locale?: string;
  format: keyof typeof HBSFormatToDateFnsFormat;
  timezone?: string;
}

export const Datestamp: React.FC<DatestampProps> = ({ date, format, locale, timezone }) => {
  const utcDate = date instanceof Date ? date.toISOString() : date;
  return (
    <time className="datetime" dateTime={utcDate} title={utcDate}>
      {formatDate(date, format, locale, timezone)}
    </time>
  );
};

export default Datestamp;
