import useMyRole from '@hooks/useMyRole';
import useAppointmentStatus from '@hooks/useAppointmentStatus';

type Appointment = any;

export type PartyStatus = 'connected' | 'disconnected' | 'in-session' | 'testing-webcam';
export type TwilioEventStatus = 'USRPOPUP' | 'USRWAIT' | 'USRCONN' | 'USRENDED' | 'CAEND' | 'CASTART';
export const patientStatusToTwilioEvent: Record<PartyStatus, TwilioEventStatus> = {
  // @ts-ignore
  none: '',
  upcoming: 'USRPOPUP',
  waiting: 'USRWAIT',
  'in-call': 'USRCONN',
  'call-over': 'USRENDED'
};
export const clinicianStatusToTwilioEvent: Record<PartyStatus, TwilioEventStatus> = {
  // @ts-ignore
  none: '',
  upcoming: 'USRPOPUP',
  waiting: 'USRWAIT',
  'in-call': 'CASTART',
  'call-over': 'CAEND'
};

/**
 * Returns the "realtime" status of the other party in an appointment.
 * Initially read from the backend and updated via the Twilio WebSocket.
 *
 * @param appointment
 * @param role
 * @returns other-party-status
 */
export function usePartyStatus(appointment: Appointment, role): PartyStatus {
  const { clinicianStatus, patientStatus } = useAppointmentStatus(appointment);
  const status = role === 'patient' ? patientStatus : clinicianStatus;

  return status.status;
}

function useOtherPartyStatus(appointment: Appointment): PartyStatus {
  const role = useMyRole();

  return usePartyStatus(appointment, role === 'clinician' ? 'patient' : 'clinician');
}

export default useOtherPartyStatus;
