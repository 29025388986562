import SurveySubmissionModal from './SurveySubmissionModal';

export { SurveySubmissionModal };
export default { SurveySubmissionModal };

export { AlertModal, alertModal } from './CustomModals/Alert';
export { ConfirmationModal, confirmationModal } from './CustomModals/Confirmation';
export { ErrorModal, errorModal } from './CustomModals/Error';
export { InfoModal, infoModal } from './CustomModals/Info';
export { OptionsModal } from './CustomModals/Options';
export { SuccessModal, successModal } from './CustomModals/Success';
