import useAuth from '@vl-core/useAuth';

export function useUser() {
  const { user } = useAuth();

  if (!user) {
    throw Error('User record expected to have been set');
  }

  return user;
}

export default useUser;
