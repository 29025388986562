import * as actionTypes from '@actionTypes';
import { updateObject } from '@helpers/updateObject';

/* Initial */
export const initialState = {
  loading: false,
  error: null,
  timeline: null,
  appointmentActive: null,
  activeFilterAP: null,
  completeFilterAP: null,
  newFilterCases: null,
  activeFilterCases: null,
  completeFilterCases: null,
  notes: [],
  completedTasks: [],
  openTasks: [],
  tasks: []
};

const requestFail = (state, action = undefined) =>
  updateObject(state, {
    loading: false,
    action
  });

const cliniciansOpenTasks = (state, action) =>
  updateObject(state, {
    loading: false,
    completedTasks: action.payload
  });

const cliniciansCompletedTasks = (state, action) =>
  updateObject(state, {
    loading: false,
    openTasks: action.payload
  });

const clinicianAPActive = (state, action) =>
  updateObject(state, {
    loading: false,
    activeFilterAP: action.payload
  });

const clinicianAPComplete = (state, action) =>
  updateObject(state, {
    loading: false,
    completeFilterAP: action.payload
  });

const cliniciansTimelineCase = (state, action) =>
  updateObject(state, {
    loading: false,
    timelineCase: action.payload
  });

const createTask = (state, action) =>
  updateObject(state, {
    loading: false,
    tasks: action.payload
  });

const clinicianCaseNew = (state, action) =>
  updateObject(state, {
    loading: false,
    newFilterCases: action.payload
  });

const clinicianCaseActive = (state, action) =>
  updateObject(state, {
    loading: false,
    activeFilterCases: action.payload
  });

const clinicianCaseClosed = (state, action) =>
  updateObject(state, {
    loading: false,
    closedFilterCases: action.payload
  });

/* Reducer */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLINICIANS_SATISFACTION_SCORE_FAIL:
    case actionTypes.CLINICIANS_APPOINTMENT_ACTIVE_FAIL:
    case actionTypes.CLINICIANS_APPOINTMENT_COMPLETE_FAIL:
    case actionTypes.CLINICIANS_NOTE_FAIL:
    case actionTypes.CLINICIANS_TIMELINECASE_FAIL:
    case actionTypes.CLINICIANS_GENERAL_QUESTIONS_FAIL:
    case actionTypes.CLINICIANS_OPEN_TASKS_FAIL:
    case actionTypes.CREATE_TASK_FAIL:
    case actionTypes.CLINICIANS_COMPLETED_TASKS_FAIL:
    case actionTypes.CLINICIANS_CASE_NEW_FAIL:
    case actionTypes.CLINICIANS_CASE_ACTIVE_FAIL:
    case actionTypes.CLINICIANS_CASE_CLOSED_FAIL:
      return requestFail(action);
    case actionTypes.CLINICIANS_APPOINTMENT_ACTIVE_SUCCESS:
      return clinicianAPActive(state, action);
    case actionTypes.CLINICIANS_APPOINTMENT_COMPLETE_SUCCESS:
      return clinicianAPComplete(state, action);
    case actionTypes.CLINICIANS_TIMELINECASE_SUCCESS:
      return cliniciansTimelineCase(state, action);
    case actionTypes.CLINICIANS_OPEN_TASKS_SUCCESS:
      return cliniciansOpenTasks(state, action);
    case actionTypes.CLINICIANS_COMPLETED_TASKS_SUCCESS:
      return cliniciansCompletedTasks(state, action);
    case actionTypes.CREATE_TASK_SUCCESS:
      return createTask(state, action);
    case actionTypes.CLINICIANS_CASE_NEW_SUCCESS:
      return clinicianCaseNew(state, action);
    case actionTypes.CLINICIANS_CASE_ACTIVE_SUCCESS:
      return clinicianCaseActive(state, action);
    case actionTypes.CLINICIANS_CASE_CLOSED_SUCCESS:
      return clinicianCaseClosed(state, action);

    default:
      return state;
  }
};

export default reducer;
