import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

export function initialise(basePath: string) {
  return i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
      lng: 'en',
      ns: ['common'],
      defaultNS: 'common',
      backend: {
        loadPath: `${basePath}/locales/{{lng}}/{{ns}}.json`
      },
      partialBundledLanguages: true,
      debug: false,
      load: 'languageOnly',
      fallbackLng: 'en',
      keySeparator: false,
      interpolation: {
        escapeValue: false // react already safes from xss
      }
    });
}

export default i18n;
