import { useSelector } from 'react-redux';
import { ReduxStore } from 'store';

export default function useIsRestricted(unknownDefault: boolean = false) {
  const { user, isRestricted } = useSelector((state: ReduxStore) => state.auth);

  // since the isRestricted flag is set at the same time as the user record, if the user isn't set then neither is
  // the isRestricted flag. Therefore, return the unknown default.
  if (!user) {
    return unknownDefault;
  }

  return !!isRestricted;
}
