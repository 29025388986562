import { z } from 'zod';

/** Catch validation errors and return the input as is */
export const safeParse = <T>(input: unknown, schema: z.ZodType<T>) => {
  try {
    return schema.parse(input);
  } catch (e) {
    console.error(e);
    return input as z.infer<typeof schema>;
  }
};
