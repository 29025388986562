import { useCallback, useMemo } from 'react';
import formatDate from 'vl-common/src/lib/formatDate';
import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';
import _ from 'lodash';
import { hooks } from '@vl-core/api';

/** Return a list of appointments along with the call statuses */
export default function useTodaysAppointments(refetchInterval: false | number = false /** in ms */) {
  const {
    data: appointments,
    refetch: refetchAppointments,
    isSuccess,
    isLoading
  } = hooks.useGetBookingAppointments(
    {
      queries: {
        sort: 'datetime',
        date_start: formatDate(startOfDay(new Date()), 'Date & Time (short ISO 1806) - 2022-03-02T17:19Z'),
        date_end: formatDate(endOfDay(new Date()), 'Date & Time (short ISO 1806) - 2022-03-02T17:19Z'),
        cancelled: 'false'
      }
    },
    {
      staleTime: Infinity,
      refetchInterval,
      placeholderData: []
    }
  );

  const { data: statuses, refetch: refetchStatuses } = hooks.useGetBookingAppointmentStatus(undefined, {
    staleTime: Infinity,
    refetchInterval,
    placeholderData: []
  });

  const refetch = useCallback(
    () => Promise.all([refetchAppointments(), refetchStatuses()]),
    [refetchAppointments, refetchStatuses]
  );

  const mergedAppointments = useMemo(() => {
    const statusByAppointmentId = _.keyBy(statuses, 'appt_id');

    if (!appointments) return [];

    return appointments
      .filter(({ appointment_id }) => statusByAppointmentId[appointment_id])
      .map((a) => ({ ...a, ...statusByAppointmentId[a.appointment_id] }));
  }, [appointments, statuses]);

  return {
    appointments: mergedAppointments,
    refetchAppointments: refetch,
    isSuccess,
    isLoading
  };
}
