import * as actions from '@actions';
import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import getConfig from 'next/config';
import useAuth from '@vl-core/useAuth';

export type Article = {
  title: string;
  body: string;
};

function unifyArticle(
  article: { title: string; body: { view: { value: string } } } | { title: string; template_body: string },
  fromConfluence = true
): Article | undefined {
  if (fromConfluence && 'body' in article) {
    return { title: article.title, body: article.body.view.value };
  }

  if ('template_body' in article) {
    return { title: article.title, body: article.template_body };
  }

  return undefined;
}

const commonCategories = ['cookies', 'accessibility', 'regulatory', 'services', 'restricted_access_services'];

async function fetchArticle(
  user: {
    policies_accepted: boolean;
    user_type_code: string;
  },
  category: string,
  dispatch: ReturnType<typeof useDispatch>
) {
  const { publicRuntimeConfig } = getConfig();
  const shouldUseConfluence = publicRuntimeConfig.CONFLUENCE === '1';

  if (user && !commonCategories.includes(category)) {
    const { policies_accepted, user_type_code } = user;

    if (policies_accepted || user_type_code === 'CLINICIAN') {
      const article = await dispatch(actions.getArticleNo(category));

      if (article?.confluence?.length) {
        if (shouldUseConfluence) {
          return unifyArticle(await dispatch(actions.fetchArticle(article.confluence[0].confluence_id)));
        }
        return unifyArticle(
          await dispatch(actions.fetchArticleFromDB(article.confluence[0].content_template_code)),
          false
        );
      }
    }
  }

  try {
    if (shouldUseConfluence) {
      return unifyArticle(await dispatch(actions.fetchArticleNoAuth(category)));
    }
    return unifyArticle(await dispatch(actions.fetchArticleNoAuthFromDB(category)), false);
  } catch {
    return undefined;
  }
}

export default function useArticle(category: string, noAuthFallback = false): Article | undefined {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const result = useQuery(
    ['useArticle', category],
    () => fetchArticle(user || { policies_accepted: false, user_type_code: '' }, category, dispatch),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!user || noAuthFallback
    }
  );

  return result.data;
}
