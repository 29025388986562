import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTwilioWS } from '@hooks/twilioWS';
import useMyRole from '@hooks/useMyRole';
import { Appointment } from 'vl-common/src/schemas/shared/appointment';

/**
 * Custom hook (for clinician use only) which, reminiscent of useState(), returns a two element
 * list of delay (in minutes) and an updater function (which takes no arguments). The implementation
 * handles the heavy lifting of updating the backend and informing the patient whenever the delay changes.
 * The first time the updater function is called the backend adds a 5 minute delay to the appointment. The
 * second time the function is called an additional 5 minutes is added, after which no further delay can be
 * added.
 *
 * @param appointment
 * @returns [delayInMinutes, setDelayInMinutes]
 */
function useCancelledNotification(appointment: Appointment): [boolean, () => void] {
  const { status, appointment_id } = appointment;
  const role = useMyRole();
  const [cancelled, setCancelled] = useState(status === 'Cancelled');

  useEffect(() => {
    setCancelled(status === 'Cancelled');
  }, [appointment_id]);

  const onControlMessage = useCallback((msg: Record<string, any>) => {
    if ('appointmentCancelled' in msg) {
      setCancelled(msg.appointmentCancelled);
    }
  }, []);
  const { sendControlMessage, open } = useTwilioWS(
    appointment,
    useMemo(() => ({ onControlMessage }), [onControlMessage])
  );
  const nullUpdater = useCallback(() => {}, []);
  const updater = useCallback(() => {
    if (open && role === 'clinician') {
      sendControlMessage({ appointmentCancelled: true });
    }
  }, [sendControlMessage, open, role]);

  return [cancelled, role === 'clinician' ? updater : nullUpdater];
}

export default useCancelledNotification;
