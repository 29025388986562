import { css } from '@emotion/react';
import { Button } from 'antd';
import React, { useEffect, useState } from 'react';

interface CookieBannerProps {
  acceptCookies: (acceptedCookies?: string[]) => void;
  setSettingsOpen: (open: boolean) => void;
  setBannerOpen: (open: boolean) => void;
  bannerOpen: boolean;
}

const CookieBanner = ({
  acceptCookies,
  setSettingsOpen,
  setBannerOpen,
  bannerOpen
}: CookieBannerProps): JSX.Element => {
  const [bannerHasChanged, setBannerHasChanged] = useState(0);
  const acceptAllCookies = (): void => {
    acceptCookies();
  };

  useEffect(() => {
    setBannerHasChanged(bannerHasChanged + 1);
  }, [bannerOpen]);

  return (
    // eslint-disable-next-line no-nested-ternary
    <div
      className={`cookie-banner ${bannerOpen ? 'banner-show' : bannerHasChanged > 1 && 'banner-hide'}`}
      data-test-id="cookie-banner"
      css={css`
        @keyframes slidein {
          0% {
            transform: translateY(0);
          }
          100% {
            transform: translateY(calc(-200px - 1em));
          }
        }
        @keyframes slideout {
          0% {
            transform: translateY(calc(-200px - 1em));
          }
          100% {
            transform: translateY(0);
          }
        }

        position: fixed;
        bottom: -200px;
        left: 1em;
        right: 1em;
        z-index: 5000;

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1em;
        padding: 1em;
        margin: 0 auto;
        max-width: 1024px;

        border: 1px solid var(--productIron);
        background-color: white;
        animation-duration: 0.8s;
        animation-delay: 0.5s;
        animation-fill-mode: both;
        border-radius: 4px;
        box-shadow: 0 2px 5px rgb(5 0 56 / 5%);

        @media (min-width: 768px) {
          flex-direction: row;
          justify-content: space-between;
        }

        .cookie-buttons {
          display: grid;
          gap: 0.5em;
          align-items: center;
          justify-items: stretch;
          width: 100%;

          @media (min-width: 425px) {
            grid-template-columns: 1fr 1fr;
          }
          @media (min-width: 768px) {
            grid-template-columns: 1fr;
            width: auto;
          }
          @media (min-width: 991px) {
            grid-template-columns: 1fr 1fr;
          }
        }

        &.banner-show {
          animation-name: slidein;
          animation-delay: ${bannerHasChanged > 1 && '0s'};
        }

        &.banner-hide {
          animation-delay: 0s;
          animation-name: slideout;
          animation-fill-mode: forward;
        }
      `}
    >
      <div>
        By clicking 'Accept All Cookies', you agree to the storing of cookies on your device to enhance site navigation
        and analyse site usage.
      </div>
      <div className="cookie-buttons">
        <Button
          tabIndex={bannerOpen ? 0 : -1}
          onClick={() => {
            setSettingsOpen(true);
            setBannerOpen(false);
          }}
          data-testid="cookie-settings"
        >
          Cookie Settings
        </Button>
        <Button
          onClick={acceptAllCookies}
          type="primary"
          tabIndex={bannerOpen ? 0 : -1}
          data-testid="accept-all-cookies"
        >
          Accept All Cookies
        </Button>
      </div>
    </div>
  );
};

export default CookieBanner;
