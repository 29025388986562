import React from 'react';
import { Button } from 'antd';

export type ContrastType = 'normal' | 'high';

const checkForHighContrast = (): ContrastType => {
  if (typeof window === 'undefined' || typeof sessionStorage === 'undefined') return 'normal';

  if (sessionStorage.getItem('contrast') === 'high') return 'high';
  if (sessionStorage.getItem('contrast') === 'normal') return 'normal';

  if (window.matchMedia('(prefers-contrast: more)').matches) return 'high';

  return 'normal';
};

const isNoSessionStorageSetting = () => {
  if (typeof window === 'undefined' || typeof sessionStorage === 'undefined') return true;
  return sessionStorage.getItem('contrast') === null;
};

export const toggleContrast = () => {
  if (typeof window === 'undefined' || typeof sessionStorage === 'undefined') return;

  const currentContrast = checkForHighContrast();

  if (sessionStorage.getItem('contrast') === 'high' || (currentContrast === 'high' && isNoSessionStorageSetting())) {
    sessionStorage.setItem('contrast', 'normal');
    document.body.setAttribute('data-contrast', 'normal');
    return;
  }

  sessionStorage.setItem('contrast', 'high');
  document.body.setAttribute('data-contrast', 'high');
};

export const AccessibilityContainer = () => (
  <Button data-testid="accessibility-button-container" onClick={() => toggleContrast()} size="small">
    Contrast
  </Button>
);

export default AccessibilityContainer;
