import React from 'react';
import { useRuntimeConfig } from '@vl-core/hooks/useConfig';
import { Button } from 'antd';
import { Auth, Amplify } from 'aws-amplify';
import { Config } from 'vl-common/src/hooks/Runtime';
import NHSLoginButton from './NHSLoginButton';

const IdentityProviderItem = ({ message, children }: { message?: string; children: React.ReactNode }) => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    {children}
    <div style={{ margin: '0.75em 0 0 0', textAlign: 'center', fontSize: '0.8rem' }}>{message}</div>
  </div>
);

type IdentityProviders = Exclude<Config['IDENTITY_PROVIDERS'], null>['providers'][0];

/** SSO login button that abstracts away the specific nature of NHS Login */
const IdentityProviderButton = ({ identityProvider }: { identityProvider: Exclude<IdentityProviders, undefined> }) => {
  const { label, message, provider } = identityProvider;
  const { APP_CLIENT_ID } = useRuntimeConfig();

  const onClick = async () => {
    if (sessionStorage.getItem('restricted')) {
      sessionStorage.removeItem('restricted');
      Amplify.configure({ userPoolWebClientId: APP_CLIENT_ID });
    }
    // @ts-ignore
    return Auth.federatedSignIn({ provider });
  };

  if (provider === 'NHSLogin') {
    return (
      <IdentityProviderItem key={provider} message={String(message)}>
        <NHSLoginButton data-testid="nhs-login-button" {...{ onClick }} />
      </IdentityProviderItem>
    );
  }

  return (
    <IdentityProviderItem key={provider} message={String(message)}>
      <Button data-testid="sso" size="large" block type="primary" {...{ onClick }}>
        {label}
      </Button>
    </IdentityProviderItem>
  );
};

export default IdentityProviderButton;
