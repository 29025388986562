import React, { useState } from 'react';
import { Modal, Progress } from 'antd';

interface SurveySubmissionModalProps {
  modalVisible: any;
  onModalSubmission: () => void;
  setModalVisible: any;
  surveyModel: any;
  surveyRes: any;
  /**
   * If true, users are always shown a 'once submitted, cannot be edited'
   * message (even for surveys that are normally editable, e.g. general health).
   */
  neverShowEditableMessage: boolean;
  completingPercent: number;
  progressMessage?: string;
}

/** Modal component used when confirming the submission of a survey. */
function SurveySubmissionModal({
  modalVisible,
  onModalSubmission,
  setModalVisible,
  surveyModel,
  surveyRes,
  neverShowEditableMessage,
  completingPercent,
  progressMessage = ''
}: SurveySubmissionModalProps) {
  const [completingSurvey, setCompletingSurvey] = useState(false);

  const showEditableMessageWhenApplicable = !neverShowEditableMessage;

  const alwaysEditable = showEditableMessageWhenApplicable && surveyRes.always_edit;

  const handleModalCancel = () => {
    surveyModel.clear(false, true);
    setModalVisible(false);
  };

  const handleModalConfirm = async () => {
    setCompletingSurvey(true);

    try {
      await onModalSubmission();
    } finally {
      setCompletingSurvey(false);
      setModalVisible(false);
    }
  };

  return (
    <Modal
      centered
      open={modalVisible}
      closable={alwaysEditable}
      onCancel={alwaysEditable && handleModalCancel}
      cancelButtonProps={
        !alwaysEditable
          ? { style: { display: 'none' }, disabled: completingPercent < 100 }
          : { disabled: completingPercent < 100 }
      }
      onOk={handleModalConfirm}
      okText={alwaysEditable ? 'Submit' : 'Continue'}
      okButtonProps={{ loading: completingSurvey, disabled: completingPercent < 100 }}
      data-testid="submission-modal-component"
    >
      {completingPercent < 100 ? (
        <>
          <Progress percent={completingPercent} />
          <p>{progressMessage}</p>
        </>
      ) : (
        <>
          {alwaysEditable
            ? 'You can come back and update your answers if anything changes. Are you happy that this assessment is complete?'
            : 'Your survey results have been submitted. All the uploaded documents are available to view in your documents.'}
        </>
      )}
    </Modal>
  );
}

export default SurveySubmissionModal;
