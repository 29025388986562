import { useEffect, useState } from 'react';

function getValue<T>(key: string, defaultValue: T) {
  const item = window.sessionStorage.getItem(key);

  return item ? JSON.parse(item) : defaultValue;
}

export function useSessionStorage<T>(key: string, defaultValue: T) {
  const [value, setValue] = useState<T>(getValue(key, defaultValue));

  // update "value" if storage changes (typically through devtools)
  useEffect(() => {
    function handleStorageEvent(ev: StorageEvent) {
      setValue((oldValue) => {
        if (ev.key === key && ev.newValue && ev.newValue !== JSON.stringify(oldValue)) {
          return JSON.parse(ev.newValue);
        }
        return oldValue;
      });
    }

    window.addEventListener('storage', handleStorageEvent);

    return () => window.removeEventListener('storage', handleStorageEvent);
  }, [key]);

  // update "value" if/when "key" changes
  useEffect(() => {
    try {
      setValue(getValue(key, defaultValue));
    } catch (e) {
      console.log(e);
    }
  }, [key, defaultValue]);

  // update localStorage when either "key" or "value" changes
  useEffect(() => {
    // I this useEffect has fired too often in the past. Be careful with updates to localStorage
    const svalue = JSON.stringify(value);
    const current = window.sessionStorage.getItem(key);

    if (current !== svalue) {
      window.sessionStorage.setItem(key, svalue);
    }
  }, [key, value]);

  return [value, setValue] as const;
}

export default useSessionStorage;
