import {
  Config,
  ConfigSchema,
  setRuntimeConfig,
  useRuntimeConfig as useRuntimeConfigFromValtio
} from 'vl-common/src/hooks/Runtime';
import { backendFetch } from '@lib/backendFetch';
import { getSettingsUrl } from 'vl-common/src/lib/getSettingsURL';

const configPromise: Promise<{ config: Config; response?: Response }> =
  typeof window === 'undefined'
    ? Promise.resolve({ config: ConfigSchema.parse({}), response: new Response() })
    : backendFetch(getSettingsUrl(), {
        method: 'GET',
        unauthenticated: true
      })
        .then(async (response) => {
          const config = ConfigSchema.parse(getUpdatedConfig(await response.json()));
          return { config, response };
        })
        .then(({ response, config }) => {
          if (!response.ok) throw Error('Could not load runtime configuration');
          const updatedConfig = ConfigSchema.parse(getUpdatedConfig(config));
          setRuntimeConfig(updatedConfig);

          return { response, config };
        });

const getDynamicConfig = async () => {
  return configPromise;
};

function useRuntimeConfig() {
  return useRuntimeConfigFromValtio();
}

function getUpdatedConfig(config: object) {
  return Object.fromEntries(Object.entries(config).map(([key, value]) => [key.toUpperCase(), value]));
}

if (typeof window !== 'undefined') {
  getDynamicConfig().then(({ response, config }) => {
    if (!response.ok) throw Error('Could not load runtime configuration');
    const updatedConfig = ConfigSchema.parse(getUpdatedConfig(config));
    return setRuntimeConfig(updatedConfig);
  });
}

export default useRuntimeConfig;
export { getDynamicConfig, useRuntimeConfig, getUpdatedConfig };
