import * as actionTypes from '@actionTypes';

export const initialState = {
  active: false,
  theirStatus: ''
};

const reducer = (state = initialState, action: { type: string; payload: unknown }) => {
  switch (action.type) {
    case actionTypes.APPOINTMENT_WEBCAM_STATE:
      return { ...state, active: action.payload };

    case actionTypes.APPOINTMENT_THEIR_STATUS:
      return { ...state, theirStatus: action.payload };

    default:
      return state;
  }
};

export default reducer;
