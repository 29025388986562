import useAppointmentStatus from '@hooks/useAppointmentStatus';

type Appointment = any;

export type CallStatus = 'not-started' | 'calling' | 'in-progress' | 'completed';
export type TwilioEventStatus =
  | 'USRPOPUP'
  | 'USRWAIT'
  | 'USRCONN'
  | 'USRENDED'
  | 'CAEND'
  | 'CASTART'
  | 'END'
  | 'START'
  | 'CACALLING'
  | 'USRDECLINED'
  | 'CADECLINED'
  | 'CACHANGED';

/**
 *
 * Returns the call status of tha appointment as controlled by the clinician (using useCallStatusUpdaters) .
 * Initially read from the backend and updated in realtime via the Twilio WebSocket.
 *
 * @param appointment
 * @returns CallStatus
 */
function useCallStatus(appointment: Appointment): CallStatus {
  const { callStatus } = useAppointmentStatus(appointment);

  return callStatus?.status || 'not-started';
}

export default useCallStatus;
