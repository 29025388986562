import { makeApi, Zodios, type ZodiosOptions } from '@zodios/core';
import { z } from 'zod';

const postAdminApptOutcome_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    appt_outcome_code: z.string().max(30),
    description: z.string().optional()
  })
  .passthrough();
const postAdminClientOutcome_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    display_order: z.number(),
    appt_outcome_code: z.string(),
    client_id: z.number(),
    assignee_client_id: z.number().optional(),
    spec_cat_code: z.string(),
    enabled: z.number(),
    granted_auth_appt_type: z.string().optional(),
    granted_auth_clin_subtype: z.string().optional(),
    task_code: z.string().optional(),
    description: z.string().optional(),
    discharge: z.number().optional(),
    subtype_code: z.string().optional(),
    discharge_code: z.string().optional(),
    gdpr_flag: z.number().optional(),
    discharge_open: z.number().optional()
  })
  .passthrough();
const postAdminCompany_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    client_id: z.number(),
    company_name: z.string().max(256),
    telephone_no: z.string().max(30).optional(),
    email: z.string().max(255).optional(),
    logo_url: z.string().max(2048).optional(),
    addline1: z.string().max(256).optional(),
    addline2: z.string().max(256).optional(),
    addline3: z.string().max(256).optional(),
    region: z.string().max(256).optional(),
    postcode: z.string().max(30).optional(),
    country: z.string().max(256).optional()
  })
  .passthrough();
const PostAdminCompanyResponse = z
  .object({ address_id: z.number().nullable(), client_id: z.number().nullable() })
  .partial()
  .passthrough();
const postAdminCompanyDisable_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    client_id: z.number(),
    enabled: z.string()
  })
  .passthrough();
const postAdminInvreqStatus_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    id: z.number(),
    ireq_state_code: z.string().max(30),
    ireq_cancel_code: z.string().max(30).optional()
  })
  .passthrough();
const GetAdminPatientsPifuResponse = z
  .object({
    appt_id: z.number().nullable(),
    case_id: z.number().nullable(),
    client_case_id: z.string().nullable(),
    date_discharged: z.string().datetime({ offset: true }).nullable(),
    description: z.string().nullable(),
    duration: z.number().nullable(),
    email: z.string().nullable(),
    expiry_date: z.string().datetime({ offset: true }).nullable(),
    patient_mobile_number: z.string().nullable(),
    patient_name: z.string().nullable(),
    patient_user_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable(),
    pifu_id: z.number().nullable(),
    pifu_outcome_code: z.string().nullable(),
    upcoming_appt_id: z.number().nullable()
  })
  .partial()
  .passthrough();
const postAdminQueue_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    cr_queue_code: z.string().max(30),
    urg_code: z.string().max(30),
    cr_low_threshold: z.number(),
    cr_high_threshold: z.number()
  })
  .passthrough();
const PostAdminQueueResponse = z
  .object({ cr_queue_code: z.string().nullable(), urg_code: z.string().nullable() })
  .partial()
  .passthrough();
const postAdminSurveyjs_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    surveyjs_def_id: z.number().optional(),
    cat_code: z.string().max(30).optional(),
    area_code: z.string().max(30).optional(),
    event_code: z.string().max(10).optional(),
    title: z.string().max(256).optional(),
    shortname: z.string().max(32).optional(),
    publish: z.string().optional(),
    always_edit: z.number().optional(),
    survey_json: z.string().optional()
  })
  .passthrough();
const PostAdminSurveyjsResponse = z.object({ survey_def_id: z.number().nullable() }).partial().passthrough();
const postAdminSurveyjsDisable_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    survey_def_id: z.number().optional(),
    enabled: z.string()
  })
  .passthrough();
const putAdminSurveyjsNew_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    cat_code: z.string().max(30),
    area_code: z.string().max(30).optional(),
    event_code: z.string().max(10).optional(),
    title: z.string().max(256).optional(),
    shortname: z.string().max(32).optional(),
    always_edit: z.number().optional()
  })
  .passthrough();
const postAdminUser_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    up_user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/)
      .optional(),
    client_id: z.number().optional(),
    title: z.string().max(128).optional(),
    first_name: z.string().max(128).optional(),
    last_name: z.string().max(128).optional(),
    email: z.string().max(256),
    user_type_code: z.string().max(100).optional(),
    user_role_code: z.string().max(100).optional(),
    employer_code: z.string().max(20).optional()
  })
  .passthrough();
const PostAdminUserResponse = z
  .object({
    up_user_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable()
  })
  .partial()
  .passthrough();
const postAdminUserDisable_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    up_user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    up_enabled: z.string()
  })
  .passthrough();
const postBookingAppointment_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    case_id: z.number(),
    date: z.string().datetime({ offset: true }),
    type: z.string().max(30),
    call_type: z.string().max(30),
    clin_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    title: z.string().max(256),
    dry_run: z.string().optional()
  })
  .passthrough();
const PostBookingAppointmentResponse = z
  .object({
    appt_id: z.number().nullable(),
    case_id: z.number().nullable(),
    clin_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable(),
    email_id: z.number().nullable(),
    pat_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable(),
    survey_id: z.number().nullable()
  })
  .partial()
  .passthrough();
const putBookingAppointmentAuth_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    case_id: z.number(),
    appt_id: z.number().optional(),
    appt_type_code: z.string().max(30).optional(),
    appt_count: z.string().max(30).optional(),
    user_subtype_code: z.string().max(100).optional(),
    from_date: z.string().datetime({ offset: true }).optional(),
    end_date: z.string().datetime({ offset: true }).optional()
  })
  .passthrough();
const PutBookingAppointmentAuthResponse = z
  .object({ auth_code: z.string().nullable(), case_id: z.number().nullable(), email_id: z.number().nullable() })
  .partial()
  .passthrough();
const putBookingAppointmentAuthRemove_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    case_id: z.number(),
    auth_cancel_code: z.string().max(30).optional(),
    availability: z.string().max(4096).optional(),
    dry_run: z.number().optional()
  })
  .passthrough();
const postBookingAppointmentCancel_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    appt_id: z.number(),
    clin_subtype: z.string().max(100).optional()
  })
  .passthrough();
const PostBookingAppointmentCancelResponse = z
  .object({ appointment_id: z.number().nullable(), email_id: z.number().nullable() })
  .partial()
  .passthrough();
const postBookingAppointmentReschedule_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    appt_id: z.number(),
    date: z.string().datetime({ offset: true }),
    clin_user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    call_type_code: z.string().max(30)
  })
  .passthrough();
const PostBookingAppointmentRescheduleResponse = z
  .object({ appt_id: z.number().nullable(), email_id: z.number().nullable() })
  .partial()
  .passthrough();
const postBookingCaseReview_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    case_id: z.number(),
    due_date: z.string().datetime({ offset: true }),
    clin_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    type: z.string().max(30).optional()
  })
  .passthrough();
const PostBookingCaseReviewResponse = z
  .object({
    appt_id: z.number().nullable(),
    case_id: z.number().nullable(),
    clin_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable(),
    pat_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable(),
    review_id: z.number().nullable()
  })
  .partial()
  .passthrough();
const postCasesDischarge_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    appt_id: z.number().optional(),
    case_id: z.number(),
    discharge_code: z.string().max(30),
    dry_run: z.string().optional()
  })
  .passthrough();
const PostCasesDischargeResponse = z
  .object({ case_id: z.number().nullable(), dry_run: z.number().nullable(), email_id: z.number().nullable() })
  .partial()
  .passthrough();
const postCasesDischargeForce_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    appt_id: z.number().optional(),
    case_id: z.number(),
    discharge_code: z.string().max(30)
  })
  .passthrough();
const postCasesFlags_Body = z
  .object({
    p_user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    appt_id: z.number().optional(),
    complex_case: z.string().optional()
  })
  .passthrough();
const putCasesNew_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    pat_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    speccat: z.string().max(30),
    specarea: z.string().max(30).optional(),
    client_case_id: z.string().max(128).optional(),
    auth_code: z.number().optional(),
    case_only: z.number().optional(),
    current_notes: z.string().optional(),
    dry_run: z.string().optional(),
    urg_code: z.string().max(30).optional()
  })
  .passthrough();
const PutCasesNewResponse = z
  .object({
    auth_code: z.string().nullable(),
    case_id: z.number().nullable(),
    needs_card_details: z.number().nullable(),
    preass_id: z.number().nullable(),
    title: z.string().nullable()
  })
  .partial()
  .passthrough();
const postCasesRef_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    case_id: z.string().max(40),
    client_case_id: z.string().max(128).optional()
  })
  .passthrough();
const postCasesReopen_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    case_id: z.number()
  })
  .passthrough();
const postCasesTriage_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    case_id: z.number(),
    outcome: z.string().max(30),
    spec_area_code: z.string().max(30).optional(),
    clin_subtype: z.string().max(100).optional(),
    discharge_code: z.string().max(30).optional()
  })
  .passthrough();
const postCasesUrgency_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    appt_id: z.number().optional(),
    case_id: z.number().optional(),
    urg_code: z.string().max(30),
    urgency_change_code: z.string().max(30)
  })
  .passthrough();
const postCliniciansApptComplete_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    appt_id: z.number(),
    prescription_id: z.string().max(128).optional()
  })
  .passthrough();
const PostCliniciansApptCompleteResponse = z
  .object({
    appt_id: z.number().nullable(),
    case_id: z.number().nullable(),
    pat_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable(),
    proms_id: z.number().nullable(),
    satis_id: z.number().nullable()
  })
  .partial()
  .passthrough();
const postCliniciansApptDelay_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    appt_id: z.number()
  })
  .passthrough();
const PostCliniciansApptDelayResponse = z
  .object({ appt_id: z.number().nullable(), case_id: z.number().nullable(), delay_minutes: z.number().nullable() })
  .partial()
  .passthrough();
const PostCliniciansApptDeleteIcdResponse = z
  .object({ appt_id: z.number().nullable(), result_message: z.string().nullable() })
  .partial()
  .passthrough();
const postCliniciansApptFactsheet_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    appt_id: z.number(),
    factsheet_id: z.number(),
    enabled: z.string().optional()
  })
  .passthrough();
const PostCliniciansApptFactsheetResponse = z
  .object({ appt_id: z.number().nullable(), case_id: z.number().nullable(), sheet_id: z.number().nullable() })
  .partial()
  .passthrough();
const postCliniciansApptIcd_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    appt_id: z.number(),
    icd_code: z.string().max(10),
    icd_description: z.string().max(250),
    icd_version: z.string().max(10)
  })
  .passthrough();
const PostCliniciansApptIcdResponse = z
  .object({
    appt_id: z.number().nullable(),
    case_id: z.number().nullable(),
    icd_code: z.string().nullable(),
    icd_description: z.string().nullable(),
    icd_version: z.string().nullable()
  })
  .partial()
  .passthrough();
const postCliniciansApptLetterfiles_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    appt_id: z.number(),
    filename: z.string().max(2000),
    letter_type: z.string().max(30),
    pdf_json: z.string().max(2048).optional(),
    ireq_id: z.number().optional()
  })
  .passthrough();
const PostCliniciansApptLetterfilesResponse = z
  .object({
    appt_id: z.number().nullable(),
    case_id: z.number().nullable(),
    filename: z.string().nullable(),
    ireq_id: z.number().nullable(),
    letter_type: z.string().nullable()
  })
  .partial()
  .passthrough();
const postCliniciansApptOutcome_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    appt_id: z.number(),
    outcome_code: z.string().max(30),
    priority: z.number().optional(),
    rtt: z.number().optional(),
    auth_granted: z.string().optional(),
    auth_granted_start_date: z.string().datetime({ offset: true }).optional()
  })
  .passthrough();
const PostCliniciansApptOutcomeResponse = z
  .object({ appt_id: z.number().nullable(), outcome_code: z.string().nullable() })
  .partial()
  .passthrough();
const postCliniciansApptPifu_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    appt_id: z.number(),
    duration: z.number(),
    self_book: z.string().optional(),
    pifu_outcome_code: z.string().max(30)
  })
  .passthrough();
const PostCliniciansApptPifuResponse = z
  .object({
    appt_id: z.number().nullable(),
    case_id: z.number().nullable(),
    duration: z.number().nullable(),
    pifu_id: z.number().nullable(),
    pifu_outcome_code: z.string().nullable(),
    self_book: z.boolean().nullable()
  })
  .partial()
  .passthrough();
const postCliniciansApptSec_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    appt_id: z.number(),
    sec_conf: z.number()
  })
  .passthrough();
const PostCliniciansApptSecResponse = z
  .object({ appt_id: z.number().nullable(), case_id: z.number().nullable(), sec_conf: z.number().nullable() })
  .partial()
  .passthrough();
const PostCliniciansApptSuggestResponse = z.object({ appt_id: z.number().nullable() }).partial().passthrough();
const postCliniciansCaseRehab_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    case_id: z.number(),
    rehab_link: z.string().max(1024)
  })
  .passthrough();
const postCliniciansDeleteInvreq_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    ireq_id: z.number().optional(),
    appt_id: z.number()
  })
  .passthrough();
const PostCliniciansDeleteInvreqResponse = z
  .object({ appt_id: z.number().nullable(), ireq_id: z.number().nullable() })
  .partial()
  .passthrough();
const PostCliniciansExtclinsRankResponse = z
  .object({
    clin_min_distance: z.number().nullable(),
    clin_user_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable(),
    rank_order: z.number().nullable()
  })
  .partial()
  .passthrough();
const postCliniciansInvreq_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    ireq_id: z.number().optional(),
    appt_id: z.number(),
    contrast_scan: z.string().optional(),
    body_region_code: z.string().max(30).optional(),
    body_side: z.string().max(30).optional(),
    urgency_code: z.string().max(30).optional(),
    reason: z.string().max(4000).optional(),
    history: z.string().max(4000).optional(),
    img_type_code: z.string().max(30)
  })
  .passthrough();
const PostCliniciansInvreqResponse = z
  .object({ hospital_id: z.number().nullable(), ireq_id: z.number().nullable() })
  .partial()
  .passthrough();
const postCliniciansInvreqProvider_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    ireq_id: z.number().optional(),
    appt_id: z.number().optional(),
    img_type_code: z.string().max(30).optional(),
    hospital_id: z.number()
  })
  .passthrough();
const PostCliniciansInvreqProviderResponse = z
  .object({ ireq_id: z.number().nullable(), surveyjs_id: z.number().nullable() })
  .partial()
  .passthrough();
const postCliniciansSigpharm_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    prescriber_id: z.string(),
    secure_pin: z.string()
  })
  .passthrough();
const postFilesApptFilesRemove_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    file_id: z.number()
  })
  .passthrough();
const PostFilesApptFilesRemoveResponse = z.object({ file_id: z.number().nullable() }).partial().passthrough();
const postFilesApptFilesUpload_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    appt_id: z.number(),
    filename: z.string().max(2000),
    file_type: z.string().max(20).optional()
  })
  .passthrough();
const PostFilesApptFilesUploadResponse = z
  .object({
    appt_id: z.number().nullable(),
    case_id: z.number().nullable(),
    file_id: z.number().nullable(),
    file_type: z.string().nullable(),
    filename: z.string().nullable()
  })
  .partial()
  .passthrough();
const postFilesCaseFilesUpload_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    case_id: z.number(),
    filename: z.string().max(2000),
    file_type: z.string().max(20).optional()
  })
  .passthrough();
const PostFilesCaseFilesUploadResponse = z
  .object({
    case_id: z.number().nullable(),
    file_id: z.number().nullable(),
    file_type: z.string().nullable(),
    filename: z.string().nullable()
  })
  .partial()
  .passthrough();
const putGdprRequest_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    case_id: z.number().optional(),
    appt_id: z.number().optional(),
    process_code: z.string().max(30),
    reason_code: z.string().max(30)
  })
  .passthrough();
const PutGdprRequestResponse = z.object({ email_id: z.number().nullable() }).partial().passthrough();
const putIntapiCompReady_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    appt_id: z.number(),
    COMPEND: z.string().max(30)
  })
  .passthrough();
const PutIntapiCompReadyResponse = z.object({ event_id: z.number().nullable() }).partial().passthrough();
const postIntapiReferrerReportMatch_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    pat_guid: z.string().regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    dob_match: z.number().optional(),
    name_match: z.number().optional(),
    published: z.number().optional(),
    red_flag_matches: z.number().optional(),
    raise_red_flag: z.number().optional()
  })
  .passthrough();
const putIntapiSupportEmail_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    subject: z.string().max(1024).optional(),
    email_body: z.string().max(32767).optional()
  })
  .passthrough();
const GetIntapiUsersThirdResponse = z
  .object({
    physitrack: z.number().nullable(),
    serenova: z.number().nullable(),
    stripe: z.number().nullable(),
    user_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable()
  })
  .partial()
  .passthrough();
const postMedicationCreate_Body = z
  .object({
    p_user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    appt_id: z.number(),
    vmp_id: z.number(),
    frequency: z.string().max(1024).optional(),
    duration: z.string().max(1024).optional(),
    med_id: z.number().optional(),
    prescribe: z.string().optional()
  })
  .passthrough();
const PostMedicationCreateResponse = z
  .object({
    appt_id: z.number().nullable(),
    case_id: z.number().nullable(),
    med_id: z.number().nullable(),
    operation: z.string().nullable(),
    patient_id: z.string().nullable()
  })
  .partial()
  .passthrough();
const postMedicationDisable_Body = z
  .object({
    p_user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    appt_id: z.number(),
    med_id: z.number()
  })
  .passthrough();
const PostMedicationDisableResponse = z
  .object({ appt_id: z.number().nullable(), med_id: z.number().nullable() })
  .partial()
  .passthrough();
const postNotesAppt_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    appt_id: z.number(),
    note_code: z.string().max(30),
    note_text: z.string().max(32767)
  })
  .passthrough();
const PostNotesApptResponse = z
  .object({ appt_id: z.number().nullable(), case_id: z.number().nullable(), note_code: z.string().nullable() })
  .partial()
  .passthrough();
const postNotesPatient_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    note_id: z.number().optional(),
    patient_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/)
      .optional(),
    case_id: z.number().optional(),
    appt_id: z.number().optional(),
    clin_visible: z.string().optional(),
    note_text: z.string().max(4096),
    category: z.string().max(30).optional(),
    ubrn: z.string().max(40).optional(),
    parent_id: z.number().optional()
  })
  .passthrough();
const PostNotesPatientResponse = z
  .object({ case_id: z.number().nullable(), note_id: z.number().nullable() })
  .partial()
  .passthrough();
const postOutputsDef_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    template_id: z.number(),
    title: z.string().max(1024),
    output_subject: z.string().max(1024),
    output_sms_body: z.string().max(256).optional(),
    publish: z.string().optional(),
    email_preview: z.string().optional()
  })
  .passthrough();
const PostOutputsDefResponse = z.object({ template_id: z.number().nullable() }).partial().passthrough();
const postOutputsPref_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    method: z.string().optional(),
    code: z.string().optional(),
    resend: z.string().optional(),
    preference: z.string().optional()
  })
  .passthrough();
const PostOutputsPrefResponse = z.object({ pref_notification: z.string().nullable() }).partial().passthrough();
const postOutputsSmspref_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    patient_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    tel_mobile: z.string().max(30).optional(),
    method: z.string().optional()
  })
  .passthrough();
const PostOutputsSmsprefResponse = z
  .object({
    email_id: z.number().nullable(),
    pref_notification: z.string().nullable(),
    tel_mobile_no: z.string().nullable()
  })
  .partial()
  .passthrough();
const PostProvidersGeoHospitalsResponse = z
  .object({
    addline1: z.string().nullable(),
    addline2: z.string().nullable(),
    addline3: z.string().nullable(),
    addline4: z.string().nullable(),
    api_code: z.string().nullable(),
    country: z.string().nullable(),
    distance: z.number().nullable(),
    distance_units: z.string().nullable(),
    group_name: z.string().nullable(),
    hospital_api_code: z.string().nullable(),
    hospital_code: z.string().nullable(),
    hospital_id: z.number().nullable(),
    hospital_name: z.string().nullable(),
    hospital_type_code: z.string().nullable(),
    hospital_type_desc: z.string().nullable(),
    latitude: z.number().nullable(),
    longitude: z.number().nullable(),
    postcode: z.string().nullable(),
    region: z.string().nullable()
  })
  .partial()
  .passthrough();
const postProvidersHospital_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    hospital_type_code: z.string().max(100).optional()
  })
  .passthrough();
const postProvidersHospitalDisable_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    hospital_id: z.number(),
    enabled: z.string()
  })
  .passthrough();
const postProvidersHospitalGroup_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    ods_code: z.string().max(50).optional(),
    group_name: z.string().max(255).optional(),
    phone: z.string().max(50).optional(),
    email: z.string().max(255).optional()
  })
  .passthrough();
const postProvidersHospitalGroupDisable_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    ods_code: z.string().max(50),
    enabled: z.string()
  })
  .passthrough();
const postProvidersHospitalServiceCat_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    client_id: z.number(),
    pdf_filename: z.string().max(255).optional(),
    signature_x: z.number().optional(),
    signature_y: z.number().optional(),
    signature_page: z.number().optional(),
    email: z.string().max(255).optional(),
    phone: z.string().max(50).optional(),
    enabled: z.string().optional()
  })
  .passthrough();
const postProvidersImgprovDisable_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    hospital_id: z.string().max(40),
    enabled: z.string()
  })
  .passthrough();
const postProvidersServiceProviderEnable_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    client_id: z.number(),
    service_cat_code: z.string().max(30),
    hospital_id: z.string().max(40),
    enabled: z.string()
  })
  .passthrough();
const postProxyActivate_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    proxy_id: z.number()
  })
  .passthrough();
const postProxyAuth_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    proxy_id: z.number().optional(),
    proxy_user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/)
      .optional(),
    new_user: z.string().optional(),
    authorise: z.string()
  })
  .passthrough();
const postProxyRequest_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    proxy_id: z.number().optional(),
    submit: z.string().optional(),
    membership_no: z.string().max(128).optional(),
    title: z.string().max(128).optional(),
    first_name: z.string().max(128),
    last_name: z.string().max(128),
    email: z.string().max(256),
    dob: z.string(),
    sex: z.string().max(1).optional(),
    tel_mobile: z.string().max(30).optional(),
    tel_other: z.string().max(30).optional(),
    addline1: z.string().max(256).optional(),
    addline2: z.string().max(256).optional(),
    addline3: z.string().max(256).optional(),
    addline4: z.string().max(256).optional(),
    region: z.string().max(256).optional(),
    postcode: z.string().max(30).optional(),
    country: z.string().max(256).optional()
  })
  .passthrough();
const postReferrersCaseRefer_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    appt_id: z.number().optional(),
    case_id: z.number().optional(),
    speccat: z.string().max(30),
    specarea: z.string().max(30),
    refclin_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/)
      .optional(),
    hospital_id: z.number().optional(),
    custom_name: z.string().max(256).optional(),
    custom_hospital: z.string().max(256).optional(),
    custom_email: z.string().max(256).optional(),
    reason: z.string().max(4096).optional(),
    postcode: z.string().max(30).optional(),
    ext_appt_date: z.string().datetime({ offset: true }).optional(),
    ext_appt_id: z.string().max(128).optional(),
    group: z.string().max(50).optional()
  })
  .passthrough();
const PostReferrersCaseReferResponse = z
  .object({
    case_id: z.number().nullable(),
    refclin_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable()
  })
  .partial()
  .passthrough();
const postReferrersCaseReferReject_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    appt_id: z.number().optional()
  })
  .passthrough();
const PostReferrersCaseReferRejectResponse = z.object({ case_id: z.number().nullable() }).partial().passthrough();
const putReferrersEligibility_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    pat_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    insured: z.string(),
    case_id: z.number().optional(),
    case_eligibility: z.string().optional(),
    client_case_id: z.string().max(128).optional(),
    membership_no: z.string().max(128).optional()
  })
  .passthrough();
const PutReferrersEligibilityResponse = z
  .object({ case_insured: z.boolean().nullable(), insured: z.boolean().nullable() })
  .partial()
  .passthrough();
const putReferrersMember_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    new_user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    membership_no: z.string().max(128).optional(),
    title: z.string().max(128).optional(),
    first_name: z.string().max(128),
    last_name: z.string().max(128),
    email: z.string().max(256),
    dob: z.string().optional(),
    sex: z.string().max(1).optional(),
    tel_mobile_no: z.string().max(30).optional(),
    tel_other_no: z.string().max(30).optional(),
    pref_tel: z.string().max(1),
    avatar_url: z.string().max(2048).optional(),
    addline1: z.string().max(256).optional(),
    addline2: z.string().max(256).optional(),
    addline3: z.string().max(256).optional(),
    addline4: z.string().max(256).optional(),
    region: z.string().max(256).optional(),
    postcode: z.string().max(30).optional(),
    country: z.string().max(256).optional(),
    client_id: z.number().optional(),
    self_reg: z.number().optional(),
    self_refer: z.number().optional(),
    seranova_id: z.string().max(40).optional(),
    physitrack_id: z.string().max(40).optional(),
    stripe_id: z.string().max(40).optional(),
    timezone: z.string().max(30).optional(),
    employer_code: z.string().max(20).optional()
  })
  .passthrough();
const PutReferrersMemberResponse = z
  .object({
    email: z.string().nullable(),
    new_user_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable(),
    survey_id: z.number().nullable()
  })
  .partial()
  .passthrough();
const postReferrersMemberProfile_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    up_user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    client_id: z.number().optional(),
    title: z.string().max(128).optional(),
    first_name: z.string().max(128).optional(),
    last_name: z.string().max(128).optional(),
    email: z.string().max(256).optional(),
    tel_mobile: z.string().max(30).optional(),
    tel_other: z.string().max(30).optional(),
    tel_pref: z.string().max(1).optional(),
    dob: z.string(),
    sex: z.string().max(1),
    mem_no: z.string().max(128).optional(),
    addline1: z.string().max(256).optional(),
    addline2: z.string().max(256).optional(),
    addline3: z.string().max(256).optional(),
    addline4: z.string().max(256).optional(),
    region: z.string().max(256).optional(),
    postcode: z.string().max(30).optional(),
    country: z.string().max(256).optional(),
    insured: z.string().optional(),
    self_refer: z.string().optional(),
    seranova_id: z.string().max(40).optional(),
    physitrack_id: z.string().max(40).optional(),
    stripe_id: z.string().max(40).optional(),
    timezone: z.string().max(30).optional(),
    extra_fields: z.string().optional(),
    employer_code: z.string().max(20).optional()
  })
  .passthrough();
const postSurveysCovid19_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    hier_code: z.string().max(10).optional(),
    appt_id: z.number().optional(),
    q_qa_code: z.string().max(30),
    a_qa_code: z.string().max(30),
    note_text: z.string().max(4096)
  })
  .passthrough();
const postSurveysSurveyjs_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    surveyjs_id: z.number(),
    complete: z.string(),
    response_json: z.string(),
    generated_def: z.string().optional()
  })
  .passthrough();
const PostSurveysSurveyjsResponse = z.object({ surveyjs_id: z.number().nullable() }).partial().passthrough();
const postTasksCancel_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    task_id: z.number(),
    cancel_reason: z.string().max(30)
  })
  .passthrough();
const PostTasksCancelResponse = z.object({ task_id: z.number().nullable() }).partial().passthrough();
const postTasksComplete_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    task_id: z.number()
  })
  .passthrough();
const putTasksCreate_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    patient_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/)
      .optional(),
    case_id: z.number(),
    task_type_code: z.string().max(30),
    appt_type_code: z.string().max(30),
    description: z.string().max(4096),
    affected_user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/)
      .optional(),
    assignee_user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/)
      .optional(),
    due_date: z.string().datetime({ offset: true }).optional(),
    appt_id: z.number().optional(),
    book_appt_type: z.string().max(30).optional(),
    survey_id: z.number().optional(),
    hbs_task: z.string().optional(),
    proxy_id: z.number().optional()
  })
  .passthrough();
const postTasksReassign_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    task_id: z.number(),
    assignee_user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/)
  })
  .passthrough();
const putUsersApptTwilioEvent_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    appt_id: z.number(),
    success: z.number().optional(),
    event: z.string().max(30),
    message: z.string().max(1024).optional(),
    detail1: z.string().max(1024).optional(),
    detail2: z.string().max(1024).optional()
  })
  .passthrough();
const postUsersApptTwilioId_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    appt_id: z.number(),
    twilio_id: z.string().max(128),
    call_type: z.string().optional()
  })
  .passthrough();
const PostUsersApptTwilioIdResponse = z
  .object({ appt_id: z.number().nullable(), twilio_id: z.string().nullable() })
  .partial()
  .passthrough();
const putUsersApptWsConn_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    appt_id: z.number(),
    connection_id: z.string(),
    restricted: z.number().optional()
  })
  .passthrough();
const PutUsersApptWsConnResponse = z.object({ connection_id: z.string().nullable() }).partial().passthrough();
const postUsersPolicy_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    policy_code: z.string().max(30),
    accepted: z.string().optional()
  })
  .passthrough();
const PostUsersPolicyResponse = z
  .object({
    accepted: z.boolean().nullable(),
    accepted_date: z.string().datetime({ offset: true }).nullable(),
    policy_code: z.string().nullable(),
    user_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable(),
    version_id: z.number().nullable()
  })
  .partial()
  .passthrough();
const postUsersProfile_Body = z
  .object({
    user_guid: z
      .string()
      .max(40)
      .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
    title: z.string().max(128).optional(),
    first_name: z.string().max(128).optional(),
    last_name: z.string().max(128).optional(),
    tel_mobile: z.string().max(30).optional(),
    tel_other: z.string().max(30).optional(),
    tel_pref: z.string().max(1).optional(),
    dob: z.string(),
    sex: z.string().max(1),
    mem_no: z.string().max(128).optional(),
    addline1: z.string().max(256).optional(),
    addline2: z.string().max(256).optional(),
    addline3: z.string().max(256).optional(),
    addline4: z.string().max(256).optional(),
    region: z.string().max(256).optional(),
    postcode: z.string().max(30).optional(),
    country: z.string().max(256).optional(),
    gp_title: z.string().max(128).optional(),
    gp_first_name: z.string().max(128).optional(),
    gp_last_name: z.string().max(128).optional(),
    gp_email: z.string().max(256).optional(),
    gp_ods_code: z.string().max(50).optional(),
    gp_virtual: z.number().optional(),
    reg_complete: z.number().optional(),
    seranova_id: z.string().max(40).optional(),
    physitrack_id: z.string().max(40).optional(),
    stripe_id: z.string().max(40).optional(),
    stripe_date_flag: z.number().optional(),
    physitrack_link: z.string().max(2048).optional(),
    timezone: z.string().max(30).optional(),
    extra_fields: z.string().optional(),
    gp_excluded: z.string().optional()
  })
  .passthrough();
const PostUsersProfileResponse = z
  .object({
    operation: z.string().nullable(),
    up_user_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable()
  })
  .partial()
  .passthrough();
const GetUsersWsByConnIdResponse = z
  .object({
    clinician: z.boolean().nullable(),
    connection_id: z.string().nullable(),
    created_date: z.string().datetime({ offset: true }).nullable(),
    id: z.string().nullable(),
    patient: z.boolean().nullable(),
    user_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable()
  })
  .partial()
  .passthrough();
const GetAdminAdminUsersResponse = z
  .object({
    addline1: z.string().nullable(),
    addline2: z.string().nullable(),
    addline3: z.string().nullable(),
    addline4: z.string().nullable(),
    avatar_url: z.string().nullable(),
    client_id: z.number().nullable(),
    client_name: z.string().nullable(),
    country: z.string().nullable(),
    dob: z.string().datetime({ offset: true }).nullable(),
    email: z.string().nullable(),
    employer_code: z.string().nullable(),
    enabled: z.boolean().nullable(),
    first_name: z.string().nullable(),
    full_name: z.string().nullable(),
    geo_region: z.string().nullable(),
    ident_prov_code: z.string().nullable(),
    ident_prov_desc: z.string().nullable(),
    insured: z.string().nullable(),
    job_title: z.string().nullable(),
    lang_code: z.string().nullable(),
    last_name: z.string().nullable(),
    lucy_id: z.string().nullable(),
    membership_no: z.string().nullable(),
    nhs_number: z.string().nullable(),
    postcode: z.string().nullable(),
    pref_notification: z.string().nullable(),
    provider_name: z.string().nullable(),
    region: z.string().nullable(),
    sex: z.string().nullable(),
    status: z.string().nullable(),
    tel_code_active: z.boolean().nullable(),
    tel_mobile_no: z.string().nullable(),
    tel_other_no: z.string().nullable(),
    tel_pref_no: z.string().nullable(),
    temp_password: z.boolean().nullable(),
    title: z.string().nullable(),
    user_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable(),
    user_role_code: z.string().nullable(),
    user_role_desc: z.string().nullable(),
    user_type_code: z.string().nullable(),
    validated: z.string().datetime({ offset: true }).nullable()
  })
  .partial()
  .passthrough();
const GetAdminApptOutcomesResponse = z
  .object({
    appt_outcome_code: z.string().nullable(),
    description: z.string().nullable(),
    no_outcomes: z.number().nullable()
  })
  .partial()
  .passthrough();
const postAdminCasePolicy_Body = z
  .object({ case_id: z.number(), policy_code: z.string().max(30), applied: z.string().optional() })
  .passthrough();
const GetAdminClientOutcomesResponse = z
  .object({
    assignee_client_id: z.number().nullable(),
    cat_code: z.string().nullable(),
    cat_name: z.string().nullable(),
    client_id: z.number().nullable(),
    client_name: z.string().nullable(),
    description: z.string().nullable(),
    discharge: z.boolean().nullable(),
    discharge_code: z.string().nullable(),
    discharge_desc: z.string().nullable(),
    discharge_open: z.boolean().nullable(),
    display_order: z.number().nullable(),
    enabled: z.boolean().nullable(),
    gdpr_flag: z.boolean().nullable(),
    granted_appt_desc: z.string().nullable(),
    granted_auth_appt_type: z.string().nullable(),
    granted_auth_clin_subtype: z.string().nullable(),
    outcome_code: z.string().nullable(),
    special_behaviour: z.boolean().nullable(),
    subtype_code: z.string().nullable(),
    subtype_desc: z.string().nullable(),
    task_code: z.string().nullable()
  })
  .partial()
  .passthrough();
const GetAdminCompaniesResponse = z
  .object({
    addline1: z.string().nullable(),
    addline2: z.string().nullable(),
    addline3: z.string().nullable(),
    client_code: z.string().nullable(),
    client_id: z.number().nullable(),
    company_name: z.string().nullable(),
    country: z.string().nullable(),
    email: z.string().nullable(),
    enabled: z.boolean().nullable(),
    info_tags: z.boolean().nullable(),
    logo_url: z.string().nullable(),
    nhs_client: z.boolean().nullable(),
    no_users: z.number().nullable(),
    ods_code: z.string().nullable(),
    postcode: z.string().nullable(),
    region: z.string().nullable(),
    telephone_no: z.string().nullable()
  })
  .partial()
  .passthrough();
const GetAdminEmployersResponse = z
  .object({
    available_to_admins: z.boolean().nullable(),
    available_to_clinicians: z.boolean().nullable(),
    available_to_patients: z.boolean().nullable(),
    employer_code: z.string().nullable(),
    employer_desc: z.string().nullable(),
    enabled: z.boolean().nullable(),
    is_default: z.boolean().nullable()
  })
  .partial()
  .passthrough();
const GetAdminHbsUsersResponse = z
  .object({
    addline1: z.string().nullable(),
    addline2: z.string().nullable(),
    addline3: z.string().nullable(),
    addline4: z.string().nullable(),
    avatar_url: z.string().nullable(),
    client_id: z.number().nullable(),
    client_name: z.string().nullable(),
    country: z.string().nullable(),
    dob: z.number().nullable(),
    email: z.string().nullable(),
    employer_code: z.string().nullable(),
    enabled: z.boolean().nullable(),
    first_name: z.string().nullable(),
    full_name: z.string().nullable(),
    geo_region: z.string().nullable(),
    ident_prov_code: z.string().nullable(),
    ident_prov_desc: z.string().nullable(),
    insured: z.string().nullable(),
    job_title: z.string().nullable(),
    lang_code: z.string().nullable(),
    last_name: z.string().nullable(),
    lucy_id: z.string().nullable(),
    membership_no: z.string().nullable(),
    nhs_number: z.string().nullable(),
    postcode: z.string().nullable(),
    pref_notification: z.string().nullable(),
    provider_name: z.string().nullable(),
    region: z.string().nullable(),
    sex: z.string().nullable(),
    status: z.string().nullable(),
    tel_code_active: z.boolean().nullable(),
    tel_mobile_no: z.string().nullable(),
    tel_other_no: z.string().nullable(),
    tel_pref_no: z.string().nullable(),
    temp_password: z.boolean().nullable(),
    title: z.string().nullable(),
    user_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable(),
    user_role_code: z.string().nullable(),
    user_role_desc: z.string().nullable(),
    user_type_code: z.string().nullable(),
    validated: z.string().datetime({ offset: true }).nullable()
  })
  .partial()
  .passthrough();
const postAdminInvreqApptdate_Body = z
  .object({
    id: z.number().optional(),
    appt_date: z.string().datetime({ offset: true }),
    status: z.string().max(30).optional(),
    token: z.string().max(70).optional()
  })
  .passthrough();
const PostAdminInvreqApptdateResponse = z.object({ ireq_id: z.number().nullable() }).partial().passthrough();
const GetAdminPowerbiGroupsResponse = z
  .object({
    dataset_id: z.string().nullable(),
    description: z.string().nullable(),
    group_code: z.string().nullable(),
    group_id: z.string().nullable(),
    order: z.number().nullable(),
    report_id: z.string().nullable()
  })
  .partial()
  .passthrough();
const GetAdminQueuesResponse = z
  .object({
    cr_high_threshold: z.number().nullable(),
    cr_low_threshold: z.number().nullable(),
    cr_queue_code: z.string().nullable(),
    cr_queue_desc: z.string().nullable(),
    cr_queue_label: z.string().nullable(),
    enabled: z.boolean().nullable(),
    urg_code: z.string().nullable()
  })
  .partial()
  .passthrough();
const GetAdminSpecareasResponse = z
  .object({
    area_code: z.string().nullable(),
    area_name: z.string().nullable(),
    cat_no_users: z.number().nullable(),
    category_code: z.string().nullable(),
    category_name: z.string().nullable(),
    enabled: z.boolean().nullable(),
    image_url: z.string().nullable(),
    no_users: z.number().nullable()
  })
  .partial()
  .passthrough();
const GetAdminSpeccatsResponse = z
  .object({
    cat_code: z.string().nullable(),
    cat_name: z.string().nullable(),
    enabled: z.boolean().nullable(),
    needs_card_details: z.number().nullable(),
    no_areas: z.number().nullable(),
    no_users: z.number().nullable()
  })
  .partial()
  .passthrough();
const GetAdminSurveyScheduleResponse = z
  .object({
    client_id: z.number().nullable(),
    client_name: z.string().nullable(),
    description: z.string().nullable(),
    end_date: z.string().datetime({ offset: true }).nullable(),
    event_code: z.string().nullable(),
    event_desc: z.string().nullable(),
    hours: z.number().nullable(),
    schedule_id: z.number().nullable(),
    schedule_type: z.string().nullable(),
    spec_area_code: z.string().nullable(),
    spec_area_name: z.string().nullable(),
    spec_cat_code: z.string().nullable(),
    spec_cat_name: z.string().nullable(),
    start_date: z.string().datetime({ offset: true }).nullable(),
    template_code: z.string().nullable(),
    weeks: z.number().nullable()
  })
  .partial()
  .passthrough();
const GetAdminSurveyjsDefResponse = z
  .object({
    always_edit: z.boolean().nullable(),
    area_code: z.string().nullable(),
    area_name: z.string().nullable(),
    cat_code: z.string().nullable(),
    cat_name: z.string().nullable(),
    clin_editable: z.boolean().nullable(),
    created_by: z.string().nullable(),
    current_version: z.boolean().nullable(),
    enabled: z.boolean().nullable(),
    event_code: z.string().nullable(),
    event_name: z.string().nullable(),
    published: z.boolean().nullable(),
    shortname: z.string().nullable(),
    status: z.string().nullable(),
    survey_code: z.string().nullable(),
    survey_json: z.string().nullable(),
    surveyjs_def_id: z.number().nullable(),
    title: z.string().nullable(),
    version: z.number().nullable()
  })
  .partial()
  .passthrough();
const GetBookingAppointmentSlotsResponse = z
  .object({
    dom: z.string().datetime({ offset: true }).nullable(),
    dow: z.string().nullable(),
    slots: z
      .array(
        z
          .object({
            slot_datetime: z.string().datetime({ offset: true }),
            slot_end_datetime: z.string().datetime({ offset: true }),
            clin_count: z.number().int(),
            slot_lengh_mins: z.number().int()
          })
          .partial()
          .passthrough()
      )
      .nullable()
  })
  .partial()
  .passthrough();
const GetBookingAppointmentStatusResponse = z
  .object({
    appointment_completed: z.boolean().nullable(),
    appointment_delay_mins: z.number().nullable(),
    appointment_delayed: z.boolean().nullable(),
    appt_end_date: z.string().datetime({ offset: true }).nullable(),
    appt_id: z.number().nullable(),
    appt_start_date: z.string().datetime({ offset: true }).nullable(),
    call_type_code: z.string().nullable(),
    case_id: z.number().nullable(),
    clin_audio_end: z.string().datetime({ offset: true }).nullable(),
    clin_audio_start: z.string().datetime({ offset: true }).nullable(),
    clin_end: z.string().datetime({ offset: true }).nullable(),
    clin_last_login_date: z.string().datetime({ offset: true }).nullable(),
    clin_last_logout_date: z.string().datetime({ offset: true }).nullable(),
    clin_start: z.string().datetime({ offset: true }).nullable(),
    clin_usrwait: z.string().datetime({ offset: true }).nullable(),
    clinician_user_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable(),
    pat_end: z.string().datetime({ offset: true }).nullable(),
    pat_last_login_date: z.string().datetime({ offset: true }).nullable(),
    pat_last_logout_date: z.string().datetime({ offset: true }).nullable(),
    pat_restricted: z.boolean().nullable(),
    pat_start: z.string().datetime({ offset: true }).nullable(),
    pat_usrwait: z.string().datetime({ offset: true }).nullable(),
    patient_user_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable()
  })
  .partial()
  .passthrough();
const GetBookingAppointmentsResponse = z
  .object({
    appointment_completed: z.boolean().nullable(),
    appointment_delay_mins: z.number().nullable(),
    appointment_delayed: z.boolean().nullable(),
    appointment_id: z.number().nullable(),
    appointment_outcome: z.string().nullable(),
    appointment_outcome_code: z.string().nullable(),
    appointment_refer: z.boolean().nullable(),
    appointment_title: z.string().nullable(),
    appointment_type: z.string().nullable(),
    appt_cancel_code: z.string().nullable(),
    appt_pri_desc: z.string().nullable(),
    appt_pri_no: z.number().nullable(),
    appt_rtt_desc: z.string().nullable(),
    appt_rtt_no: z.number().nullable(),
    appt_type_code: z.string().nullable(),
    appt_type_desc: z.string().nullable(),
    assignment: z.string().nullable(),
    auth_granted: z.string().nullable(),
    auth_granted_end_date: z.string().datetime({ offset: true }).nullable(),
    auth_granted_start_date: z.string().datetime({ offset: true }).nullable(),
    call_type_code: z.string().nullable(),
    call_type_desc: z.string().nullable(),
    cancelled_date: z.string().datetime({ offset: true }).nullable(),
    cancelled_reason: z.string().nullable(),
    case_created_date: z.string().datetime({ offset: true }).nullable(),
    case_id: z.number().nullable(),
    case_review: z.boolean().nullable(),
    case_spec_area: z.string().nullable(),
    case_spec_area_code: z.string().nullable(),
    case_title: z.string().nullable(),
    cat_desc: z.string().nullable(),
    client_case_id: z.string().nullable(),
    clinician: z.string().nullable(),
    clinician_avatar_url: z.string().nullable(),
    clinician_bio: z.string().nullable(),
    clinician_company: z.string().nullable(),
    clinician_spec_areas: z.string().nullable(),
    clinician_tel_mobile_no: z.string().nullable(),
    clinician_tel_other_no: z.string().nullable(),
    clinician_tel_pref_no: z.string().nullable(),
    clinician_type: z.string().nullable(),
    clinician_type_code: z.string().nullable(),
    clinician_user_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable(),
    complex_case: z.boolean().nullable(),
    condition: z.string().nullable(),
    datetime: z.string().datetime({ offset: true }).nullable(),
    end: z.string().datetime({ offset: true }).nullable(),
    gdpr_resend: z.boolean().nullable(),
    letter_saved: z.boolean().nullable(),
    lucy_id: z.string().nullable(),
    ods_code: z.string().nullable(),
    patient: z.string().nullable(),
    patient_client_code: z.string().nullable(),
    patient_client_id: z.number().nullable(),
    patient_client_name: z.string().nullable(),
    patient_nhs_client: z.boolean().nullable(),
    patient_no_title: z.string().nullable(),
    patient_postcode: z.string().nullable(),
    patient_tel_mobile_no: z.string().nullable(),
    patient_tel_other_no: z.string().nullable(),
    patient_tel_pref_no: z.string().nullable(),
    patient_title: z.string().nullable(),
    patient_user_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable(),
    prescribe_meds_flag: z.number().nullable(),
    read_only: z.boolean().nullable(),
    referral_created: z.boolean().nullable(),
    slot_duration: z.number().nullable(),
    spec_cat_code: z.string().nullable(),
    start: z.string().datetime({ offset: true }).nullable(),
    status: z.string().nullable(),
    twilio_id: z.string().nullable()
  })
  .partial()
  .passthrough();
const GetCasesResponse = z
  .object({
    appointment_date: z.string().datetime({ offset: true }).nullable(),
    appointment_id: z.number().nullable(),
    appointment_type: z.string().nullable(),
    appt_auth_available: z.boolean().nullable(),
    appt_auth_expiry: z.string().datetime({ offset: true }).nullable(),
    appt_auth_from_date: z.string().datetime({ offset: true }).nullable(),
    appt_bookable: z.boolean().nullable(),
    appt_outcome_code: z.string().nullable(),
    appt_outcome_desc: z.string().nullable(),
    appt_type_code: z.string().nullable(),
    appt_type_desc: z.string().nullable(),
    assignment: z.string().nullable(),
    auth_code: z.string().nullable(),
    cancel_flag: z.boolean().nullable(),
    case_closed_by: z.string().nullable(),
    case_closed_date: z.string().datetime({ offset: true }).nullable(),
    case_created_date: z.string().datetime({ offset: true }).nullable(),
    case_discharge_code: z.string().nullable(),
    case_discharge_desc: z.string().nullable(),
    case_eligibility: z.number().nullable(),
    case_first_app: z.boolean().nullable(),
    case_id: z.number(),
    case_spec_area: z.string().nullable(),
    case_spec_area_code: z.string().nullable(),
    case_status: z.string().nullable(),
    case_status_desc: z.string().nullable(),
    cases_dropdown: z.string().nullable(),
    cat_desc: z.string().nullable(),
    client_case_id: z.string().nullable(),
    client_id: z.number().nullable(),
    client_name: z.string().nullable(),
    clinician: z.string().nullable(),
    clinician_avatar_url: z.string().nullable(),
    clinician_type: z.string().nullable(),
    clinician_user_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable(),
    complex_case: z.boolean().nullable(),
    condition: z.string().nullable(),
    cr_overdue: z.boolean().nullable(),
    cr_survey_overdue: z.boolean().nullable(),
    datetime: z.string().datetime({ offset: true }).nullable(),
    ncaseid: z.number().nullable(),
    patient: z.string().nullable(),
    patient_appt_quota: z.number().nullable(),
    patient_appt_type_code: z.string().nullable(),
    patient_can_reopen: z.boolean().nullable(),
    patient_can_reopen_until: z.string().datetime({ offset: true }).nullable(),
    patient_clin_subtype_code: z.string().nullable(),
    patient_email: z.string().nullable(),
    patient_membership_no: z.string().nullable(),
    patient_no_title: z.string().nullable(),
    patient_tel_mobile_no: z.string().nullable(),
    patient_tel_other_no: z.string().nullable(),
    patient_title: z.string().nullable(),
    patient_user_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable(),
    spec_cat_code: z.string().nullable(),
    sr_appt2_overdue: z.boolean().nullable(),
    sr_appt_overdue: z.boolean().nullable(),
    status: z.string().nullable(),
    title: z.string().nullable(),
    urg_code: z.string().nullable(),
    urg_desc: z.string().nullable()
  })
  .passthrough();
const GetCimarLinkResponse = z.object({ url: z.string().nullable() }).partial().passthrough();
const GetCliniciansLetterCodesResponse = z
  .object({ description: z.string().nullable(), letter_code: z.string().nullable() })
  .partial()
  .passthrough();
const GetCliniciansApptFactsheetResponse = z
  .object({ appt_id: z.number().nullable(), filename: z.string().nullable(), sheet_name: z.string().nullable() })
  .partial()
  .passthrough();
const GetCliniciansApptIcdResponse = z
  .object({
    appt_id: z.number().nullable(),
    icd_code: z.string().nullable(),
    icd_description: z.string().nullable(),
    icd_version: z.string().nullable()
  })
  .partial()
  .passthrough();
const GetCliniciansApptLetterfilesResponse = z
  .object({
    appt_id: z.number().nullable(),
    case_id: z.number().nullable(),
    client_case_id: z.string().nullable(),
    created_date: z.string().datetime({ offset: true }).nullable(),
    delete_status: z.string().nullable(),
    description: z.string().nullable(),
    document_id: z.number().nullable(),
    document_type: z.string().nullable(),
    file_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable(),
    letter_type: z.string().nullable(),
    patient_user_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable(),
    published_to_patient: z.boolean().nullable(),
    uploaded_by: z.string().nullable()
  })
  .partial()
  .passthrough();
const GetCliniciansApptSecResponse = z
  .object({
    addline1: z.string().nullable(),
    addline2: z.string().nullable(),
    addline3: z.string().nullable(),
    addline4: z.string().nullable(),
    client_case_id: z.string().nullable(),
    dob: z.string().datetime({ offset: true }).nullable(),
    dpa_check_by: z.string().nullable(),
    dpa_check_date: z.string().datetime({ offset: true }).nullable(),
    dpa_check_state: z.number().nullable(),
    email: z.string().nullable(),
    membership_no: z.string().nullable(),
    name: z.string().nullable(),
    postcode: z.string().nullable(),
    region: z.string().nullable(),
    tel_mobile_no: z.string().nullable(),
    tel_other_no: z.string().nullable(),
    tel_pref_no: z.string().nullable()
  })
  .partial()
  .passthrough();
const GetCliniciansCliniciansResponse = z
  .object({
    add_langs: z.array(z.object({ lang_code: z.string(), lang_desc: z.string() })).nullable(),
    avatar_url: z.string().nullable(),
    best_match_flag: z.boolean().nullable(),
    bio: z.string().nullable(),
    cat_code: z.string().nullable(),
    cat_name: z.string().nullable(),
    client_id: z.number().nullable(),
    client_name: z.string().nullable(),
    clin_lucy_id: z.string().nullable(),
    clin_user_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable(),
    clinical_measures: z
      .array(
        z
          .object({
            ranking_code: z.string(),
            ranking_desc: z.string(),
            min_rank_value: z.number(),
            max_rank_value: z.number(),
            rank_value: z.number()
          })
          .partial()
          .passthrough()
      )
      .nullable(),
    consultant_id: z.string().nullable(),
    email: z.string().nullable(),
    employer_code: z.string().nullable(),
    employer_desc: z.string().nullable(),
    fastest_flag: z.boolean().nullable(),
    filter_tags: z.array(z.string()).nullable(),
    first_name: z.string().nullable(),
    first_slot: z.string().datetime({ offset: true }).nullable(),
    full_name: z.string().nullable(),
    geo_region: z.string().nullable(),
    gmc: z.string().nullable(),
    hcpc: z.string().nullable(),
    hospitals: z.string().nullable(),
    ident_prov_code: z.string().nullable(),
    ident_prov_desc: z.string().nullable(),
    info_tags: z.array(z.string()).nullable(),
    job_title: z.string().nullable(),
    lang_code: z.string().nullable(),
    lang_desc: z.string().nullable(),
    last_name: z.string().nullable(),
    last_seen_flag: z.boolean().nullable(),
    link1: z.string().nullable(),
    link2: z.string().nullable(),
    pref_notification: z.string().nullable(),
    prems_rank: z.number().nullable(),
    provider_name: z.string().nullable(),
    qualification_codes: z.string().nullable(),
    qualifications: z.string().nullable(),
    regions: z.array(z.string()).nullable(),
    sex_code: z.string().nullable(),
    sex_desc: z.string().nullable(),
    signature_url: z.string().nullable(),
    slot_duration: z.number().nullable(),
    spec_area_codes: z.string().nullable(),
    spec_areas: z.string().nullable(),
    status: z.string().nullable(),
    subtype: z.string().nullable(),
    subtype_desc: z.string().nullable(),
    tel_mobile_no: z.string().nullable(),
    tel_other_no: z.string().nullable(),
    temp_password: z.boolean().nullable(),
    timezone: z.string().nullable(),
    title: z.string().nullable(),
    user_role_code: z.string().nullable(),
    user_role_desc: z.string().nullable()
  })
  .partial()
  .passthrough();
const GetCliniciansDiaryResponse = z
  .object({
    appointment_completed: z.boolean().nullable(),
    appointment_id: z.number().nullable(),
    appointment_type: z.string().nullable(),
    call_type_code: z.string().nullable(),
    condition: z.string().nullable(),
    end: z.string().datetime({ offset: true }).nullable(),
    entry_type: z.string().nullable(),
    lucy_id: z.string().nullable(),
    patient: z.string().nullable(),
    slot_duration: z.number().nullable(),
    start: z.string().datetime({ offset: true }).nullable()
  })
  .partial()
  .passthrough();
const GetCliniciansExtclinsResponse = z
  .object({
    bio: z.string().nullable(),
    cat_code: z.string().nullable(),
    cat_name: z.string().nullable(),
    clin_user_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable(),
    display_order: z.number().nullable(),
    first_name: z.string().nullable(),
    gmc: z.string().nullable(),
    hospitals: z.string().nullable(),
    last_name: z.string().nullable(),
    regions: z.string().nullable(),
    subtype: z.string().nullable(),
    subtype_desc: z.string().nullable(),
    title: z.string().nullable()
  })
  .partial()
  .passthrough();
const GetCliniciansInvreqResponse = z
  .object({
    addline1: z.string().nullable(),
    addline2: z.string().nullable(),
    addline3: z.string().nullable(),
    addline4: z.string().nullable(),
    appointment_date: z.string().datetime({ offset: true }).nullable(),
    apppointment_completed: z.boolean().nullable(),
    appt_datetime: z.string().datetime({ offset: true }).nullable(),
    appt_id: z.number().nullable(),
    body_region_code: z.string().nullable(),
    body_region_desc: z.string().nullable(),
    body_side: z.string().nullable(),
    cancelled_by: z.string().nullable(),
    cancelled_date: z.string().datetime({ offset: true }).nullable(),
    cancelled_flag: z.boolean().nullable(),
    case_id: z.number().nullable(),
    clinician: z.string().nullable(),
    clinician_tel_mobile_no: z.string().nullable(),
    clinician_tel_other_no: z.string().nullable(),
    clinician_tel_pref_no: z.string().nullable(),
    clinician_type: z.string().nullable(),
    clinician_type_code: z.string().nullable(),
    clinician_user_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable(),
    complated_by: z.string().nullable(),
    completed_date: z.string().datetime({ offset: true }).nullable(),
    contrast_scan: z.boolean().nullable(),
    country: z.string().nullable(),
    email: z.string().nullable(),
    ext_appt_date: z.string().datetime({ offset: true }).nullable(),
    filename: z.string().nullable(),
    group_name: z.string().nullable(),
    history: z.string().nullable(),
    hospital_code: z.string().nullable(),
    hospital_id: z.number().nullable(),
    hospital_name: z.string().nullable(),
    hospital_type_code: z.string().nullable(),
    hospital_type_desc: z.string().nullable(),
    insurer_name: z.string().nullable(),
    ireq_cancel_code: z.string().nullable(),
    ireq_cancel_desc: z.string().nullable(),
    ireq_id: z.number().nullable(),
    ireq_results_available: z.boolean().nullable(),
    ireq_state_code: z.string().nullable(),
    ireq_state_desc: z.string().nullable(),
    patient_dob: z.string().datetime({ offset: true }).nullable(),
    patient_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable(),
    patient_lucy_id: z.string().nullable(),
    patient_name: z.string().nullable(),
    pdf_flag: z.boolean().nullable(),
    postcode: z.string().nullable(),
    reason: z.string().nullable(),
    region: z.string().nullable(),
    service_type_code: z.string().nullable(),
    service_type_desc: z.string().nullable(),
    signature_page: z.number().nullable(),
    signature_x: z.number().nullable(),
    signature_y: z.number().nullable(),
    surveyjs_id: z.number().nullable(),
    tel_no: z.string().nullable(),
    template_filename: z.string().nullable(),
    template_path: z.string().nullable(),
    template_url: z.string().nullable(),
    updated_date: z.string().datetime({ offset: true }).nullable(),
    urgency_code: z.string().nullable(),
    urgency_desc: z.string().nullable()
  })
  .partial()
  .passthrough();
const GetCliniciansRefclinsResponse = z
  .object({
    addline1: z.string().nullable(),
    addline2: z.string().nullable(),
    addline3: z.string().nullable(),
    addline4: z.string().nullable(),
    avatar_url: z.string().nullable(),
    bio: z.string().nullable(),
    cat_code: z.string().nullable(),
    cat_name: z.string().nullable(),
    client_id: z.number().nullable(),
    client_name: z.string().nullable(),
    clin_lucy_id: z.string().nullable(),
    clin_user_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable(),
    consultant_id: z.string().nullable(),
    country: z.string().nullable(),
    email: z.string().nullable(),
    first_name: z.string().nullable(),
    geo_region: z.string().nullable(),
    gmc: z.string().nullable(),
    hcpc: z.string().nullable(),
    hospital_code: z.string().nullable(),
    hospital_id: z.number().nullable(),
    hospital_name: z.string().nullable(),
    last_name: z.string().nullable(),
    postcode: z.string().nullable(),
    region: z.string().nullable(),
    signature_url: z.string().nullable(),
    spec_area_codes: z.string().nullable(),
    spec_areas: z.string().nullable(),
    subtype: z.string().nullable(),
    subtype_desc: z.string().nullable(),
    tel_mobile_no: z.string().nullable(),
    tel_no: z.string().nullable(),
    tel_other_no: z.string().nullable(),
    title: z.string().nullable()
  })
  .partial()
  .passthrough();
const GetGdprResponse = z
  .object({
    actioned_date: z.string().datetime({ offset: true }).nullable(),
    actioned_user_name: z.string().nullable(),
    reason: z.string().nullable()
  })
  .partial()
  .passthrough();
const GetIntapiCompPendingResponse = z
  .object({
    appt_id: z.number().nullable(),
    case_id: z.number().nullable(),
    clinician_user_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable(),
    created_date: z.string().datetime({ offset: true }).nullable(),
    detail1: z.string().nullable(),
    detail2: z.string().nullable(),
    event: z.string().nullable(),
    event_desc: z.string().nullable(),
    event_id: z.number().nullable(),
    message: z.string().nullable(),
    patient_user_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable(),
    success: z.boolean().nullable(),
    user_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable(),
    user_type_code: z.string().nullable()
  })
  .partial()
  .passthrough();
const GetMedicationResponse = z
  .object({
    bnf_url: z.string().nullable(),
    duration: z.string().nullable(),
    frequency: z.string().nullable(),
    med_id: z.number().nullable(),
    prescription_id: z.string().nullable(),
    vmp_id: z.string().nullable(),
    vmp_name: z.string().nullable(),
    vtm_id: z.string().nullable(),
    vtm_name: z.string().nullable()
  })
  .partial()
  .passthrough();
const postMedicationApptPrescribe_Body = z
  .object({ appt_id: z.number(), prescribe: z.string().optional() })
  .passthrough();
const PostMedicationApptPrescribeResponse = z
  .object({ appt_id: z.number().nullable(), prescribe_meds: z.number().nullable() })
  .partial()
  .passthrough();
const GetMedicationVmpResponse = z
  .object({
    bnf_url: z.string().nullable(),
    vmp_id: z.string().nullable(),
    vmp_name: z.string().nullable(),
    vtm_id: z.string().nullable(),
    vtm_name: z.string().nullable()
  })
  .partial()
  .passthrough();
const GetMedicationVtmResponse = z
  .object({ bnf_url: z.string().nullable(), vtm_id: z.string().nullable(), vtm_name: z.string().nullable() })
  .partial()
  .passthrough();
const GetMetaCaseResponse = z
  .object({
    case_cancel_reasons: z
      .array(z.object({ case_cancel_code: z.string(), case_cancel_desc: z.string() }).partial().passthrough())
      .nullable(),
    case_discharge_reasons: z
      .array(z.object({ discharge_code: z.string(), discharge_desc: z.string() }).partial().passthrough())
      .nullable(),
    user_auth_cancel_reasons: z
      .array(
        z.object({ auth_cancel_reason_code: z.string(), auth_cancel_reason_desc: z.string() }).partial().passthrough()
      )
      .nullable(),
    clinical_urgencies: z
      .array(z.object({ urg_code: z.string(), urg_desc: z.string() }).partial().passthrough())
      .nullable(),
    urgency_change_reasons: z
      .array(z.object({ urgency_change_code: z.string(), urgency_change_desc: z.string() }).partial().passthrough())
      .nullable()
  })
  .partial()
  .passthrough();
const GetNotesPatientResponse = z
  .object({
    added_by: z.string().nullable(),
    appt_id: z.number().nullable(),
    case_id: z.number().nullable(),
    category_code: z.string().nullable(),
    clin_visible: z.boolean().nullable(),
    created_by: z.string().nullable(),
    created_date: z.string().datetime({ offset: true }).nullable(),
    date_added: z.string().datetime({ offset: true }).nullable(),
    note_id: z.number().nullable(),
    note_level: z.string().nullable(),
    note_path: z.string().nullable(),
    note_text: z.string().nullable(),
    parent_id: z.number().nullable(),
    ubrn: z.string().nullable(),
    user_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable()
  })
  .partial()
  .passthrough();
const GetOutputsBoilerplateByBoilerplateCodeResponse = z
  .object({ boilerplate_code: z.string().nullable(), boilerplate_text: z.string().nullable() })
  .partial()
  .passthrough();
const GetOutputsConfluenceByCategoryIdResponse = z
  .object({
    confluence_id: z.number().nullable(),
    content_template_code: z.string().nullable(),
    description: z.string().nullable()
  })
  .partial()
  .passthrough();
const GetOutputsDefResponse = z
  .object({
    client_id: z.number().nullable(),
    client_name: z.string().nullable(),
    common_flag: z.boolean().nullable(),
    created_by: z.string().nullable(),
    current_version: z.boolean().nullable(),
    enabled: z.boolean().nullable(),
    event_code: z.string().nullable(),
    event_name: z.string().nullable(),
    lang_code: z.string().nullable(),
    lang_desc: z.string().nullable(),
    output_body: z.string().nullable(),
    output_sms_body: z.string().nullable(),
    output_subject: z.string().nullable(),
    published: z.boolean().nullable(),
    status: z.string().nullable(),
    template_code: z.string().nullable(),
    template_id: z.number().nullable(),
    template_type: z.string().nullable(),
    title: z.string().nullable(),
    version: z.number().nullable()
  })
  .partial()
  .passthrough();
const GetOutputsLetterHtmlByApptIdByLetterCodeByPreviewBySubTokensResponse = z
  .object({
    appt_id: z.number().nullable(),
    clinician_user_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable(),
    file_name: z.string().nullable(),
    letter_code: z.string().nullable(),
    letter_html: z.string().nullable(),
    letterid: z.number().nullable(),
    options_json: z.string().nullable()
  })
  .partial()
  .passthrough();
const PutOutputsPushSubscriptionResponse = z.object({ sub_id: z.number().nullable() }).partial().passthrough();
const GetProvidersHospitalsResponse = z
  .object({
    addline1: z.string().nullable(),
    addline2: z.string().nullable(),
    addline3: z.string().nullable(),
    addline4: z.string().nullable(),
    country: z.string().nullable(),
    email: z.string().nullable(),
    group_name: z.string().nullable(),
    no_hospitals: z.number().nullable(),
    ods_code: z.string().nullable(),
    postcode: z.string().nullable(),
    region: z.string().nullable(),
    tel_no: z.string().nullable()
  })
  .partial()
  .passthrough();
const GetProvidersImgprovsResponse = z
  .object({
    addline1: z.string().nullable(),
    addline2: z.string().nullable(),
    addline3: z.string().nullable(),
    addline4: z.string().nullable(),
    country: z.string().nullable(),
    distance: z.number().nullable(),
    distance_units: z.string().nullable(),
    email: z.string().nullable(),
    form_type: z.string().nullable(),
    group_name: z.string().nullable(),
    hospital_code: z.string().nullable(),
    hospital_id: z.number().nullable(),
    hospital_name: z.string().nullable(),
    hospital_type_code: z.string().nullable(),
    hospital_type_desc: z.string().nullable(),
    latitude: z.number().nullable(),
    longitude: z.number().nullable(),
    postcode: z.string().nullable(),
    region: z.string().nullable(),
    service_type_codes: z.string().nullable(),
    tel_no: z.string().nullable()
  })
  .partial()
  .passthrough();
const GetProvidersPracticesResponse = z
  .object({
    addline1: z.string().nullable(),
    addline2: z.string().nullable(),
    addline3: z.string().nullable(),
    addline4: z.string().nullable(),
    country: z.string().nullable(),
    distance: z.number().nullable(),
    distance_units: z.string().nullable(),
    email: z.string().nullable(),
    latitude: z.number().nullable(),
    longitude: z.number().nullable(),
    nlat: z.number().nullable(),
    nlong: z.number().nullable(),
    nradius: z.number().nullable(),
    ods_code: z.string().nullable(),
    postcode: z.string().nullable(),
    practice_name: z.string().nullable(),
    region: z.string().nullable(),
    tel_no: z.string().nullable()
  })
  .partial()
  .passthrough();
const GetProvidersServiceProvidersResponse = z
  .object({
    client_id: z.number().nullable(),
    client_name: z.string().nullable(),
    email: z.string().nullable(),
    enabled: z.boolean().nullable(),
    filter_tags: z.array(z.string()).nullable(),
    group_name: z.string().nullable(),
    hospital_code: z.string().nullable(),
    hospital_id: z.number().nullable(),
    hospital_name: z.string().nullable(),
    hospital_ods_code: z.string().nullable(),
    hospital_type_code: z.string().nullable(),
    hospital_type_desc: z.string().nullable(),
    service_cat_code: z.string().nullable(),
    service_cat_desc: z.string().nullable(),
    service_types: z.string().nullable(),
    tel_no: z.string().nullable()
  })
  .partial()
  .passthrough();
const GetProxyRequestsResponse = z
  .object({
    addline1: z.string().nullable(),
    addline2: z.string().nullable(),
    addline3: z.string().nullable(),
    addline4: z.string().nullable(),
    agent_updatable: z.string().nullable(),
    authorised: z.string().nullable(),
    authorised_date: z.string().datetime({ offset: true }).nullable(),
    country: z.string().nullable(),
    email: z.string().nullable(),
    first_name: z.string().nullable(),
    last_name: z.string().nullable(),
    membership_no: z.string().nullable(),
    postcode: z.string().nullable(),
    proxy_id: z.number().nullable(),
    proxy_status: z.string().nullable(),
    region: z.string().nullable(),
    sex: z.string().nullable(),
    tel_mobile_no: z.string().nullable(),
    tel_other_no: z.string().nullable(),
    title: z.string().nullable()
  })
  .partial()
  .passthrough();
const GetReferrersCaseReferResponse = z
  .object({
    appointment_date: z.string().datetime({ offset: true }).nullable(),
    appt_id: z.number().nullable(),
    case_created_date: z.string().datetime({ offset: true }).nullable(),
    case_id: z.number().nullable(),
    case_spec_area_code: z.string().nullable(),
    case_spec_area_desc: z.string().nullable(),
    case_spec_cat_code: z.string().nullable(),
    case_spec_cat_desc: z.string().nullable(),
    client_case_id: z.string().nullable(),
    clinician: z.string().nullable(),
    clinician_user_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable(),
    condition: z.string().nullable(),
    hospital_addline1: z.string().nullable(),
    hospital_addline2: z.string().nullable(),
    hospital_addline3: z.string().nullable(),
    hospital_addline4: z.string().nullable(),
    hospital_code: z.string().nullable(),
    hospital_country: z.string().nullable(),
    hospital_id: z.number().nullable(),
    hospital_name: z.string().nullable(),
    hospital_postcode: z.string().nullable(),
    hospital_region: z.string().nullable(),
    patient: z.string().nullable(),
    patient_email: z.string().nullable(),
    patient_membership_no: z.string().nullable(),
    patient_postcode: z.string().nullable(),
    patient_tel_mobile_no: z.string().nullable(),
    patient_tel_other_no: z.string().nullable(),
    patient_user_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable(),
    ref_case_reason: z.string().nullable(),
    ref_case_spec_area_code: z.string().nullable(),
    ref_case_spec_area_desc: z.string().nullable(),
    ref_case_spec_cat_code: z.string().nullable(),
    ref_case_spec_cat_desc: z.string().nullable(),
    ref_clinician: z.string().nullable(),
    ref_clinician_user_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable(),
    ref_email: z.string().nullable(),
    ref_postcode: z.string().nullable()
  })
  .partial()
  .passthrough();
const GetReferrersDbReferResponse = z
  .object({
    case_created_date: z.string().datetime({ offset: true }).nullable(),
    case_id: z.number().nullable(),
    case_spec_area_code: z.string().nullable(),
    case_spec_area_desc: z.string().nullable(),
    case_spec_cat_code: z.string().nullable(),
    case_spec_cat_desc: z.string().nullable(),
    client_case_id: z.string().nullable(),
    condition: z.string().nullable(),
    ext_appt_date: z.string().datetime({ offset: true }).nullable(),
    ext_appt_id: z.string().nullable(),
    group: z.string().nullable(),
    hospital_addline1: z.string().nullable(),
    hospital_addline2: z.string().nullable(),
    hospital_addline3: z.string().nullable(),
    hospital_addline4: z.string().nullable(),
    hospital_code: z.string().nullable(),
    hospital_country: z.string().nullable(),
    hospital_group_name: z.string().nullable(),
    hospital_id: z.number().nullable(),
    hospital_name: z.string().nullable(),
    hospital_phone: z.string().nullable(),
    hospital_postcode: z.string().nullable(),
    hospital_region: z.string().nullable(),
    patient: z.string().nullable(),
    patient_email: z.string().nullable(),
    patient_membership_no: z.string().nullable(),
    patient_tel_mobile_no: z.string().nullable(),
    patient_tel_other_no: z.string().nullable(),
    patient_user_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable(),
    ref_case_spec_cat_code: z.string().nullable(),
    ref_clinician: z.string().nullable(),
    ref_clinician_user_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable(),
    ref_postcode: z.string().nullable()
  })
  .partial()
  .passthrough();
const GetReferrersPatientsResponse = z
  .object({
    addline1: z.string().nullable(),
    addline2: z.string().nullable(),
    addline3: z.string().nullable(),
    addline4: z.string().nullable(),
    avatar_url: z.string().nullable(),
    client_id: z.number().nullable(),
    client_name: z.string().nullable(),
    country: z.string().nullable(),
    created_date: z.string().datetime({ offset: true }).nullable(),
    dob: z.string().datetime({ offset: true }).nullable(),
    email: z.string().nullable(),
    employer_code: z.string().nullable(),
    enabled: z.boolean().nullable(),
    existing_case: z.boolean().nullable(),
    filter_tags: z.array(z.string()).nullable(),
    first_name: z.string().nullable(),
    full_name: z.string().nullable(),
    geo_region: z.string().nullable(),
    ident_prov_code: z.string().nullable(),
    ident_prov_desc: z.string().nullable(),
    info_tags: z.array(z.string()).nullable(),
    insured: z.boolean().nullable(),
    job_title: z.string().nullable(),
    lang_code: z.string().nullable(),
    lang_desc: z.string().nullable(),
    last_name: z.boolean().nullable(),
    membership_no: z.string().nullable(),
    nhs_client: z.boolean().nullable(),
    postcode: z.string().nullable(),
    pref_notification: z.string().nullable(),
    provider_name: z.string().nullable(),
    region: z.string().nullable(),
    restricted: z.number().nullable(),
    self_refer: z.boolean().nullable(),
    seranova_id: z.string().nullable(),
    sex: z.string().nullable(),
    sex_code: z.string().nullable(),
    sex_desc: z.string().nullable(),
    status: z.string().nullable(),
    tel_mobile_no: z.string().nullable(),
    tel_other_no: z.string().nullable(),
    tel_pref_no: z.string().nullable(),
    temp_password: z.boolean().nullable(),
    timezone: z.string().nullable(),
    title: z.string().nullable(),
    user_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable(),
    user_role_code: z.string().nullable(),
    user_type_code: z.string().nullable(),
    validated: z.string().datetime({ offset: true }).nullable()
  })
  .partial()
  .passthrough();
const GetSurveysSurveyjsResponse = z
  .object({
    always_edit: z.boolean().nullable(),
    appt_id: z.number().nullable(),
    area_code: z.string().nullable(),
    area_name: z.string().nullable(),
    case_id: z.number().nullable(),
    cat_code: z.string().nullable(),
    cat_name: z.string().nullable(),
    clin_editable: z.boolean().nullable(),
    completed_date: z.string().datetime({ offset: true }).nullable(),
    completed_flag: z.boolean().nullable(),
    created_date: z.string().datetime({ offset: true }).nullable(),
    definition_pregenerated: z.number().nullable(),
    event_code: z.string().nullable(),
    ireq_id: z.number().nullable(),
    outcome_flag: z.boolean().nullable(),
    outcome_mandatory_flag: z.boolean().nullable(),
    patient_user_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable(),
    response: z.string().nullable(),
    shortname: z.string().nullable(),
    survey_def_id: z.number().nullable(),
    surveyjs_def_id: z.number().nullable(),
    surveyjs_id: z.number().nullable(),
    title: z.string().nullable(),
    updated_date: z.string().datetime({ offset: true }).nullable()
  })
  .partial()
  .passthrough();
const GetTasksResponse = z
  .object({
    actionable: z.number().nullable(),
    appt_id: z.number().nullable(),
    area_desc: z.string().nullable(),
    assigned_by: z.string().nullable(),
    assigned_to: z.string().nullable(),
    assigned_to_client_id: z.number().nullable(),
    bkappt_clin_subtype: z.string().nullable(),
    case_desc: z.string().nullable(),
    case_id: z.number().nullable(),
    case_selection: z.string().nullable(),
    cat_code: z.string().nullable(),
    client_case_id: z.string().nullable(),
    closed_date: z.string().datetime({ offset: true }).nullable(),
    company: z.string().nullable(),
    created_date: z.string().datetime({ offset: true }).nullable(),
    creator_name: z.string().nullable(),
    due_date: z.string().datetime({ offset: true }).nullable(),
    membership_no: z.string().nullable(),
    patient_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable(),
    patient_name: z.string().nullable(),
    patient_title: z.string().nullable(),
    proxy_id: z.number().nullable(),
    reason_for_cancel: z.string().nullable(),
    status: z.string().nullable(),
    task_id: z.number().nullable(),
    task_notes: z.string().nullable(),
    task_subtype: z.string().nullable(),
    task_type: z.string().nullable(),
    task_type_code: z.string().nullable()
  })
  .partial()
  .passthrough();
const GetTasksDashboardTasksResponse = z
  .object({
    accesser_type_code: z.string().nullable(),
    case_selection: z.string().nullable(),
    due_date: z.string().datetime({ offset: true }).nullable(),
    patient_name: z.string().nullable(),
    task_id: z.number().nullable(),
    task_sub_type: z.string().nullable(),
    task_type: z.string().nullable()
  })
  .partial()
  .passthrough();
const PutUsersCheckpResponse = z
  .object({
    user_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable()
  })
  .partial()
  .passthrough();
const GetUsersFactsheetsResponse = z
  .object({
    filename: z.string().nullable(),
    sheet_id: z.number().nullable(),
    sheet_name: z.string().nullable(),
    url: z.string().nullable()
  })
  .partial()
  .passthrough();
const GetUsersGpByGpUserGuidResponse = z
  .object({
    addline1: z.string().nullable(),
    addline2: z.string().nullable(),
    addline3: z.string().nullable(),
    addline4: z.string().nullable(),
    country: z.string().nullable(),
    email: z.string().nullable(),
    first_name: z.string().nullable(),
    gp_ods_code: z.string().nullable(),
    last_name: z.string().nullable(),
    postcode: z.string().nullable(),
    region: z.string().nullable(),
    title: z.string().nullable()
  })
  .partial()
  .passthrough();
const GetUsersPolicyResponse = z
  .object({
    accepted: z.boolean().nullable(),
    accepted_date: z.string().datetime({ offset: true }).nullable(),
    description: z.string().nullable(),
    policy_code: z.string().nullable(),
    policy_level: z.string().nullable(),
    policy_link: z.string().nullable(),
    url: z.string().nullable(),
    user_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable(),
    version_id: z.number().nullable()
  })
  .partial()
  .passthrough();
const PostUsersStripedbDateResponse = z
  .object({ stripe_id: z.string().nullable(), stripe_info_date: z.string().datetime({ offset: true }).nullable() })
  .partial()
  .passthrough();
const GetUsersTimelineResponse = z
  .object({
    appt_cancelled_by: z.string().nullable(),
    appt_date: z.string().datetime({ offset: true }).nullable(),
    appt_id: z.number().nullable(),
    appt_status_code: z.string().nullable(),
    appt_status_desc: z.string().nullable(),
    appt_type_code: z.string().nullable(),
    appt_type_desc: z.string().nullable(),
    case_closed_by: z.string().nullable(),
    case_closed_date: z.string().datetime({ offset: true }).nullable(),
    case_created_date: z.string().datetime({ offset: true }).nullable(),
    case_status_date: z.string().datetime({ offset: true }).nullable(),
    case_status_desc: z.string().nullable(),
    client_case_id: z.string().nullable(),
    clin_user_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable(),
    clinician_name: z.string().nullable(),
    date_order: z.string().datetime({ offset: true }).nullable(),
    ext_appt_date: z.string().datetime({ offset: true }).nullable(),
    follow_up: z.boolean().nullable(),
    imaging_provider: z.string().nullable(),
    item_call_type_code: z.string().nullable(),
    item_call_type_desc: z.string().nullable(),
    item_condition: z.string().nullable(),
    item_id: z.number().nullable(),
    item_level: z.string().nullable(),
    item_status_code: z.string().nullable(),
    item_status_date: z.string().datetime({ offset: true }).nullable(),
    item_status_desc: z.boolean().nullable(),
    item_type_code: z.string().nullable(),
    item_type_desc: z.string().nullable(),
    item_user: z.string().nullable(),
    service_cat_code: z.string().nullable(),
    service_cat_desc: z.string().nullable(),
    service_type_code: z.string().nullable(),
    service_type_desc: z.string().nullable(),
    site_name: z.string().nullable(),
    title: z.string().nullable()
  })
  .partial()
  .passthrough();
const postUsersTimingEvent_Body = z
  .object({ event: z.string().max(30), date: z.string().datetime({ offset: true }).optional() })
  .passthrough();
const GetUsersTodoResponse = z
  .object({
    action_desc: z.string().nullable(),
    appt_auth_end_date: z.string().datetime({ offset: true }).nullable(),
    appt_auth_from_date: z.string().datetime({ offset: true }).nullable(),
    appt_id: z.number().nullable(),
    appt_type_code: z.string().nullable(),
    case_id: z.number().nullable(),
    clinician_user_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable(),
    condition: z.string().nullable(),
    datetime: z.string().datetime({ offset: true }).nullable(),
    spec_area_code: z.string().nullable(),
    spec_cat_code: z.string().nullable(),
    status: z.string().nullable(),
    survey_id: z.number().nullable(),
    surveyjs_def_id: z.number().nullable(),
    todo_type: z.string().nullable(),
    todo_weight: z.number().nullable(),
    user_subtype_code: z.string().nullable()
  })
  .partial()
  .passthrough();
const GetUsersTourResponse = z
  .object({
    cancelled_appts: z.number().nullable(),
    clin_spec_areas: z.string().nullable(),
    clin_spec_cat_code: z.string().nullable(),
    clin_subtype_code: z.string().nullable(),
    completed_appts: z.number().nullable(),
    days_inactive: z.number().nullable(),
    login_count: z.number().nullable(),
    new_clinician: z.boolean().nullable(),
    open_appts: z.number().nullable(),
    outstanding_cr_surveys: z.number().nullable(),
    outstanding_crs: z.number().nullable(),
    pat_employer_code: z.string().nullable(),
    pat_on_pifu: z.boolean().nullable(),
    user_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable()
  })
  .partial()
  .passthrough();
const GetUsersUserResponse = z
  .object({
    addline1: z.string().nullable(),
    addline2: z.string().nullable(),
    addline3: z.string().nullable(),
    addline4: z.string().nullable(),
    appt_ical: z.string().nullable(),
    auth_type: z.string().nullable(),
    avatar_url: z.string().nullable(),
    build_path: z.string().nullable(),
    client_code: z.string().nullable(),
    client_id: z.number().nullable(),
    client_name: z.string().nullable(),
    country: z.string().nullable(),
    created_date: z.string().datetime({ offset: true }).nullable(),
    dob: z.string().datetime({ offset: true }).nullable(),
    email: z.string().nullable(),
    email_validated: z.string().datetime({ offset: true }).nullable(),
    employer_code: z.string().nullable(),
    enabled: z.boolean().nullable(),
    first_name: z.string().nullable(),
    geo_region: z.string().nullable(),
    ident_prov_code: z.string().nullable(),
    insured: z.boolean().nullable(),
    job_title: z.string().nullable(),
    lang_code: z.string().nullable(),
    last_name: z.boolean().nullable(),
    locked_date: z.string().datetime({ offset: true }).nullable(),
    locked_mins_left: z.number().nullable(),
    lockout_mins: z.number().nullable(),
    login_url: z.string().nullable(),
    lucy_id: z.string().nullable(),
    membership_no: z.string().nullable(),
    needs_card_details: z.number().nullable(),
    needs_recent_card_details: z.number().nullable(),
    nhs_client: z.boolean().nullable(),
    physitrack_id: z.string().nullable(),
    physitrack_link: z.string().nullable(),
    policies_accepted: z.number().nullable(),
    postcode: z.string().nullable(),
    pref_notification: z.string().nullable(),
    provider_name: z.string().nullable(),
    provider_settings_url: z.string().nullable(),
    proxies_available: z.string().nullable(),
    proxy_id: z.number().nullable(),
    proxy_session: z.boolean().nullable(),
    proxy_user_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable(),
    region: z.string().nullable(),
    restricted: z.number().nullable(),
    self_refer: z.boolean().nullable(),
    self_reg_required: z.boolean().nullable(),
    seranova_id: z.string().nullable(),
    service_name: z.string().nullable(),
    sex: z.string().nullable(),
    stripe_id: z.string().nullable(),
    tel_mobile_no: z.string().nullable(),
    tel_other_no: z.string().nullable(),
    tel_pref_no: z.string().nullable(),
    tel_validated: z.string().datetime({ offset: true }).nullable(),
    timeout_mins: z.number().nullable(),
    timezone: z.string().nullable(),
    title: z.string().nullable(),
    user_guid: z
      .string()
      .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      .nullable(),
    user_role_code: z.string().nullable(),
    user_subtype_code: z.string().nullable(),
    user_type_code: z.string().nullable(),
    validated: z.string().datetime({ offset: true }).nullable()
  })
  .partial()
  .passthrough();
const ErrorResponse = z
  .object({ message: z.string().nullable(), status: z.number().nullable() })
  .partial()
  .passthrough();

export const schemas = {
  postAdminApptOutcome_Body,
  postAdminClientOutcome_Body,
  postAdminCompany_Body,
  PostAdminCompanyResponse,
  postAdminCompanyDisable_Body,
  postAdminInvreqStatus_Body,
  GetAdminPatientsPifuResponse,
  postAdminQueue_Body,
  PostAdminQueueResponse,
  postAdminSurveyjs_Body,
  PostAdminSurveyjsResponse,
  postAdminSurveyjsDisable_Body,
  putAdminSurveyjsNew_Body,
  postAdminUser_Body,
  PostAdminUserResponse,
  postAdminUserDisable_Body,
  postBookingAppointment_Body,
  PostBookingAppointmentResponse,
  putBookingAppointmentAuth_Body,
  PutBookingAppointmentAuthResponse,
  putBookingAppointmentAuthRemove_Body,
  postBookingAppointmentCancel_Body,
  PostBookingAppointmentCancelResponse,
  postBookingAppointmentReschedule_Body,
  PostBookingAppointmentRescheduleResponse,
  postBookingCaseReview_Body,
  PostBookingCaseReviewResponse,
  postCasesDischarge_Body,
  PostCasesDischargeResponse,
  postCasesDischargeForce_Body,
  postCasesFlags_Body,
  putCasesNew_Body,
  PutCasesNewResponse,
  postCasesRef_Body,
  postCasesReopen_Body,
  postCasesTriage_Body,
  postCasesUrgency_Body,
  postCliniciansApptComplete_Body,
  PostCliniciansApptCompleteResponse,
  postCliniciansApptDelay_Body,
  PostCliniciansApptDelayResponse,
  PostCliniciansApptDeleteIcdResponse,
  postCliniciansApptFactsheet_Body,
  PostCliniciansApptFactsheetResponse,
  postCliniciansApptIcd_Body,
  PostCliniciansApptIcdResponse,
  postCliniciansApptLetterfiles_Body,
  PostCliniciansApptLetterfilesResponse,
  postCliniciansApptOutcome_Body,
  PostCliniciansApptOutcomeResponse,
  postCliniciansApptPifu_Body,
  PostCliniciansApptPifuResponse,
  postCliniciansApptSec_Body,
  PostCliniciansApptSecResponse,
  PostCliniciansApptSuggestResponse,
  postCliniciansCaseRehab_Body,
  postCliniciansDeleteInvreq_Body,
  PostCliniciansDeleteInvreqResponse,
  PostCliniciansExtclinsRankResponse,
  postCliniciansInvreq_Body,
  PostCliniciansInvreqResponse,
  postCliniciansInvreqProvider_Body,
  PostCliniciansInvreqProviderResponse,
  postCliniciansSigpharm_Body,
  postFilesApptFilesRemove_Body,
  PostFilesApptFilesRemoveResponse,
  postFilesApptFilesUpload_Body,
  PostFilesApptFilesUploadResponse,
  postFilesCaseFilesUpload_Body,
  PostFilesCaseFilesUploadResponse,
  putGdprRequest_Body,
  PutGdprRequestResponse,
  putIntapiCompReady_Body,
  PutIntapiCompReadyResponse,
  postIntapiReferrerReportMatch_Body,
  putIntapiSupportEmail_Body,
  GetIntapiUsersThirdResponse,
  postMedicationCreate_Body,
  PostMedicationCreateResponse,
  postMedicationDisable_Body,
  PostMedicationDisableResponse,
  postNotesAppt_Body,
  PostNotesApptResponse,
  postNotesPatient_Body,
  PostNotesPatientResponse,
  postOutputsDef_Body,
  PostOutputsDefResponse,
  postOutputsPref_Body,
  PostOutputsPrefResponse,
  postOutputsSmspref_Body,
  PostOutputsSmsprefResponse,
  PostProvidersGeoHospitalsResponse,
  postProvidersHospital_Body,
  postProvidersHospitalDisable_Body,
  postProvidersHospitalGroup_Body,
  postProvidersHospitalGroupDisable_Body,
  postProvidersHospitalServiceCat_Body,
  postProvidersImgprovDisable_Body,
  postProvidersServiceProviderEnable_Body,
  postProxyActivate_Body,
  postProxyAuth_Body,
  postProxyRequest_Body,
  postReferrersCaseRefer_Body,
  PostReferrersCaseReferResponse,
  postReferrersCaseReferReject_Body,
  PostReferrersCaseReferRejectResponse,
  putReferrersEligibility_Body,
  PutReferrersEligibilityResponse,
  putReferrersMember_Body,
  PutReferrersMemberResponse,
  postReferrersMemberProfile_Body,
  postSurveysCovid19_Body,
  postSurveysSurveyjs_Body,
  PostSurveysSurveyjsResponse,
  postTasksCancel_Body,
  PostTasksCancelResponse,
  postTasksComplete_Body,
  putTasksCreate_Body,
  postTasksReassign_Body,
  putUsersApptTwilioEvent_Body,
  postUsersApptTwilioId_Body,
  PostUsersApptTwilioIdResponse,
  putUsersApptWsConn_Body,
  PutUsersApptWsConnResponse,
  postUsersPolicy_Body,
  PostUsersPolicyResponse,
  postUsersProfile_Body,
  PostUsersProfileResponse,
  GetUsersWsByConnIdResponse,
  GetAdminAdminUsersResponse,
  GetAdminApptOutcomesResponse,
  postAdminCasePolicy_Body,
  GetAdminClientOutcomesResponse,
  GetAdminCompaniesResponse,
  GetAdminEmployersResponse,
  GetAdminHbsUsersResponse,
  postAdminInvreqApptdate_Body,
  PostAdminInvreqApptdateResponse,
  GetAdminPowerbiGroupsResponse,
  GetAdminQueuesResponse,
  GetAdminSpecareasResponse,
  GetAdminSpeccatsResponse,
  GetAdminSurveyScheduleResponse,
  GetAdminSurveyjsDefResponse,
  GetBookingAppointmentSlotsResponse,
  GetBookingAppointmentStatusResponse,
  GetBookingAppointmentsResponse,
  GetCasesResponse,
  GetCimarLinkResponse,
  GetCliniciansLetterCodesResponse,
  GetCliniciansApptFactsheetResponse,
  GetCliniciansApptIcdResponse,
  GetCliniciansApptLetterfilesResponse,
  GetCliniciansApptSecResponse,
  GetCliniciansCliniciansResponse,
  GetCliniciansDiaryResponse,
  GetCliniciansExtclinsResponse,
  GetCliniciansInvreqResponse,
  GetCliniciansRefclinsResponse,
  GetGdprResponse,
  GetIntapiCompPendingResponse,
  GetMedicationResponse,
  postMedicationApptPrescribe_Body,
  PostMedicationApptPrescribeResponse,
  GetMedicationVmpResponse,
  GetMedicationVtmResponse,
  GetMetaCaseResponse,
  GetNotesPatientResponse,
  GetOutputsBoilerplateByBoilerplateCodeResponse,
  GetOutputsConfluenceByCategoryIdResponse,
  GetOutputsDefResponse,
  GetOutputsLetterHtmlByApptIdByLetterCodeByPreviewBySubTokensResponse,
  PutOutputsPushSubscriptionResponse,
  GetProvidersHospitalsResponse,
  GetProvidersImgprovsResponse,
  GetProvidersPracticesResponse,
  GetProvidersServiceProvidersResponse,
  GetProxyRequestsResponse,
  GetReferrersCaseReferResponse,
  GetReferrersDbReferResponse,
  GetReferrersPatientsResponse,
  GetSurveysSurveyjsResponse,
  GetTasksResponse,
  GetTasksDashboardTasksResponse,
  PutUsersCheckpResponse,
  GetUsersFactsheetsResponse,
  GetUsersGpByGpUserGuidResponse,
  GetUsersPolicyResponse,
  PostUsersStripedbDateResponse,
  GetUsersTimelineResponse,
  postUsersTimingEvent_Body,
  GetUsersTodoResponse,
  GetUsersTourResponse,
  GetUsersUserResponse,
  ErrorResponse
};

const endpoints = makeApi([
  {
    method: 'get',
    path: '/admin/admin/users/APPUSER/',
    alias: 'getAdminAdminUsers',
    description: `GET All Admin users`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'rows',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'name',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'email',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'enabled',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'validated',
        type: 'Query',
        schema: z.string().datetime({ offset: true }).optional()
      },
      {
        name: 'fuzzy',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'type_code',
        type: 'Query',
        schema: z.string().optional()
      }
    ],
    response: z.array(GetAdminAdminUsersResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/admin/appt/outcome/',
    alias: 'postAdminApptOutcome',
    description: `POST Outcomes`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postAdminApptOutcome_Body
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'put',
    path: '/admin/appt/outcome/delete/',
    alias: 'putAdminApptOutcomeDelete',
    description: `DELETE An OUTCOME CODE`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({}).partial().passthrough()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/admin/appt/outcomes/APPUSER/',
    alias: 'getAdminApptOutcomes',
    description: `GET Outcomes`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'rows',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'appt_outcome_code',
        type: 'Query',
        schema: z.string().optional()
      }
    ],
    response: z.array(GetAdminApptOutcomesResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/admin/case/policy/APPUSER/',
    alias: 'postAdminCasePolicy',
    description: `POST case eligibility policy`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postAdminCasePolicy_Body
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/admin/client/outcome/',
    alias: 'postAdminClientOutcome',
    description: `POST Outcomes CATS`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postAdminClientOutcome_Body
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'put',
    path: '/admin/client/outcome/delete/',
    alias: 'putAdminClientOutcomeDelete',
    description: `DELETE A Client OUTCOME CODE`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({}).partial().passthrough()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/admin/client/outcomes/APPUSER/',
    alias: 'getAdminClientOutcomes',
    description: `GET Outcomes Categories`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'rows',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'outcome_code',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'client_id',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'spec_cat_code',
        type: 'Query',
        schema: z.string().optional()
      }
    ],
    response: z.array(GetAdminClientOutcomesResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/admin/clinician/',
    alias: 'postAdminClinician',
    description: `POST Upsert Clinician`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({}).partial().passthrough()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/admin/companies/APPUSER/',
    alias: 'getAdminCompanies',
    description: `GET companies (clients)`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'rows',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'id',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'name',
        type: 'Query',
        schema: z.string().max(256).optional()
      },
      {
        name: 'exclude_hbs',
        type: 'Query',
        schema: z.string().optional()
      }
    ],
    response: z.array(GetAdminCompaniesResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/admin/company/',
    alias: 'postAdminCompany',
    description: `POST (update) a company (client)`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postAdminCompany_Body
      }
    ],
    response: z.array(PostAdminCompanyResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/admin/company/disable/',
    alias: 'postAdminCompanyDisable',
    description: `POST Disable a company (client)`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postAdminCompanyDisable_Body
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/admin/employers/APPUSER/',
    alias: 'getAdminEmployers',
    description: `GET Employers`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'rows',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'user_type_code',
        type: 'Query',
        schema: z.string().max(100).optional()
      },
      {
        name: 'client_id',
        type: 'Query',
        schema: z.number().optional()
      }
    ],
    response: z.array(GetAdminEmployersResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/admin/hbs_dashboard/APPUSER/',
    alias: 'getAdminHbsdashboard',
    description: `GET HBS Dashboard`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'period',
        type: 'Query',
        schema: z.string()
      },
      {
        name: 'client_id',
        type: 'Query',
        schema: z.number().optional()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/admin/hbs_dashboard/tasks/',
    alias: 'getAdminHbsdashboardTasks',
    description: `GET Dashboard Tasks`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'user_guid',
        type: 'Query',
        schema: z
          .string()
          .max(40)
          .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      },
      {
        name: 'period',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'client_id',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'spec_cat_code',
        type: 'Query',
        schema: z.string().max(30).optional()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/admin/hbs/users/APPUSER/',
    alias: 'getAdminHbsUsers',
    description: `GET HBS users`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'rows',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'name',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'email',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'enabled',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'validated',
        type: 'Query',
        schema: z.string().datetime({ offset: true }).optional()
      },
      {
        name: 'fuzzy',
        type: 'Query',
        schema: z.string().optional()
      }
    ],
    response: z.array(GetAdminHbsUsersResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/admin/invreq/appt_date/APPUSER/',
    alias: 'postAdminInvreqApptdate',
    description: `POST IREQ Status`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postAdminInvreqApptdate_Body
      }
    ],
    response: z.array(PostAdminInvreqApptdateResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/admin/invreq/audit/APPUSER/',
    alias: 'getAdminInvreqAudit',
    description: `GET INVREQ Audit`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'rows',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'case_id',
        type: 'Query',
        schema: z.number()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/admin/invreq/simple/APPUSER/',
    alias: 'getAdminInvreqSimple',
    description: `GET IREQ`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'token',
        type: 'Query',
        schema: z.string().max(70).optional()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/admin/invreq/status/',
    alias: 'postAdminInvreqStatus',
    description: `POST IREQ Status`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postAdminInvreqStatus_Body
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/admin/patients/pifu/',
    alias: 'getAdminPatientsPifu',
    description: `GET patients who are on or have been on a PIFU pathway`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'p_user_guid',
        type: 'Query',
        schema: z
          .string()
          .max(40)
          .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      },
      {
        name: 'expired',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'closed',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'discharged',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'followup_booked',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'pifu_outcome_code',
        type: 'Query',
        schema: z.string().max(30)
      },
      {
        name: 'patient_user_guid',
        type: 'Query',
        schema: z
          .string()
          .max(40)
          .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      },
      {
        name: 'patient_name',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'expires_before',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'expires_after',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'case_ref',
        type: 'Query',
        schema: z.string().max(128).optional()
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'rows',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.string().optional()
      }
    ],
    response: z.array(GetAdminPatientsPifuResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/admin/powerbi/groups/APPUSER/',
    alias: 'getAdminPowerbiGroups',
    description: `GET PowerBI Groups`,
    requestFormat: 'json',
    response: z.array(GetAdminPowerbiGroupsResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/admin/queue/',
    alias: 'postAdminQueue',
    description: `POST queue`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postAdminQueue_Body
      }
    ],
    response: z.array(PostAdminQueueResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/admin/queues/APPUSER/',
    alias: 'getAdminQueues',
    description: `GET Queues`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'rows',
        type: 'Query',
        schema: z.number().optional()
      }
    ],
    response: z.array(GetAdminQueuesResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/admin/referrer/users/APPUSER/',
    alias: 'getAdminReferrerUsers',
    description: `GET Referrer users`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'rows',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'name',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'email',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'enabled',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'validated',
        type: 'Query',
        schema: z.string().datetime({ offset: true }).optional()
      },
      {
        name: 'fuzzy',
        type: 'Query',
        schema: z.string().optional()
      }
    ],
    response: z.array(GetAdminAdminUsersResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/admin/roles/APPUSER/',
    alias: 'getAdminRoles',
    description: `GET specialist categories`,
    requestFormat: 'json',
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/admin/specareas/APPUSER/',
    alias: 'getAdminSpecareas',
    description: `GET specialist areas`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'rows',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'speccat',
        type: 'Query',
        schema: z.string().max(30).optional()
      },
      {
        name: 'empty',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'see_all',
        type: 'Query',
        schema: z.number().optional()
      }
    ],
    response: z.array(GetAdminSpecareasResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/admin/speccats/APPUSER/',
    alias: 'getAdminSpeccats',
    description: `GET specialist categories`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'rows',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'enabled',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'see_all',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'slots',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'self_book',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'pat_guid',
        type: 'Query',
        schema: z
          .string()
          .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
          .optional()
      }
    ],
    response: z.array(GetAdminSpeccatsResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/admin/survey/schedule/APPUSER/',
    alias: 'getAdminSurveySchedule',
    description: `GET Survey Schedules`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'rows',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'client_id',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'type',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'temp_description',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'event_description',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'spec_cat_code',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'spec_area_code',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.string().optional()
      }
    ],
    response: z.array(GetAdminSurveyScheduleResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/admin/surveyjs/',
    alias: 'postAdminSurveyjs',
    description: `POST SurveyJS Definition`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postAdminSurveyjs_Body
      }
    ],
    response: z.array(PostAdminSurveyjsResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/admin/surveyjs/def/APPUSER/',
    alias: 'getAdminSurveyjsDef',
    description: `GET SurveyJS Definition`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'rows',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'surveyjs_def_id',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'draft',
        type: 'Query',
        schema: z.string()
      },
      {
        name: 'name',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'cat_code',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'area_code',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'current',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'event_code',
        type: 'Query',
        schema: z.string().max(10).optional()
      },
      {
        name: 'surveyjs_id',
        type: 'Query',
        schema: z.number().optional()
      }
    ],
    response: z.array(GetAdminSurveyjsDefResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/admin/surveyjs/disable/',
    alias: 'postAdminSurveyjsDisable',
    description: `POST SurveyJS Disable`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postAdminSurveyjsDisable_Body
      }
    ],
    response: z.array(PostAdminSurveyjsResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/admin/surveyjs/events/APPUSER/',
    alias: 'getAdminSurveyjsEvents',
    description: `GET SurveyJS Events`,
    requestFormat: 'json',
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'put',
    path: '/admin/surveyjs/new/',
    alias: 'putAdminSurveyjsNew',
    description: `PUT SurveyJS Survey`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: putAdminSurveyjsNew_Body
      }
    ],
    response: z.array(PostAdminSurveyjsResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/admin/user/',
    alias: 'postAdminUser',
    description: `POST Admin User`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postAdminUser_Body
      }
    ],
    response: z.array(PostAdminUserResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/admin/user/disable/',
    alias: 'postAdminUserDisable',
    description: `POST Disable User`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postAdminUserDisable_Body
      }
    ],
    response: z.array(PostAdminUserResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/booking/appointment/',
    alias: 'postBookingAppointment',
    description: `POST Appointment`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postBookingAppointment_Body
      }
    ],
    response: z.array(PostBookingAppointmentResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'put',
    path: '/booking/appointment/auth/',
    alias: 'putBookingAppointmentAuth',
    description: `PUT Booking Auth`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: putBookingAppointmentAuth_Body
      }
    ],
    response: z.array(PutBookingAppointmentAuthResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'put',
    path: '/booking/appointment/auth/remove/',
    alias: 'putBookingAppointmentAuthRemove',
    description: `PUT Remove Appointment Auths`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: putBookingAppointmentAuthRemove_Body
      }
    ],
    response: z.unknown(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/booking/appointment/cancel/',
    alias: 'postBookingAppointmentCancel',
    description: `POST Appointment Cancel`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postBookingAppointmentCancel_Body
      }
    ],
    response: z.array(PostBookingAppointmentCancelResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/booking/appointment/reschedule/',
    alias: 'postBookingAppointmentReschedule',
    description: `POST Appointment Reschedule`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postBookingAppointmentReschedule_Body
      }
    ],
    response: z.array(PostBookingAppointmentRescheduleResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/booking/appointment/slots/APPUSER/',
    alias: 'getBookingAppointmentSlots',
    description: `GET Appointment Slots`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'start',
        type: 'Query',
        schema: z.string()
      },
      {
        name: 'end',
        type: 'Query',
        schema: z.string()
      },
      {
        name: 'week_offset',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'clin_guid',
        type: 'Query',
        schema: z
          .string()
          .max(40)
          .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
          .optional()
      },
      {
        name: 'clin_subtype',
        type: 'Query',
        schema: z.string().max(100).optional()
      },
      {
        name: 'clin_specarea',
        type: 'Query',
        schema: z.string().max(30).optional()
      },
      {
        name: 'clin_speccat',
        type: 'Query',
        schema: z.string().max(30)
      },
      {
        name: 'patient_guid',
        type: 'Query',
        schema: z
          .string()
          .max(40)
          .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      },
      {
        name: 'appt_type_code',
        type: 'Query',
        schema: z.string().max(30).optional()
      }
    ],
    response: z.array(GetBookingAppointmentSlotsResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/booking/appointment/status/APPUSER/',
    alias: 'getBookingAppointmentStatus',
    description: `GET Appointment Status`,
    requestFormat: 'json',
    response: z.array(GetBookingAppointmentStatusResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/booking/appointment/status/APPUSER/:p_appt_id/',
    alias: 'getBookingAppointmentStatusByApptId',
    description: `GET Appointment Status`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'p_appt_id',
        type: 'Path',
        schema: z.number()
      }
    ],
    response: z.array(GetBookingAppointmentStatusResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'put',
    path: '/booking/appointment/take/APPUSER/:p_appt_id/',
    alias: 'putBookingAppointmentTakeByApptId',
    description: `PUT Take Appointment`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({}).partial().passthrough()
      },
      {
        name: 'p_appt_id',
        type: 'Path',
        schema: z.number()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/booking/appointments/APPUSER/',
    alias: 'getBookingAppointments',
    description: `GET Appointments`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'rows',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'case',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'client_case_id',
        type: 'Query',
        schema: z.string().max(128).optional()
      },
      {
        name: 'patient',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'clinician',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'date_start',
        type: 'Query',
        schema: z.string().datetime({ offset: true }).optional()
      },
      {
        name: 'date_end',
        type: 'Query',
        schema: z.string().datetime({ offset: true }).optional()
      },
      {
        name: 'week_offset',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'completed',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'status',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'active',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'cancelled',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'appt_id',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'upcoming',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'cat_code',
        type: 'Query',
        schema: z.string().max(30).optional()
      },
      {
        name: 'cat_name',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'area_code',
        type: 'Query',
        schema: z.string().max(30).optional()
      },
      {
        name: 'area_name',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'clin_type',
        type: 'Query',
        schema: z.string().max(100).optional()
      },
      {
        name: 'client_code',
        type: 'Query',
        schema: z.string().max(30).optional()
      },
      {
        name: 'pat_guid',
        type: 'Query',
        schema: z
          .string()
          .max(40)
          .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
          .optional()
      },
      {
        name: 'created_from',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'updated_from',
        type: 'Query',
        schema: z.string().datetime({ offset: true }).optional()
      },
      {
        name: 'rescheduled',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'team',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'call_type',
        type: 'Query',
        schema: z.string().optional()
      }
    ],
    response: z.array(GetBookingAppointmentsResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/booking/case/review/',
    alias: 'postBookingCaseReview',
    description: `POST Case Review`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postBookingCaseReview_Body
      }
    ],
    response: z.array(PostBookingCaseReviewResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/cases/APPUSER/',
    alias: 'getCases',
    description: `GET Cases`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'rows',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'case',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'appt',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'patient',
        type: 'Query',
        schema: z
          .string()
          .max(40)
          .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
          .optional()
      },
      {
        name: 'clin_guid',
        type: 'Query',
        schema: z
          .string()
          .max(40)
          .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
          .optional()
      },
      {
        name: 'status',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'first_incomplete',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'active',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'case_ref',
        type: 'Query',
        schema: z.string().max(128).optional()
      },
      {
        name: 'client_id',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'client_name',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'patient_name',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'cat_code',
        type: 'Query',
        schema: z.string().max(30).optional()
      },
      {
        name: 'cat_name',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'area_code',
        type: 'Query',
        schema: z.string().max(30).optional()
      },
      {
        name: 'area_name',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'clin_type',
        type: 'Query',
        schema: z.string().max(100).optional()
      },
      {
        name: 'clinician',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'client_code',
        type: 'Query',
        schema: z.string().max(30).optional()
      },
      {
        name: 'date_start',
        type: 'Query',
        schema: z.string().datetime({ offset: true }).optional()
      },
      {
        name: 'date_end',
        type: 'Query',
        schema: z.string().datetime({ offset: true }).optional()
      },
      {
        name: 'urg_code',
        type: 'Query',
        schema: z.string().max(30).optional()
      },
      {
        name: 'overdue_only',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'team',
        type: 'Query',
        schema: z.string().optional()
      }
    ],
    response: z.array(GetCasesResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/cases/discharge/',
    alias: 'postCasesDischarge',
    description: `POST Case Discharge`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postCasesDischarge_Body
      }
    ],
    response: z.array(PostCasesDischargeResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/cases/discharge/force/',
    alias: 'postCasesDischargeForce',
    description: `POST Case Discharge (Force)`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postCasesDischargeForce_Body
      }
    ],
    response: z.array(PostCasesDischargeResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/cases/eligibility/APPUSER/',
    alias: 'getCasesEligibility',
    description: `GET case eligibility`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'case_id',
        type: 'Query',
        schema: z.number().optional()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/cases/flags/',
    alias: 'postCasesFlags',
    description: `POST Update Case Flags`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postCasesFlags_Body
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'put',
    path: '/cases/new/',
    alias: 'putCasesNew',
    description: `PUT Case`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: putCasesNew_Body
      }
    ],
    response: z.array(PutCasesNewResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/cases/queues/APPUSER/',
    alias: 'getCasesQueues',
    description: `GET Case Review Queues`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'rows',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'case_ref',
        type: 'Query',
        schema: z.string().max(128).optional()
      },
      {
        name: 'patient_name',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'cat_name',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'area_name',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'date_start',
        type: 'Query',
        schema: z.string().datetime({ offset: true }).optional()
      },
      {
        name: 'date_end',
        type: 'Query',
        schema: z.string().datetime({ offset: true }).optional()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/cases/ref/',
    alias: 'postCasesRef',
    description: `POST Case Ref`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postCasesRef_Body
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/cases/reopen/',
    alias: 'postCasesReopen',
    description: `POST Case Reopen`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postCasesReopen_Body
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/cases/triage/',
    alias: 'postCasesTriage',
    description: `POST Case Triage`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postCasesTriage_Body
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/cases/urgency/',
    alias: 'postCasesUrgency',
    description: `POST Case Urgency`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postCasesUrgency_Body
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/cimar/link/APPUSER/',
    alias: 'getCimarLink',
    description: `GET CIMAR link`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'appt_id',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'case_id',
        type: 'Query',
        schema: z.number().optional()
      }
    ],
    response: z.array(GetCimarLinkResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/clinicians/appt/complete/',
    alias: 'postCliniciansApptComplete',
    description: `POST Complete Appointment`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postCliniciansApptComplete_Body
      }
    ],
    response: z.array(PostCliniciansApptCompleteResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/clinicians/appt/delay/',
    alias: 'postCliniciansApptDelay',
    description: `POST Appointment Delay`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postCliniciansApptDelay_Body
      }
    ],
    response: z.array(PostCliniciansApptDelayResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/clinicians/appt/delete/icd/',
    alias: 'postCliniciansApptDeleteIcd',
    description: `POST DELETE Appointment ICD`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postCliniciansApptDelay_Body
      }
    ],
    response: z.array(PostCliniciansApptDeleteIcdResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/clinicians/appt/details/APPUSER/',
    alias: 'getCliniciansApptDetails',
    description: `GET Appt details`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'appt_id',
        type: 'Query',
        schema: z.number()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/clinicians/appt/factsheet/',
    alias: 'postCliniciansApptFactsheet',
    description: `POST Appointment Factsheet`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postCliniciansApptFactsheet_Body
      }
    ],
    response: z.array(PostCliniciansApptFactsheetResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/clinicians/appt/factsheet/APPUSER/',
    alias: 'getCliniciansApptFactsheet',
    description: `GET Appointment Factsheet`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'rows',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'case_id',
        type: 'Query',
        schema: z.number()
      }
    ],
    response: z.array(GetCliniciansApptFactsheetResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/clinicians/appt/icd/',
    alias: 'postCliniciansApptIcd',
    description: `POST Appointment ICD`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postCliniciansApptIcd_Body
      }
    ],
    response: z.array(PostCliniciansApptIcdResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/clinicians/appt/icd/APPUSER/',
    alias: 'getCliniciansApptIcd',
    description: `GET Appointment ICD`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'rows',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'case_id',
        type: 'Query',
        schema: z.number()
      }
    ],
    response: z.array(GetCliniciansApptIcdResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/clinicians/appt/letter_files/',
    alias: 'postCliniciansApptLetterfiles',
    description: `POST Appointment Letter`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postCliniciansApptLetterfiles_Body
      }
    ],
    response: z.array(PostCliniciansApptLetterfilesResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/clinicians/appt/letter_files/APPUSER/',
    alias: 'getCliniciansApptLetterfiles',
    description: `GET Appointment Letters`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'rows',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'appt_id',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'case_id',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'date_start',
        type: 'Query',
        schema: z.string().datetime({ offset: true }).optional()
      },
      {
        name: 'date_end',
        type: 'Query',
        schema: z.string().datetime({ offset: true }).optional()
      },
      {
        name: 'doc_type',
        type: 'Query',
        schema: z.string().optional()
      }
    ],
    response: z.array(GetCliniciansApptLetterfilesResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/clinicians/appt/outcome/',
    alias: 'postCliniciansApptOutcome',
    description: `POST Appointment Outcome`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postCliniciansApptOutcome_Body
      }
    ],
    response: z.array(PostCliniciansApptOutcomeResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/clinicians/appt/pifu/',
    alias: 'postCliniciansApptPifu',
    description: `POST Appointment PIFU`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postCliniciansApptPifu_Body
      }
    ],
    response: z.array(PostCliniciansApptPifuResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/clinicians/appt/pifu/APPUSER/',
    alias: 'getCliniciansApptPifu',
    description: `GET Appointment PIFU`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'appt_id',
        type: 'Query',
        schema: z.number()
      }
    ],
    response: z.array(PostCliniciansApptPifuResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/clinicians/appt/sec/',
    alias: 'postCliniciansApptSec',
    description: `POST Appointment Security`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postCliniciansApptSec_Body
      }
    ],
    response: z.array(PostCliniciansApptSecResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/clinicians/appt/sec/APPUSER/',
    alias: 'getCliniciansApptSec',
    description: `GET Appointment Security`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'appt_id',
        type: 'Query',
        schema: z.number()
      },
      {
        name: 'date_check',
        type: 'Query',
        schema: z.string().datetime({ offset: true }).optional()
      }
    ],
    response: z.array(GetCliniciansApptSecResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/clinicians/appt/suggest/',
    alias: 'postCliniciansApptSuggest',
    description: `POST Appointment General Advice`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({}).partial().passthrough()
      }
    ],
    response: z.array(PostCliniciansApptSuggestResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/clinicians/appt/suggest/APPUSER/:p_appt_id/',
    alias: 'getCliniciansApptSuggestByApptId',
    description: `GET Appointment General Advice`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'p_appt_id',
        type: 'Path',
        schema: z.number()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/clinicians/appt/videolink/APPUSER/',
    alias: 'postCliniciansApptVideolink',
    description: `POST Videoappt link`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ appt_id: z.number() }).passthrough()
      }
    ],
    response: z.array(PostBookingAppointmentRescheduleResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/clinicians/case/rehab/',
    alias: 'postCliniciansCaseRehab',
    description: `POST Case Rehab`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postCliniciansCaseRehab_Body
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/clinicians/case/rehab/APPUSER/',
    alias: 'getCliniciansCaseRehab',
    description: `GET Case Rehab`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'case_id',
        type: 'Query',
        schema: z.number()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/clinicians/clinicians/APPUSER/',
    alias: 'getCliniciansClinicians',
    description: `GET Clinicians`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'rows',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'role',
        type: 'Query',
        schema: z.string().max(100).optional()
      },
      {
        name: 'subtype',
        type: 'Query',
        schema: z.string().max(100).optional()
      },
      {
        name: 'spec_cat',
        type: 'Query',
        schema: z.string().max(30).optional()
      },
      {
        name: 'specarea',
        type: 'Query',
        schema: z.string().max(30).optional()
      },
      {
        name: 'name',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'hospital',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'datetime',
        type: 'Query',
        schema: z.string().datetime({ offset: true }).optional()
      },
      {
        name: 'clin_guid',
        type: 'Query',
        schema: z
          .string()
          .max(40)
          .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
          .optional()
      },
      {
        name: 'status',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'patient_guid',
        type: 'Query',
        schema: z
          .string()
          .max(40)
          .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
          .optional()
      },
      {
        name: 'admin',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'gender',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'lang_code',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'tag_filter',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'rank',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'appt_type_code',
        type: 'Query',
        schema: z.string().max(30).optional()
      },
      {
        name: 'case_id',
        type: 'Query',
        schema: z.number().optional()
      }
    ],
    response: z.array(GetCliniciansCliniciansResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/clinicians/delete/invreq/',
    alias: 'postCliniciansDeleteInvreq',
    description: `POST Delete Investigation Request`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postCliniciansDeleteInvreq_Body
      }
    ],
    response: z.array(PostCliniciansDeleteInvreqResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/clinicians/diary/APPUSER/',
    alias: 'getCliniciansDiary',
    description: `GET Clinician Diary`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'date_start',
        type: 'Query',
        schema: z.string().datetime({ offset: true }).optional()
      },
      {
        name: 'date_end',
        type: 'Query',
        schema: z.string().datetime({ offset: true }).optional()
      },
      {
        name: 'week_offset',
        type: 'Query',
        schema: z.number().optional()
      }
    ],
    response: z.array(GetCliniciansDiaryResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/clinicians/diary/APPUSER/calendar.ics/',
    alias: 'getCliniciansDiaryCalendarics',
    description: `GET Clinician Diary (iCal)`,
    requestFormat: 'json',
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/clinicians/extclins/APPUSER/',
    alias: 'getCliniciansExtclins',
    description: `GET External Clincians`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'spec_cat',
        type: 'Query',
        schema: z.string().max(30).optional()
      },
      {
        name: 'case_id',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'demo',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'ranking',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'user_subtype_code',
        type: 'Query',
        schema: z.string().max(100).optional()
      }
    ],
    response: z.array(GetCliniciansExtclinsResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/clinicians/extclins/rank/',
    alias: 'postCliniciansExtclinsRank',
    description: `POST Rerank Extclins`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({}).partial().passthrough()
      }
    ],
    response: z.array(PostCliniciansExtclinsRankResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/clinicians/invreq/',
    alias: 'postCliniciansInvreq',
    description: `POST Investigation Request`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postCliniciansInvreq_Body
      }
    ],
    response: z.array(PostCliniciansInvreqResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/clinicians/invreq/APPUSER/',
    alias: 'getCliniciansInvreq',
    description: `GET Investigation Requests`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'rows',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'ireq_id',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'appt_id',
        type: 'Query',
        schema: z.number()
      },
      {
        name: 'case_id',
        type: 'Query',
        schema: z.number()
      },
      {
        name: 'completed',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'ireq_state_code',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'overdue_sc',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'overdue_pa',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'date_start',
        type: 'Query',
        schema: z.string().datetime({ offset: true }).optional()
      },
      {
        name: 'date_end',
        type: 'Query',
        schema: z.string().datetime({ offset: true }).optional()
      },
      {
        name: 'clinician',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'patient',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'hospital',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'cancelled',
        type: 'Query',
        schema: z.string().optional()
      }
    ],
    response: z.array(GetCliniciansInvreqResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/clinicians/invreq/provider/',
    alias: 'postCliniciansInvreqProvider',
    description: `POST Investigation Provider`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postCliniciansInvreqProvider_Body
      }
    ],
    response: z.array(PostCliniciansInvreqProviderResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/clinicians/LetterCodes/APPUSER/',
    alias: 'getCliniciansLetterCodes',
    description: `GET Letter Codes`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'appt_id',
        type: 'Query',
        schema: z.number()
      }
    ],
    response: z.array(GetCliniciansLetterCodesResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/clinicians/refclins/APPUSER/',
    alias: 'getCliniciansRefclins',
    description: `GET referral clincians`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'rows',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'spec_cat',
        type: 'Query',
        schema: z.string().max(30).optional()
      },
      {
        name: 'specarea',
        type: 'Query',
        schema: z.string().max(30).optional()
      },
      {
        name: 'clin_guid',
        type: 'Query',
        schema: z
          .string()
          .max(40)
          .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
          .optional()
      },
      {
        name: 'geo_postcode',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'clin_name',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'hospital_name',
        type: 'Query',
        schema: z.string().max(255).optional()
      },
      {
        name: 'case_id',
        type: 'Query',
        schema: z.number().optional()
      }
    ],
    response: z.array(GetCliniciansRefclinsResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/clinicians/sigpharm/',
    alias: 'postCliniciansSigpharm',
    description: `POST Signature Pharmacy Details`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postCliniciansSigpharm_Body
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/clinicians/sigpharm/APPUSER/',
    alias: 'getCliniciansSigpharm',
    description: `GET Signature Pharmacy Details`,
    requestFormat: 'json',
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/clinicians/teams/APPUSER/',
    alias: 'getCliniciansTeams',
    description: `GET User teams`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'lead_user_guid',
        type: 'Query',
        schema: z
          .string()
          .max(40)
          .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/files/appt/files/remove/',
    alias: 'postFilesApptFilesRemove',
    description: `POST Remove file`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postFilesApptFilesRemove_Body
      }
    ],
    response: z.array(PostFilesApptFilesRemoveResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/files/appt/files/upload/',
    alias: 'postFilesApptFilesUpload',
    description: `POST Appt File`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postFilesApptFilesUpload_Body
      }
    ],
    response: z.array(PostFilesApptFilesUploadResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/files/case/files/upload/',
    alias: 'postFilesCaseFilesUpload',
    description: `POST Case file`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postFilesCaseFilesUpload_Body
      }
    ],
    response: z.array(PostFilesCaseFilesUploadResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/files/survey/APPUSER/:p_survey_id/',
    alias: 'getFilesSurveyBySurveyId',
    description: `GET Survey Files`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'p_survey_id',
        type: 'Path',
        schema: z.number()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/gdpr/APPUSER/',
    alias: 'getGdpr',
    description: `GET GDPR Audit Log`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'rows',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'process_code',
        type: 'Query',
        schema: z.string().max(30)
      },
      {
        name: 'case_id',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'appt_id',
        type: 'Query',
        schema: z.number().optional()
      }
    ],
    response: z.array(GetGdprResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'put',
    path: '/gdpr/request/',
    alias: 'putGdprRequest',
    description: `PUT GDPR request`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: putGdprRequest_Body
      }
    ],
    response: z.array(PutGdprRequestResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/intapi/comp/pending/APPUSER/',
    alias: 'getIntapiCompPending',
    description: `GET pending compositions (INTAPI)`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'COMPSTART',
        type: 'Query',
        schema: z.string().max(30).optional()
      }
    ],
    response: z.array(GetIntapiCompPendingResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'put',
    path: '/intapi/comp/ready/',
    alias: 'putIntapiCompReady',
    description: `PUT Composition Ready (INTAPI)`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: putIntapiCompReady_Body
      }
    ],
    response: z.array(PutIntapiCompReadyResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/intapi/meta/invreq/parse/',
    alias: 'getIntapiMetaInvreqParse',
    description: `GET INVREQ parse meta (INTAPI)`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'user_guid',
        type: 'Query',
        schema: z
          .string()
          .max(40)
          .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      },
      {
        name: 'client_id',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'service_cat',
        type: 'Query',
        schema: z.string().max(30).optional()
      },
      {
        name: 'service_type',
        type: 'Query',
        schema: z.string().max(30).optional()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/intapi/outputs/template/html/',
    alias: 'getIntapiOutputsTemplateHtml',
    description: `GET Template html (INTAPI)`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'user_guid',
        type: 'Query',
        schema: z
          .string()
          .max(40)
          .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      },
      {
        name: 'template_code',
        type: 'Query',
        schema: z.string().max(30)
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/intapi/referrer/report/match/',
    alias: 'postIntapiReferrerReportMatch',
    description: `POST report match details (INTAPI)`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postIntapiReferrerReportMatch_Body
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'put',
    path: '/intapi/support/email/',
    alias: 'putIntapiSupportEmail',
    description: `PUT Support Email (INTAPI)`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: putIntapiSupportEmail_Body
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/intapi/users/lucy_id/',
    alias: 'getIntapiUsersLucyid',
    description: `GET User From Lucy ID (INTAPI)`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'user_guid',
        type: 'Query',
        schema: z
          .string()
          .max(40)
          .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      },
      {
        name: 'lucy_id',
        type: 'Query',
        schema: z.string().optional()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/intapi/users/third/',
    alias: 'getIntapiUsersThird',
    description: `GET User Ext (INTAPI)`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'user_guid',
        type: 'Query',
        schema: z
          .string()
          .max(40)
          .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      }
    ],
    response: z.array(GetIntapiUsersThirdResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/medication/appt/prescribe/APPUSER/',
    alias: 'postMedicationApptPrescribe',
    description: `POST appt Prescribe`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postMedicationApptPrescribe_Body
      }
    ],
    response: z.array(PostMedicationApptPrescribeResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/medication/APPUSER/',
    alias: 'getMedication',
    description: `GET Medications`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'appt_id',
        type: 'Query',
        schema: z.number()
      }
    ],
    response: z.array(GetMedicationResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/medication/create/',
    alias: 'postMedicationCreate',
    description: `POST Medication`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postMedicationCreate_Body
      }
    ],
    response: z.array(PostMedicationCreateResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/medication/disable/',
    alias: 'postMedicationDisable',
    description: `POST Medication Disable`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postMedicationDisable_Body
      }
    ],
    response: z.array(PostMedicationDisableResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/medication/vmp/APPUSER/',
    alias: 'getMedicationVmp',
    description: `GET Virtual Medication Product`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'appt_id',
        type: 'Query',
        schema: z.number()
      },
      {
        name: 'vtm_id',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'vmp_id',
        type: 'Query',
        schema: z.number().optional()
      }
    ],
    response: z.array(GetMedicationVmpResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/medication/vtm/APPUSER/',
    alias: 'getMedicationVtm',
    description: `GET Virtual Medication Moiety`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'appt_id',
        type: 'Query',
        schema: z.number()
      },
      {
        name: 'vtm_name',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'vtm_id',
        type: 'Query',
        schema: z.number().optional()
      }
    ],
    response: z.array(GetMedicationVtmResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/meta/appointments/APPUSER/',
    alias: 'getMetaAppointments',
    description: `GET Appointment Meta`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'case_id',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'appt_id',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'pat_guid',
        type: 'Query',
        schema: z
          .string()
          .max(40)
          .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
          .optional()
      },
      {
        name: 'speccat',
        type: 'Query',
        schema: z.string().max(30).optional()
      },
      {
        name: 'auth_flow',
        type: 'Query',
        schema: z.string().optional()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/meta/case/APPUSER/',
    alias: 'getMetaCase',
    description: `GET Case Meta`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'case_id',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'pat_guid',
        type: 'Query',
        schema: z
          .string()
          .max(40)
          .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
          .optional()
      },
      {
        name: 'speccat',
        type: 'Query',
        schema: z.string().max(30).optional()
      }
    ],
    response: z.array(GetMetaCaseResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/meta/clinicians/APPUSER/',
    alias: 'getMetaClinicians',
    description: `GET Clinicians Meta`,
    requestFormat: 'json',
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/meta/configtool/APPUSER/',
    alias: 'getMetaConfigtool',
    description: `GET Config Tool Meta`,
    requestFormat: 'json',
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/meta/extappt/APPUSER/',
    alias: 'getMetaExtappt',
    description: `GET EXTAPPT restricted meta`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'token',
        type: 'Query',
        schema: z.string().max(70).optional()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/meta/gdpr/APPUSER/',
    alias: 'getMetaGdpr',
    description: `GET GDPR Meta`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'process_code',
        type: 'Query',
        schema: z.string().max(30).optional()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/meta/hospital/APPUSER/',
    alias: 'getMetaHospital',
    description: `GET Hospital Meta`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'client_id',
        type: 'Query',
        schema: z.number().optional()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/meta/invreq/APPUSER/',
    alias: 'getMetaInvreq',
    description: `GET Investigations Meta v2`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'p_appt_id',
        type: 'Query',
        schema: z.number().optional()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/meta/invreq/APPUSER/:p_appt_id/',
    alias: 'getMetaInvreqByApptId',
    description: `GET Investigations Meta`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'p_appt_id',
        type: 'Path',
        schema: z.number()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/meta/misc/APPUSER/',
    alias: 'getMetaMisc',
    description: `GET Misc Meta`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'rule_code',
        type: 'Query',
        schema: z.string().max(128).optional()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/meta/misc/APPUSER/:p_up_user_guid/',
    alias: 'getMetaMiscByUpUserGuid',
    description: `GET Misc Meta v2`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'p_up_user_guid',
        type: 'Path',
        schema: z
          .string()
          .max(40)
          .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      },
      {
        name: 'rule_code',
        type: 'Query',
        schema: z.string().max(128).optional()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/meta/outputs/APPUSER/:p_template_code/',
    alias: 'getMetaOutputsByTemplateCode',
    description: `GET output template Meta`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'p_template_code',
        type: 'Path',
        schema: z.string().max(30)
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/meta/refdata/appt/APPUSER/',
    alias: 'getMetaRefdataAppt',
    description: `GET Appt Refdata Meta`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'spec_cat_code',
        type: 'Query',
        schema: z.string().max(30).optional()
      },
      {
        name: 'client_id',
        type: 'Query',
        schema: z.number().optional()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/meta/refdata/APPUSER/',
    alias: 'getMetaRefdata',
    description: `GET Refdata Meta`,
    requestFormat: 'json',
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/meta/suggest/APPUSER/:p_appt_id/',
    alias: 'getMetaSuggestByApptId',
    description: `GET Suggestions Meta`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'p_appt_id',
        type: 'Path',
        schema: z.number()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/meta/support/APPUSER/',
    alias: 'getMetaSupport',
    description: `GET Support Meta`,
    requestFormat: 'json',
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/meta/tasks/APPUSER/',
    alias: 'getMetaTasks',
    description: `GET Task Meta`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'Context',
        type: 'Query',
        schema: z.string().optional()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/meta/tasks2/APPUSER/',
    alias: 'getMetaTasks2',
    description: `GET Task Meta v2`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'Context',
        type: 'Query',
        schema: z.string().optional()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/meta/users/APPUSER/',
    alias: 'getMetaUsers',
    description: `GET User Meta`,
    requestFormat: 'json',
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/notes/appt/',
    alias: 'postNotesAppt',
    description: `POST Appointment Notes`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postNotesAppt_Body
      }
    ],
    response: z.array(PostNotesApptResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/notes/appt/APPUSER/',
    alias: 'getNotesAppt',
    description: `GET Appointment Notes`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'appt_id',
        type: 'Query',
        schema: z.number()
      },
      {
        name: 'note_code',
        type: 'Query',
        schema: z.string().max(30).optional()
      },
      {
        name: 'case_id',
        type: 'Query',
        schema: z.number().optional()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/notes/patient/',
    alias: 'postNotesPatient',
    description: `POST Patient Notes`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postNotesPatient_Body
      }
    ],
    response: z.array(PostNotesPatientResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/notes/patient/APPUSER/',
    alias: 'getNotesPatient',
    description: `GET Patient Notes`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'patient_guid',
        type: 'Query',
        schema: z
          .string()
          .max(40)
          .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
          .optional()
      },
      {
        name: 'case_id',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'appt_id',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'rows',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'category',
        type: 'Query',
        schema: z.string().max(30).optional()
      }
    ],
    response: z.array(GetNotesPatientResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/outputs/boilerplate/APPUSER/:p_boilerplate_code/',
    alias: 'getOutputsBoilerplateByBoilerplateCode',
    description: `GET Boilerplate Text`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'p_boilerplate_code',
        type: 'Path',
        schema: z.string().max(30)
      },
      {
        name: 'p_case_id',
        type: 'Query',
        schema: z.number().optional()
      }
    ],
    response: z.array(GetOutputsBoilerplateByBoilerplateCodeResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/outputs/confluence/APPUSER/:p_category_id/',
    alias: 'getOutputsConfluenceByCategoryId',
    description: `GET Confluence Output`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'p_category_id',
        type: 'Path',
        schema: z.string().max(30)
      }
    ],
    response: z.array(GetOutputsConfluenceByCategoryIdResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/outputs/def/',
    alias: 'postOutputsDef',
    description: `POST Output Definition`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postOutputsDef_Body
      }
    ],
    response: z.array(PostOutputsDefResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/outputs/def/APPUSER/',
    alias: 'getOutputsDef',
    description: `GET Output Definitions`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'rows',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'client_id',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'template_id',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'draft',
        type: 'Query',
        schema: z.string()
      },
      {
        name: 'name',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'current',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'event',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'lang_code',
        type: 'Query',
        schema: z.string().max(2).optional()
      }
    ],
    response: z.array(GetOutputsDefResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/outputs/letter/html/APPUSER/:p_appt_id/:p_letter_code/:p_preview/:p_sub_tokens/',
    alias: 'getOutputsLetterHtmlByApptIdByLetterCodeByPreviewBySubTokens',
    description: `GET Letter HTML`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'p_appt_id',
        type: 'Path',
        schema: z.number()
      },
      {
        name: 'p_letter_code',
        type: 'Path',
        schema: z.string().max(30)
      },
      {
        name: 'p_preview',
        type: 'Path',
        schema: z.string()
      },
      {
        name: 'p_sub_tokens',
        type: 'Path',
        schema: z.string()
      }
    ],
    response: z.array(GetOutputsLetterHtmlByApptIdByLetterCodeByPreviewBySubTokensResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/outputs/pref/',
    alias: 'postOutputsPref',
    description: `POST Notification Preference`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postOutputsPref_Body
      }
    ],
    response: z.array(PostOutputsPrefResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'put',
    path: '/outputs/push/subscription/APPUSER/',
    alias: 'putOutputsPushSubscription',
    description: `PUT Push Subscription`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ subscription: z.string().max(8096) }).passthrough()
      }
    ],
    response: z.array(PutOutputsPushSubscriptionResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/outputs/smspref/',
    alias: 'postOutputsSmspref',
    description: `POST Preference for SMS`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postOutputsSmspref_Body
      }
    ],
    response: z.array(PostOutputsSmsprefResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/outputs/template/html/',
    alias: 'getOutputsTemplateHtml',
    description: `GET Template html`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'user_guid',
        type: 'Query',
        schema: z
          .string()
          .max(40)
          .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      },
      {
        name: 'template_code',
        type: 'Query',
        schema: z.string().max(30)
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/providers/geo/hospitals/',
    alias: 'postProvidersGeoHospitals',
    description: `POST Geo Hospitals`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({}).partial().passthrough()
      }
    ],
    response: z.array(PostProvidersGeoHospitalsResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/providers/hospital/',
    alias: 'postProvidersHospital',
    description: `POST Upsert Hospital`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postProvidersHospital_Body
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/providers/hospital/disable/',
    alias: 'postProvidersHospitalDisable',
    description: `POST Disable Hospital`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postProvidersHospitalDisable_Body
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/providers/hospital/group/',
    alias: 'postProvidersHospitalGroup',
    description: `POST Upsert Hospital Group`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postProvidersHospitalGroup_Body
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/providers/hospital/group/disable/',
    alias: 'postProvidersHospitalGroupDisable',
    description: `POST Disable Hospital Group`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postProvidersHospitalGroupDisable_Body
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/providers/hospital/groups/APPUSER/',
    alias: 'getProvidersHospitalGroups',
    description: `GET Hospital Groups`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'rows',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'ods_code',
        type: 'Query',
        schema: z.string().max(50).optional()
      },
      {
        name: 'name',
        type: 'Query',
        schema: z.string().optional()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/providers/hospital/service/cat/',
    alias: 'postProvidersHospitalServiceCat',
    description: `POST hospital Service Cat`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postProvidersHospitalServiceCat_Body
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/providers/hospital/service/type/',
    alias: 'postProvidersHospitalServiceType',
    description: `POST hospital Service Type`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({}).partial().passthrough()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/providers/hospitals/APPUSER/',
    alias: 'getProvidersHospitals',
    description: `GET Hospitals`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'rows',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'id',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'postcode',
        type: 'Query',
        schema: z.string().max(30).optional()
      },
      {
        name: 'group',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'name',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'type_code',
        type: 'Query',
        schema: z.string().optional()
      }
    ],
    response: z.array(GetProvidersHospitalsResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/providers/imgprov/',
    alias: 'postProvidersImgprov',
    description: `POST Imaging Provider`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({}).partial().passthrough()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/providers/imgprov/disable/',
    alias: 'postProvidersImgprovDisable',
    description: `POST Disable Imaging Provider`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postProvidersImgprovDisable_Body
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/providers/imgprovs/APPUSER/',
    alias: 'getProvidersImgprovs',
    description: `GET Image Providers`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'rows',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'id',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'name',
        type: 'Query',
        schema: z.string().max(255).optional()
      },
      {
        name: 'postcode',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'type',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'group',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'enabled',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'geo_postcode',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'appt_id',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'tag_filter',
        type: 'Query',
        schema: z.string().optional()
      }
    ],
    response: z.array(GetProvidersImgprovsResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/providers/practices/APPUSER/',
    alias: 'getProvidersPractices',
    description: `GET GP Practices`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'rows',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'ods_code',
        type: 'Query',
        schema: z.string().max(50)
      },
      {
        name: 'name',
        type: 'Query',
        schema: z.string().max(128).optional()
      },
      {
        name: 'postcode',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'geo_postcode',
        type: 'Query',
        schema: z.string().optional()
      }
    ],
    response: z.array(GetProvidersPracticesResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/providers/service/provider/enable/',
    alias: 'postProvidersServiceProviderEnable',
    description: `POST Disable Imaging Provider`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postProvidersServiceProviderEnable_Body
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/providers/service/providers/APPUSER/',
    alias: 'getProvidersServiceProviders',
    description: `GET Service Providers`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'rows',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'client_id',
        type: 'Query',
        schema: z.number()
      },
      {
        name: 'service_cat_code',
        type: 'Query',
        schema: z.string().max(30).optional()
      },
      {
        name: 'service_type_code',
        type: 'Query',
        schema: z.string().max(30).optional()
      },
      {
        name: 'group_name',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'p_namehospital_name',
        type: 'Query',
        schema: z.string().optional()
      }
    ],
    response: z.array(GetProvidersServiceProvidersResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/proxy/activate/',
    alias: 'postProxyActivate',
    description: `POST Proxy Activate`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postProxyActivate_Body
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/proxy/auth/',
    alias: 'postProxyAuth',
    description: `POST Proxy Authorisation`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postProxyAuth_Body
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/proxy/deactivate/',
    alias: 'postProxyDeactivate',
    description: `POST Proxy Deactivate`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z
          .object({
            user_guid: z
              .string()
              .max(40)
              .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/)
          })
          .passthrough()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/proxy/matches/APPUSER/',
    alias: 'getProxyMatches',
    description: `GET proxy requests`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'proxy_id',
        type: 'Query',
        schema: z.number().optional()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/proxy/request/',
    alias: 'postProxyRequest',
    description: `POST Proxy Request`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postProxyRequest_Body
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/proxy/requests/APPUSER/',
    alias: 'getProxyRequests',
    description: `GET proxy requests`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'proxy_id',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'authorised',
        type: 'Query',
        schema: z.string().optional()
      }
    ],
    response: z.array(GetProxyRequestsResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/referrers/appointment/dash/APPUSER/',
    alias: 'getReferrersAppointmentDash',
    description: `GET Appointment Dashboard`,
    requestFormat: 'json',
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/referrers/case/refer/',
    alias: 'postReferrersCaseRefer',
    description: `POST Case Referral`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postReferrersCaseRefer_Body
      }
    ],
    response: z.array(PostReferrersCaseReferResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/referrers/case/refer/APPUSER/',
    alias: 'getReferrersCaseRefer',
    description: `GET Case Referral`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'appt_id',
        type: 'Query',
        schema: z.number().optional()
      }
    ],
    response: z.array(GetReferrersCaseReferResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/referrers/case/refer/reject/',
    alias: 'postReferrersCaseReferReject',
    description: `POST Reject Case Referral`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postReferrersCaseReferReject_Body
      }
    ],
    response: z.array(PostReferrersCaseReferRejectResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/referrers/db/refer/APPUSER/',
    alias: 'getReferrersDbRefer',
    description: `GET Direct Book Referrals`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'case_id',
        type: 'Query',
        schema: z.number()
      }
    ],
    response: z.array(GetReferrersDbReferResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'put',
    path: '/referrers/eligibility/',
    alias: 'putReferrersEligibility',
    description: `PUT Member Eligibility`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: putReferrersEligibility_Body
      }
    ],
    response: z.array(PutReferrersEligibilityResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/referrers/email/APPUSER/',
    alias: 'getReferrersEmail',
    description: `GET Check Email`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'email',
        type: 'Query',
        schema: z.string().max(256)
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'put',
    path: '/referrers/member/',
    alias: 'putReferrersMember',
    description: `PUT Member`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: putReferrersMember_Body
      }
    ],
    response: z.array(PutReferrersMemberResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/referrers/member/profile/',
    alias: 'postReferrersMemberProfile',
    description: `POST User Profiler`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postReferrersMemberProfile_Body
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/referrers/patients/APPUSER/',
    alias: 'getReferrersPatients',
    description: `GET Patients`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'rows',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'name',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'mem_no',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'tel',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'email',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'pat_guid',
        type: 'Query',
        schema: z
          .string()
          .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
          .optional()
      },
      {
        name: 'client_id',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'dob',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'postcode',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'fuzzy',
        type: 'Query',
        schema: z.string().optional()
      }
    ],
    response: z.array(GetReferrersPatientsResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/surveys/covid19/',
    alias: 'postSurveysCovid19',
    description: `POST Covid 19 Survey`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postSurveysCovid19_Body
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/surveys/surveyjs/',
    alias: 'postSurveysSurveyjs',
    description: `POST SurveyJS Response`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postSurveysSurveyjs_Body
      }
    ],
    response: z.array(PostSurveysSurveyjsResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/surveys/surveyjs/appt/APPUSER/',
    alias: 'getSurveysSurveyjsAppt',
    description: `GET Appt SurveyJS`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'rows',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'appt_id',
        type: 'Query',
        schema: z.number()
      },
      {
        name: 'event_code',
        type: 'Query',
        schema: z.string().max(10)
      },
      {
        name: 'complete',
        type: 'Query',
        schema: z.string()
      },
      {
        name: 'response',
        type: 'Query',
        schema: z.string()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/surveys/surveyjs/APPUSER/',
    alias: 'getSurveysSurveyjs',
    description: `GET User SurveyJS`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'rows',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'case_id',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'appt_id',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'ireq_id',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'surveyjs_id',
        type: 'Query',
        schema: z.number()
      },
      {
        name: 'response',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'gh',
        type: 'Query',
        schema: z.string().optional()
      }
    ],
    response: z.array(GetSurveysSurveyjsResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/surveys/surveyjs/previous/',
    alias: 'postSurveysSurveyjsPrevious',
    description: `POST GET Previous Surveys`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({}).partial().passthrough()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/surveys/surveys/APPUSER/',
    alias: 'getSurveysSurveys',
    description: `GET Surveys`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'rows',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'complete',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'case_id',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.string().optional()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/tags/hospital/',
    alias: 'postTagsHospital',
    description: `POST hospital tags`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({}).partial().passthrough()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/tags/user/',
    alias: 'postTagsUser',
    description: `POST user tags`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({}).partial().passthrough()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/tasks/APPUSER/',
    alias: 'getTasks',
    description: `Query a TASK`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'rows',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'task_title',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'task_type',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'date_start',
        type: 'Query',
        schema: z.string().datetime({ offset: true }).optional()
      },
      {
        name: 'date_end',
        type: 'Query',
        schema: z.string().datetime({ offset: true }).optional()
      },
      {
        name: 'status_code',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'patient_name',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'assigned_by',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'assigned_to',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'company_id',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'task_sub_type',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'client_case_id',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'active',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'appt_id',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'note_search',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'spec_code',
        type: 'Query',
        schema: z.string().max(30).optional()
      },
      {
        name: 'restrict_mode',
        type: 'Query',
        schema: z.string().optional()
      }
    ],
    response: z.array(GetTasksResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/tasks/assignee/APPUSER/',
    alias: 'getTasksAssignee',
    description: `Obtains Assignee for tasks`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'rows',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.string().optional()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/tasks/cancel/',
    alias: 'postTasksCancel',
    description: `CANCEL a TASK`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postTasksCancel_Body
      }
    ],
    response: z.array(PostTasksCancelResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/tasks/complete/',
    alias: 'postTasksComplete',
    description: `COMPLETE a TASK`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postTasksComplete_Body
      }
    ],
    response: z.array(PostTasksCancelResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'put',
    path: '/tasks/create/',
    alias: 'putTasksCreate',
    description: `CREATE a TASK`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: putTasksCreate_Body
      }
    ],
    response: z.array(PostTasksCancelResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/tasks/dashboard/current/APPUSER/',
    alias: 'getTasksDashboardCurrent',
    description: `Obtains daily dashboard figures for tasks`,
    requestFormat: 'json',
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/tasks/dashboard/rank/APPUSER/',
    alias: 'getTasksDashboardRank',
    description: `obtains rank statistics dashboard figures for tasks`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'rows',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'period',
        type: 'Query',
        schema: z.string().optional()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/tasks/dashboard/stats/APPUSER/',
    alias: 'getTasksDashboardStats',
    description: `obtains period statistics dashboard figures for tasks`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'period',
        type: 'Query',
        schema: z.string()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/tasks/dashboard/tasks/APPUSER/',
    alias: 'getTasksDashboardTasks',
    description: `obtains period statistics dashboard figures for tasks`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'rows',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'assignee',
        type: 'Query',
        schema: z
          .string()
          .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
          .optional()
      }
    ],
    response: z.array(GetTasksDashboardTasksResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/tasks/reassign/',
    alias: 'postTasksReassign',
    description: `Reassign a TASK`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postTasksReassign_Body
      }
    ],
    response: z.array(PostTasksCancelResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/users/appt/event/APPUSER/',
    alias: 'getUsersApptEvent',
    description: `GET Appointment Events`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'appt_id',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'user_type_code',
        type: 'Query',
        schema: z.string().max(100).optional()
      },
      {
        name: 'event',
        type: 'Query',
        schema: z.string().max(30).optional()
      }
    ],
    response: z.array(GetIntapiCompPendingResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'put',
    path: '/users/appt/twilio/event/',
    alias: 'putUsersApptTwilioEvent',
    description: `PUT Appointment Twilio Event`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: putUsersApptTwilioEvent_Body
      }
    ],
    response: z.array(PutIntapiCompReadyResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/users/appt/twilio/id/',
    alias: 'postUsersApptTwilioId',
    description: `POST Appointment Twilio ID`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postUsersApptTwilioId_Body
      }
    ],
    response: z.array(PostUsersApptTwilioIdResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'put',
    path: '/users/appt/ws/conn/',
    alias: 'putUsersApptWsConn',
    description: `PUT Websocket Connection`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: putUsersApptWsConn_Body
      }
    ],
    response: z.array(PutUsersApptWsConnResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'put',
    path: '/users/appt/ws/diss/',
    alias: 'putUsersApptWsDiss',
    description: `PUT Websocket Disconnect`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ connection_id: z.string() }).passthrough()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'put',
    path: '/users/checkp/APPUSER/',
    alias: 'putUsersCheckp',
    description: `PUT Check Password`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ pass: z.string().max(64) }).passthrough()
      }
    ],
    response: z.array(PutUsersCheckpResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/users/ers/',
    alias: 'getUsersErs',
    description: `GET ERS Security`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'user_guid',
        type: 'Query',
        schema: z
          .string()
          .max(40)
          .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      },
      {
        name: 'appt_id',
        type: 'Query',
        schema: z.number()
      },
      {
        name: 'ubrn',
        type: 'Query',
        schema: z.string().max(40)
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/users/extra/APPUSER/',
    alias: 'getUsersExtra',
    description: `GET User Extra Fields`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'field_key',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'return_all',
        type: 'Query',
        schema: z.number().optional()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/users/extra/update/APPUSER/',
    alias: 'postUsersExtraUpdate',
    description: `POST User Extra Fields`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ extra_fields: z.string() }).partial().passthrough()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/users/factsheets/APPUSER/',
    alias: 'getUsersFactsheets',
    description: `GET Patient Factsheets`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'rows',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'name',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'id',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'appt_id',
        type: 'Query',
        schema: z.number()
      }
    ],
    response: z.array(GetUsersFactsheetsResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/users/gp/APPUSER/:p_gp_user_guid/',
    alias: 'getUsersGpByGpUserGuid',
    description: `GET Patient GP`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'p_gp_user_guid',
        type: 'Path',
        schema: z
          .string()
          .max(40)
          .regex(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/)
      }
    ],
    response: z.array(GetUsersGpByGpUserGuidResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'put',
    path: '/users/logout/APPUSER/',
    alias: 'putUsersLogout',
    description: `PUT Logout`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({}).partial().passthrough()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'put',
    path: '/users/password/change/',
    alias: 'putUsersPasswordChange',
    description: `PUT Password Change`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z
          .object({
            user_guid: z
              .string()
              .max(40)
              .regex(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/)
          })
          .passthrough()
      }
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/users/policy/',
    alias: 'postUsersPolicy',
    description: `POST Policy`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postUsersPolicy_Body
      }
    ],
    response: z.array(PostUsersPolicyResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/users/policy/APPUSER/',
    alias: 'getUsersPolicy',
    description: `GET Policy`,
    requestFormat: 'json',
    response: z.array(GetUsersPolicyResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/users/policy/APPUSER/:p_policy_code/',
    alias: 'getUsersPolicyByPolicyCode',
    description: `GET Policy`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'p_policy_code',
        type: 'Path',
        schema: z.string().max(30)
      }
    ],
    response: z.array(GetUsersPolicyResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/users/profile/',
    alias: 'postUsersProfile',
    description: `POST User Profile`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postUsersProfile_Body
      }
    ],
    response: z.array(PostUsersProfileResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/users/stripe-db/date/APPUSER/',
    alias: 'postUsersStripedbDate',
    description: `POST Stripe Data`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({}).partial().passthrough()
      }
    ],
    response: z.array(PostUsersStripedbDateResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/users/stripe-db/info/APPUSER/',
    alias: 'getUsersStripedbInfo',
    description: `GET Stripe Data`,
    requestFormat: 'json',
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/users/timeline/APPUSER/',
    alias: 'getUsersTimeline',
    description: `GET Timeline`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'case_id',
        type: 'Query',
        schema: z.number()
      }
    ],
    response: z.array(GetUsersTimelineResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'post',
    path: '/users/timing/event/APPUSER/',
    alias: 'postUsersTimingEvent',
    description: `POST User Timing Event`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postUsersTimingEvent_Body
      }
    ],
    response: z.array(PutIntapiCompReadyResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/users/todo/APPUSER/',
    alias: 'getUsersTodo',
    description: `GET user to dos`,
    requestFormat: 'json',
    response: z.array(GetUsersTodoResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/users/todo/APPUSER/:p_case_id/',
    alias: 'getUsersTodoByCaseId',
    description: `GET user to dos`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'p_case_id',
        type: 'Path',
        schema: z.number()
      }
    ],
    response: z.array(GetUsersTodoResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/users/tour/APPUSER/',
    alias: 'getUsersTour',
    description: `GET user tour properties`,
    requestFormat: 'json',
    response: z.array(GetUsersTourResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/users/user/APPUSER/',
    alias: 'getUsersUser',
    description: `GET User`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'source_url',
        type: 'Query',
        schema: z.string().optional()
      }
    ],
    response: z.array(GetUsersUserResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  },
  {
    method: 'get',
    path: '/users/ws/:p_conn_id/',
    alias: 'getUsersWsByConnId',
    description: `GET Websocket Connections`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'p_conn_id',
        type: 'Path',
        schema: z.string()
      }
    ],
    response: z.array(GetUsersWsByConnIdResponse),
    errors: [
      {
        status: 'default',
        schema: ErrorResponse
      }
    ]
  }
]);

export const api = new Zodios(endpoints);

export function createApiClient(baseUrl: string, options?: ZodiosOptions) {
  return new Zodios(baseUrl, endpoints, options);
}
