import z from 'zod';
import { SpecAreaCodeEnum } from '../enums/specAreaCode';
import { CatCodeEnum } from '../enums/catCode';
import { ClinicianTypeCodeEnum } from '../enums/clinicianTypeCode';
import { ApptTypeCodeEnum } from '../enums/apptTypeCode';

export const CaseSchema = z.object({
  ncaseid: z.number(),
  client_id: z.number(),
  client_name: z.string(),
  case_id: z.number(),
  spec_cat_code: CatCodeEnum,
  cat_desc: z.string(),
  case_status: z.string(),
  case_status_desc: z.string(),
  case_spec_area_code: SpecAreaCodeEnum.nullable(),
  case_spec_area: z.string().nullable(),
  title: z.string(),
  auth_code: z.string().nullable(),
  cases_dropdown: z.string(),
  condition: z.string(),
  case_created_date: z.coerce.date(),
  case_closed_date: z.coerce.date().nullable(),
  case_closed_by: z.string().nullable(),
  case_discharge_code: z.union([z.string(), z.null()]),
  case_discharge_desc: z.union([z.string(), z.null()]),
  case_first_app: z.boolean(),
  appointment_id: z.union([z.number(), z.null()]),
  appt_type_code: ApptTypeCodeEnum.nullable(),
  appt_type_desc: z.union([z.null(), z.string()]),
  imaging_request: z.boolean(),
  appointment_type: z.string(),
  appointment_date: z.coerce.date(),
  patient_user_guid: z.string(),
  patient_title: z.string(),
  patient_no_title: z.string(),
  patient: z.string(),
  patient_membership_no: z.string(),
  patient_email: z.string().email(),
  patient_tel_mobile_no: z.string(),
  patient_tel_other_no: z.string(),
  clinician_user_guid: z.string().nullable(),
  clinician: z.string().nullable(),
  clinician_type: z.string().nullable(),
  clinician_avatar_url: z.string().url().nullable(),
  datetime: z.coerce.date(),
  status: z.string(),
  cr_survey_overdue: z.boolean(),
  cr_overdue: z.boolean(),
  sr_appt_overdue: z.boolean(),
  sr_appt2_overdue: z.boolean(),
  client_case_id: z.string(),
  cancel_flag: z.boolean(),
  patient_appt_quota: z.number(),
  appt_bookable: z.boolean(),
  appt_auth_available: z.boolean(),
  patient_appt_type_code: ApptTypeCodeEnum.nullable(),
  patient_clin_subtype_code: ClinicianTypeCodeEnum.nullable(),
  appt_outcome_code: z.union([z.null(), z.string()]),
  appt_outcome_desc: z.union([z.null(), z.string()]),
  case_eligibility: z.number(),
  patient_can_reopen: z.boolean(),
  patient_can_reopen_until: z.null(),
  appt_auth_expiry: z.union([z.coerce.date(), z.null()]),
  complex_case: z.boolean()
});
export type Case = z.infer<typeof CaseSchema>;
