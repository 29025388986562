import * as actionTypes from './actionTypes';

export const participantDisplayLastMessage = () => ({
  type: actionTypes.PARTICIPANT_DISPLAY_LAST_MESSAGE
});

export const participantAddMessage = (message) => ({
  type: actionTypes.PARTICIPANT_ADD_MESSAGE,
  payload: message
});

export const participantHideMessage = () => ({
  type: actionTypes.PARTICIPANT_HIDE_MESSAGE
});

export const enqueueOutgoingMessage = (message) => ({
  type: actionTypes.PARTICIPANT_ENQUEUE_OUTGOING_MESSAGE,
  payload: message
});

export const discardOutgoingMessage = () => ({
  type: actionTypes.PARTICIPANT_DISCARD_OUTGOING_MESSAGE
});
