import React, { createContext, useContext, ReactNode, useCallback } from 'react';
import { notification } from 'antd';

type DisplayNotificationFunction = (
  type: 'success' | 'info' | 'warning' | 'error',
  message: string,
  description: ReactNode
) => void;

const NotificationContext = createContext<DisplayNotificationFunction | null>(null);

interface NotificationProviderProps {
  children: ReactNode;
}

export function NotificationProvider({ children }: NotificationProviderProps) {
  const displayNotification: DisplayNotificationFunction = useCallback(
    (type, message, description) => {
      notification[type]({
        message,
        description,
      });
    },
    []
  );

  return (
    <NotificationContext.Provider value={displayNotification}>
      {children}
    </NotificationContext.Provider>
  );
}

export function useNotification(): DisplayNotificationFunction {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
}
