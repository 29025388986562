import React, { useEffect, useState } from 'react';
import useArticle from '@hooks/useArticle';
import useSetTourAttributes from '@hooks/useSetTourAttributes';
import CookieBanner from './CookieBanner';
import CookieSettings from './CookieSettings';

const CookieContainer = ({ cookiePreferencesUpdated }: { cookiePreferencesUpdated: () => void }) => {
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [bannerOpen, setBannerOpen] = useState(true);
  const article = useArticle('cookies', true);

  const acceptCookies = (cookies?: string[]) => {
    if (cookies) {
      localStorage.setItem('cookie-consent', cookies.map((cookie) => cookie.substring(0, 3)).join(' '));
      setSettingsOpen(false);
      setBannerOpen(false);
    } else {
      localStorage.setItem('cookie-consent', 'all');
      setSettingsOpen(false);
      setBannerOpen(false);
    }

    cookiePreferencesUpdated();
  };

  return (
    <div>
      {settingsOpen && (
        <CookieSettings
          setSettingsOpen={setSettingsOpen}
          acceptCookies={acceptCookies}
          setBannerOpen={setBannerOpen}
          article={article}
        />
      )}
      <CookieBanner
        setSettingsOpen={setSettingsOpen}
        acceptCookies={acceptCookies}
        setBannerOpen={setBannerOpen}
        bannerOpen={bannerOpen}
      />
    </div>
  );
};

const CookieContainerWrapper = ({
  cookiePreferencesUpdated,
  tourActive
}: {
  cookiePreferencesUpdated: () => void;
  tourActive: boolean;
}) => {
  const areCookiesSet = localStorage.getItem('cookie-consent');
  const setTourAttributes = useSetTourAttributes();

  useEffect(() => {
    if (areCookiesSet) {
      setTourAttributes().then();
    }
  }, [setTourAttributes, areCookiesSet, tourActive]);

  if (areCookiesSet) return null;

  return <CookieContainer cookiePreferencesUpdated={cookiePreferencesUpdated} />;
};

export default CookieContainerWrapper;
