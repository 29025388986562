import { useCallback, useEffect, useMemo, useState } from 'react';
import useSharedAppointmentWebSocket from '@hooks/useSharedAppointmentWebSocket';
import useMyRole from '@hooks/useMyRole';
import { useRuntimeConfig } from '@vl-core/hooks/useConfig';
import useLocalStorage from 'use-local-storage';
import useAppointmentEvents from '@hooks/useAppointmentEvents';

// todo - separate out "appointment" parameters from the other
export interface SessionAppointment {
  clinician_user_guid: string;
  patient_user_guid: string;
  appointment_id: number;
  case_id: number;
  role: 'patient' | 'clinician';
  // eslint-disable-next-line no-unused-vars
  onChat?: (msg: string) => void;
  // eslint-disable-next-line no-unused-vars
  onControlMessage?: (msg: Object) => void;
  name?: string;
  patient: string;
  client_case_id: string;
}

interface Callbacks {
  onChat?: (msg: string) => void;
  onControlMessage?: (msg: object) => void;
  onTokenReceived?: (token: string, participant: string) => void;
}

export function useTwilioWS(appointment, callbacks?: Callbacks, role?, url = undefined) {
  const { TWILIO_BACKEND_URL, PVIDEO } = useRuntimeConfig() as any;
  const { onChat, onControlMessage, onTokenReceived } = callbacks || {};
  const { appointment_id, clinician_user_guid, patient_user_guid } = appointment;
  const myRole = useMyRole();
  const [roomName, setRoomName] = useState('');
  const [token, setToken] = useState('');
  const onMessage = useCallback(
    (message) => {
      const text = message.data.toString();

      // A video call token
      if (text.slice(0, 2) === 'tk') {
        if (PVIDEO === 'chime') {
          const [, room, ...token] = text.split('&');
          console.log('room', room);
          let joinObject = null;
          try {
            joinObject = JSON.parse(token?.join());
          } catch (e) {
            console.log('unable to parse chime join token');
          }
          console.log('token', joinObject);
          setRoomName(room);
          setToken(joinObject);
        } else {
          // eslint-disable-next-line no-unused-vars
          const [, room, token, participantGuid] = text.split('&');

          if ([clinician_user_guid, patient_user_guid].includes(participantGuid)) {
            setRoomName(room);
            setToken(token);
            sessionStorage.setItem('userInCall', '1');
          } else {
            console.log('Forwarding a token');
            onTokenReceived?.(token, participantGuid);
          }
        }
      } else if (text.startsWith('chat&')) {
        onChat?.(text.slice(5));
      } else if (text.startsWith('control&')) {
        const control = JSON.parse(text.slice(8));
        onControlMessage?.(control);
      }
    },
    [onChat, onControlMessage]
  );
  const appointmentIdentifiers = useMemo(
    () => ({ appointment_id, clinician_user_guid, patient_user_guid }),
    [appointment_id, clinician_user_guid, patient_user_guid]
  );
  const {
    client,
    send,
    close,
    connected: wsConnected
  } = useSharedAppointmentWebSocket(appointmentIdentifiers, myRole, onMessage, url || TWILIO_BACKEND_URL);
  const [connected, setConnected] = useState(false);
  const [, setSequenceNumber] = useLocalStorage('control-sequence-number', 0);
  const { callover } = useAppointmentEvents(appointment);

  useEffect(() => {
    if (client) {
      const { onopen = () => {}, onclose = () => {} } = client;

      client.onopen = () => {
        onopen();
        setConnected(true);
      };

      client.onclose = (ev) => {
        setConnected(false);
        onclose(ev);
      };

      setConnected(client.readyState === client.OPEN || wsConnected);

      return () => {
        client.onopen = onopen;
        client.onclose = onclose;
      };
    }

    return () => {};
  }, [client, wsConnected]);

  const sendControlMessage = useCallback(
    (msg) => {
      if (connected && send) {
        setSequenceNumber((n) => {
          send('control', `control&${JSON.stringify({ ...msg, timestamp: new Date(), sequenceNumber: n + 1 })}`);
          return n + 1;
        });
        console.log('Successfully sent', msg);
      } else {
        console.log('Couldnt send', msg);
      }
    },
    [connected, setSequenceNumber, send]
  );

  const sendTokenRequest = useCallback(
    (participant?: string, additionalOptions: any = {}) => {
      console.log('token gen hit:', participant);
      const options = { provider: PVIDEO, ...additionalOptions };
      if (participant) {
        options.participantGUID = participant;
      }
      if (send) {
        send('tokgen', '', options);
      }
    },
    [send, PVIDEO]
  );

  const chat = useCallback(
    (message: string) => {
      send('chat', message);
    },
    [send]
  );

  useEffect(() => {
    if (client) {
      client.subscribe(onMessage);

      return () => client.unsubscribe(onMessage);
    }

    return () => {};
  }, [client, onMessage]);

  useEffect(() => {
    if (callover) {
      setTimeout(() => close(), 1000);
    }
  }, [callover, close]);

  return {
    client,
    roomName,
    token,
    chat,
    connected,
    sendControlMessage,
    sendTokenRequest,
    open: connected,
    close
  };
}
