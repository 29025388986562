import { memoize } from 'lodash';
import { errorModal } from 'vl-common/src/components/Modals';

const pingWebSockets = async (url: string): Promise<'error' | 'success'> => {
  const ws = new WebSocket(`${url}?ping=1`);

  return new Promise((resolve) => {
    ws.onopen = () => {
      ws.close(1000, 'PING');
      resolve('success');
    };

    ws.onerror = () => {
      ws.close(1000, 'PING');
      resolve('error');
    };
  });
};

const checkWebSockets = memoize(async (url: string): Promise<void> => {
  const status = await pingWebSockets(url);

  if (status === 'error') {
    errorModal({
      title: 'Unable to establish a WebSocket connection',
      content:
        'It is unlikely that video calls will work properly on this network connection. This may be due to a firewall or proxy configuration. Contact your network administrator for assistance.'
    });
  }
});

export default checkWebSockets;
