import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '@actions';
import { useTwilioWS } from '@hooks/twilioWS';
import useMyRole from '@hooks/useMyRole';
import type { Appointment } from 'vl-common/src/schemas/shared';

/**
 * Custom hook (for clinician use only) which, reminiscent of useState(), returns a two element
 * list of delay (in minutes) and an updater function (which takes no arguments). The implementation
 * handles the heavy lifting of updating the backend and informing the patient whenever the delay changes.
 * The first time the updater function is called the backend adds a 5 minute delay to the appointment. The
 * second time the function is called an additional 5 minutes is added, after which no further delay can be
 * added.
 *
 * @param appointment
 * @returns [delayInMinutes, setDelayInMinutes]
 */
function useDelayAppointment(appointment: Appointment): [number, () => void] {
  const { appointment_id, appointment_delay_mins } = appointment;
  const [delay, setDelay] = useState<number>(appointment_delay_mins || 0);
  const dispatch = useDispatch();
  const role = useMyRole();
  const { sendControlMessage, open } = useTwilioWS(appointment);
  const nullUpdater = useCallback(() => {}, []);
  const updater = useCallback(() => {
    const p = dispatch(actions.delayAppointment(appointment_id));

    return p.then(({ delay_minutes }) => {
      setDelay((current_delay) => {
        if (delay_minutes) {
          if (open) sendControlMessage({ delay: delay_minutes });
        }

        return delay_minutes || current_delay;
      });
    });
  }, [appointment_id, dispatch, sendControlMessage, open]);

  return [delay, role === 'clinician' ? updater : nullUpdater];
}

export default useDelayAppointment;
