import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTwilioWS } from '@hooks/twilioWS';
import useMyRole from '@hooks/useMyRole';
import * as actions from '@actions';
import { useDispatch } from 'react-redux';
import { NumberParam, useQueryParam } from 'use-query-params';

type Appointment = any;

/**
 * Custom hook to be used by the patient (will not yield updates on the clinician's side) which will notify
 * when the clinician delays the start of the appointment or cancels the appointment (as controlled by
 * useDelayAppointment()).
 *
 * @param appointment
 * @returns {delayInMinutes}
 */
// todo - remove delay
function useAppointmentDelays(appointment: Appointment): { delayInMinutes: number; delay: () => void } {
  const { appointment_id, appointment_delay_mins } = appointment;
  const myRole = useMyRole();
  const dispatch = useDispatch();
  const [queryDelay, setQueryDelay] = useQueryParam(`delay_for_${appointment_id}`, NumberParam);
  const [delayInMinutes, setDelayInMinutes] = useState(Math.max(appointment_delay_mins, queryDelay || 0));

  useEffect(() => {
    setDelayInMinutes(appointment_delay_mins);
  }, [appointment_id]);

  const onControlMessage = useCallback(
    (msg: Record<string, any>) => {
      if ('delay' in msg) {
        setDelayInMinutes(msg.delay);
        setQueryDelay(msg.delay);
      }
    },
    [setQueryDelay]
  );
  const { sendControlMessage } = useTwilioWS(
    appointment,
    useMemo(() => ({ onControlMessage }), [onControlMessage])
  );

  const nullUpdater = useCallback(() => {}, []);
  const updater = useCallback(() => {
    const p = dispatch(actions.delayAppointment(appointment_id));

    return p.then(({ delay_minutes }) => {
      setDelayInMinutes((current_delay) => {
        if (delay_minutes) {
          sendControlMessage({ delay: delay_minutes });
        }

        return delay_minutes || current_delay;
      });
    });
  }, [appointment_id, dispatch, sendControlMessage]);

  return { delayInMinutes, delay: myRole === 'clinician' ? updater : nullUpdater };
}

export default useAppointmentDelays;
