import { useCallback } from 'react';
import useCallStatus from '@hooks/useCallStatus';
import useCallStatusUpdaters from '@hooks/useCallStatusUpdaters';
import useUser from '@vl-core/hooks/useUser';
import { Appointment } from 'vl-common/src/schemas/shared/appointment';
import { useTwilioWS } from './twilioWS';

const useGrabAppointment = (appointment: Appointment) => {
  const { sendControlMessage, open } = useTwilioWS(appointment);
  const { appointmentTaken: sendAppointmentTakenEvent } = useCallStatusUpdaters(appointment);
  const { first_name, last_name, title, user_guid } = useUser();
  const newClinicianName = `${title} ${first_name} ${last_name}`;

  const callStatus = useCallStatus(appointment);

  const grabAppointment = useCallback(async () => {
    if (callStatus === 'not-started') {
      // if successful res, continue with appointment take
      try {
        sendControlMessage({
          clinicianChangedFrom: appointment.clinician_user_guid,
          clinicianChangedTo: user_guid,
          newClinicianName
        });
        sendAppointmentTakenEvent().then();
        return 'success';
      } catch (e) {
        return 'failed';
      }
    }
    return 'appointment grab too late';
  }, [
    appointment.clinician_user_guid,
    callStatus,
    sendAppointmentTakenEvent,
    sendControlMessage,
    newClinicianName,
    user_guid
  ]);

  return open ? grabAppointment : null;
};

export default useGrabAppointment;
