import { useEffect, useState } from 'react';
import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';
import useRuntimeConfig from '@vl-core/hooks/useConfig';
import { Auth } from 'aws-amplify';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { EventEmitter } from 'events';
import { useDispatch } from 'react-redux';
import * as actions from '@actions';
import { ICloseEvent, IMessageEvent, IStringified, w3cwebsocket as WebSocket } from 'websocket';
import useMyRole from '@hooks/useMyRole';
import useTodaysAppointments from '@hooks/useTodaysAppointments';
import { MAX_RECONNECTION_COUNT, RECONNECTION_DELAY_MS } from '@hooks/websocketConnectionConfig';

// optional additional delay before returning the underlying WebSocket, simulating a slow network connection
const NETWORK_TEST_WEB_SOCKET_DELAY_SECONDS = 0;

function isCompletedControlMessage(data) {
  if (data?.toString().startsWith('control&')) {
    const control = JSON.parse(data?.toString().split('&')[1]);
    if (control.callStatus === 'completed') {
      return true;
    }
  }

  return false;
}

export class MockWebSocket {
  static MOCKED_WEB_SOCKETS = false;

  onopen: () => void;

  onerror: (error: Error) => void;

  onclose: (event: ICloseEvent) => void;

  onmessage: (message: IMessageEvent) => void;

  send(data: ArrayBufferView | ArrayBuffer | Buffer | IStringified): void {
    //
  }

  close(code?: number, reason?: string): void {
    //
  }
}

// Private class to create singleton objects that "track" the status of a given appointment.
class AppointmentEventsListener {
  // WebSocket connected to the other party in a call
  private ws?: WebSocket | MockWebSocket;

  // a list of all "events" (messages) ever sent to this party (from the other party).
  // NB: the last element is the "base status" as read from the DB by actions.getAppointmentStatus,
  // and all other elements are pushed onto the events array as they are received.
  public readonly events: any[] = [];

  private explicitlyClosed = false;

  // send a heartbeat every minute so that AWS doesn't kill the socket dur to inactivity
  private interval = setInterval(() => this.heartbeat(), 60 * 1000);

  public static reconnectionCount = 0;

  // communicate changes to any subscriber
  public readonly twilio = new EventEmitter();

  constructor(
    queryClient: QueryClient,
    private appointment,
    private myRole,
    theirRole,
    twilioBaseUrl,
    dispatch,
    tokens,
    appointments
  ) {
    const { appointment_id, clinician_user_guid, patient_user_guid } = appointment;
    const token = tokens.getIdToken().getJwtToken();
    const u = new URL(twilioBaseUrl);

    const enqueueMessage = ({ data }) => {
      if (isCompletedControlMessage(data)) {
        setTimeout(() => this.close(), 3 * 1000);
      }
      this.events.unshift({ message: data, timestamp: new Date() });
    };

    // If there is no appointment_id (which happens when the Slider tries to render an upcoming appointment booking -
    // see VLNHS-771), then don't even try to open the socket - there's nothing to connect to!
    if (!appointment_id) {
      return;
    }

    u.searchParams.append('role', myRole);
    u.searchParams.append('appointment_id', appointment_id);
    u.searchParams.append('clinician_user_guid', clinician_user_guid);
    u.searchParams.append('patient_user_guid', patient_user_guid);
    u.searchParams.append('authorizer', token);

    this.twilio.setMaxListeners(0);
    this.ws = MockWebSocket.MOCKED_WEB_SOCKETS ? new MockWebSocket() : new WebSocket(u.toString());

    // initially (until after the base is read from the DB) gather any WebSocket events BUT DON'T TELL ANYONE
    this.ws.onmessage = enqueueMessage;

    this.ws.onerror = (error) => {
      if (!this.explicitlyClosed) {
        // something went wrong. The safest thing to do is to start again.
        console.error('A WebSocket error occurred, restarting', error);
        recoverWebSocket().then();
      }
    };

    this.ws.onopen = () => {
      if (!this.explicitlyClosed) {
        this.twilio.emit('connected', true);
      }
    };

    this.ws.onclose = () => {
      console.log('useAppointmentEvents WebSocket closed');
      this.twilio.emit('connected', false);

      clearInterval(this.interval);

      if (!this.explicitlyClosed) {
        // this.closed === false implies someone else closed the WebSocket. The safest thing to do is to start again.
        console.error('A WebSocket was unexpectedly closed, restarting');
        recoverWebSocket().then();
      }
    };

    // this function gets called if a WebSocket unexpectedly closes. It may have caused production problems if/when
    // firewall connections are blocked (VLAXA-1163) - hence the delay
    async function recoverWebSocket() {
      AppointmentEventsListener.reconnectionCount += 1;

      if (AppointmentEventsListener.reconnectionCount >= MAX_RECONNECTION_COUNT) {
        console.log('Exceeded MAX_RECONNECTION_COUNT', AppointmentEventsListener.reconnectionCount);
        return;
      }

      await new Promise((resolve) => setTimeout(resolve, RECONNECTION_DELAY_MS));
      try {
        await queryClient.invalidateQueries(['useTodaysAppointments']);
        await queryClient.invalidateQueries([
          'useAppointmentEvents',
          appointment_id,
          clinician_user_guid,
          patient_user_guid
        ]);
      } catch (e) {
        console.error('Trouble recovering from from WebSocket failure', e);
        window.location.reload();
      }
    }

    // try to find the appointment in the appointments list - saves a possible 429
    async function getAppointmentStatus(appointment_id) {
      // "appointments" is actually the appointment + status.
      const appointmentAndStatus = appointments.find((a) => a.appointment_id === appointment_id);

      if (appointmentAndStatus) return { appointment_stati: [appointmentAndStatus] };

      return dispatch(actions.getAppointmentStatus(appointment_id));
    }

    if (MockWebSocket.MOCKED_WEB_SOCKETS) return;

    // query the database for the base appointment status
    getAppointmentStatus(appointment_id).then((initial) => {
      // now I've read the base state of the appointment, emit "events" from the emitter to tell subscribers that there
      // has been a change
      this.ws.onmessage = (message) => {
        enqueueMessage(message);
        this.twilio.emit('events', [...this.events]);
      };
      if (initial.appointment_stati) {
        const status = initial.appointment_stati[0];

        this.events.push(status);

        if (status.clin_end) {
          this.close();
        }
      }
      this.twilio.emit('events', [...this.events]);
    });
  }

  close = () => {
    this.explicitlyClosed = true;
    this.ws?.close();
  };

  sendAction = (action, message, options = {}) => {
    const { appointment_id, clinician_user_guid, patient_user_guid } = this.appointment;

    this.ws?.send(
      JSON.stringify({
        action,
        appointmentID: appointment_id,
        role: this.myRole,
        clinicianGUID: clinician_user_guid,
        participantGUID: this.myRole === 'patient' ? patient_user_guid : clinician_user_guid,
        message,
        ...options
      })
    );
  };

  isOpen() {
    if (!this.ws) return false;

    return this.ws.readyState === WebSocket.OPEN;
  }

  heartbeat() {
    this.sendAction('heartbeat', {});
  }
}

async function listenerFactory(
  queryClient: QueryClient,
  appointment,
  myRole,
  theirRole,
  twilioBaseUrl,
  dispatch,
  appointments
) {
  const tokens = await Auth.currentSession();

  if (NETWORK_TEST_WEB_SOCKET_DELAY_SECONDS) {
    await new Promise((resolve) => setTimeout(resolve, NETWORK_TEST_WEB_SOCKET_DELAY_SECONDS * 1000));
    console.log('WS Socket open delay 1 completes');
  }

  return new AppointmentEventsListener(
    queryClient,
    appointment,
    myRole,
    theirRole,
    twilioBaseUrl,
    dispatch,
    tokens,
    appointments
  );
}

type TwilioMessage = any;

/**
 * An efficient hook to access a shared "event" state for a given appointment (event being one of the Twilio events
 * that we issue as the patient/clinician navigates around the application and changes the video call status).
 *
 * @param appointment
 */
export function useAppointmentEvents(appointment) {
  const { TWILIO_BACKEND_URL } = useRuntimeConfig() as any;
  const { appointment_id, clinician_user_guid, patient_user_guid } = appointment;
  const dispatch = useDispatch();
  const [connected, setConnected] = useState(false);
  const [error, setError] = useState<Error>();
  const myRole = useMyRole();
  const theirRole = myRole === 'clinician' ? 'PATIENT' : 'CLINICIAN';
  const { appointments, isSuccess: loaded } = useTodaysAppointments();
  const queryClient = useQueryClient();
  const { data: listener } = useQuery(
    ['useAppointmentEvents', appointment_id, clinician_user_guid, patient_user_guid],
    () => listenerFactory(queryClient, appointment, myRole, theirRole, TWILIO_BACKEND_URL, dispatch, appointments),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: loaded
    }
  );
  const [events, setEvents] = useState<TwilioMessage[]>(listener?.events || []);
  const [callover, setCallover] = useState(false);

  useEffect(() => {
    if (listener) {
      if (listener.isOpen()) setConnected(true);

      listener.twilio.on('events', setEvents).on('connected', setConnected).on('error', setError);
      setEvents(listener.events);
      return () => listener.twilio.off('events', setEvents).off('connected', setConnected).off('error', setError);
    }

    return () => {};
  }, [listener, setEvents]);

  useEffect(() => {
    const base = events[events.length - 1];
    const current = events[0];

    if (base?.clin_end) {
      setCallover(true);
    } else if (current !== base) {
      if (current && isCompletedControlMessage(current.data)) {
        setCallover(true);
      }
    }
  }, [events]);

  return { events, close: listener?.close, connected, error, sendAction: listener?.sendAction, callover };
}

export default useAppointmentEvents;
