import React from 'react';
import { useRuntimeConfig } from '@vl-core/hooks/useConfig';
import assert from 'assert';
import IdentityProviderButton from '@components/Login/IdentityProviderButton';

const IdentityProviderButtons = () => {
  const { IDENTITY_PROVIDERS } = useRuntimeConfig();
  const providers = IDENTITY_PROVIDERS?.providers;

  if (!providers || providers.length === 0) return null;

  return providers
    .filter((p) => p && !p.provider?.includes('-ERR-'))
    .map?.((identityProvider) => {
      assert(identityProvider);

      // eslint-disable-next-line test-selectors/button
      return <IdentityProviderButton key={identityProvider.provider} identityProvider={identityProvider} />;
    });
};

export default IdentityProviderButtons;
