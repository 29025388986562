import { useCallback } from 'react';
import { useTwilioWS } from '@hooks/twilioWS';
import { useDispatch } from 'react-redux';
import * as actions from '@actions';
import useMyRole from '@hooks/useMyRole';
import { CallStatus, TwilioEventStatus } from '@hooks/useCallStatus';
import useAppointmentEvents from '@hooks/useAppointmentEvents';

/**
 * Returns a pair of zero argument "action" functions that (i) record the call status in the backend and (ii)
 * issue WebSocket updates to keep the other party up-to-date. For use by the clinician only.
 *
 * @param appointment
 * @returns {startCall, endCall} action functions taking no parameters ... they do all the heavy lifting
 */
function useCallStatusUpdaters(appointment) {
  const { appointment_id, patient_user_guid } = appointment;
  const dispatch = useDispatch();
  const role = useMyRole();
  const declineEvent = role === 'clinician' ? 'CADECLINED' : 'USRDECLINED';
  const startEvent = role === 'clinician' ? 'CASTART' : 'START';
  const endEvent = role === 'clinician' ? 'CAEND' : 'END';
  const { sendControlMessage, open, close } = useTwilioWS(appointment);
  const { close: closeEventsWS, connected } = useAppointmentEvents(appointment);

  const sendTwilioEvent = useCallback(
    async (callStatus: CallStatus, event: TwilioEventStatus) => {
      if (open) {
        sendControlMessage({ callStatus, event });
        await dispatch(actions.sendTwilioEvent(patient_user_guid, appointment_id, event));

        if (callStatus === 'completed') {
          setTimeout(() => {
            close();
            closeEventsWS?.();
          }, 1000);
        }
      }
    },
    [appointment_id, close, closeEventsWS, dispatch, open, patient_user_guid, sendControlMessage]
  );

  return {
    startCall: useCallback(() => sendTwilioEvent('in-progress', startEvent), [sendTwilioEvent, startEvent]),
    endCall: useCallback(() => sendTwilioEvent('completed', endEvent), [sendTwilioEvent, endEvent]),
    stopCalling: useCallback(() => sendTwilioEvent('not-started', declineEvent), [declineEvent, sendTwilioEvent]),
    dial: useCallback(() => sendTwilioEvent('calling', 'CACALLING'), [sendTwilioEvent]),
    decline: useCallback(() => sendTwilioEvent('not-started', declineEvent), [declineEvent, sendTwilioEvent]),
    appointmentTaken: useCallback(() => sendTwilioEvent('not-started', 'CACHANGED'), [sendTwilioEvent]),
    updatersReady: open && connected
  };
}

export default useCallStatusUpdaters;
