import package_json from '../../../package.json';
import assert from 'assert';

export function getSettingsUrl(origin = typeof window === 'undefined' ? '' : window?.location?.origin || '') {
  assert(origin, 'Expected either an explicit SETTINGS_URL in the environment or a window.location.origin');
  const originSubDomain = new URL(origin).hostname.split('.')[0];

  if (originSubDomain === 'localhost') {
    assert(process.env.SETTINGS_URL, 'process.env.SETTINGS_URL is required when served from "localhost"');
    return process.env.SETTINGS_URL;
  }

  assert(
    origin.match(/https:[/][/].*[.]virtuallucyweb[.]co[.]uk/),
    'origin should match "https://.*.virtuallucyweb.co.uk"'
  );

  const { settings } = package_json;

  for (const [API_SUB_DOMAIN, ORIGIN_SUB_DOMAIN] of Object.entries(settings)) {
    const RE = new RegExp(`^${ORIGIN_SUB_DOMAIN}$`);

    if (RE.test(originSubDomain)) {
      const apiSubDomain = originSubDomain.replace(RE, API_SUB_DOMAIN);
      return `https://${apiSubDomain}.virtuallucyweb.co.uk/v1/triage-settings/${originSubDomain}.virtuallucyweb.co.uk`;
    }
  }

  return `https://nprodapi.virtuallucyweb.co.uk/v1/triage-settings/${originSubDomain}.virtuallucyweb.co.uk`;
}

const IMPORTING_SERVER_SIDE = typeof process !== 'undefined';

if (IMPORTING_SERVER_SIDE) {
  const ORIGIN_SUB_DOMAIN = process.argv?.at(2);

  if (ORIGIN_SUB_DOMAIN) {
    // this module is run directly by the build scripts (nextDev.sh and nextBuild.sh). This has to be the last line echoed
    // by this module otherwise the build scripts will get confused
    console.log(getSettingsUrl(`https://${ORIGIN_SUB_DOMAIN}.virtuallucyweb.co.uk`));
  }
}
