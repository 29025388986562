import * as actionTypes from '@actionTypes';
import { User } from 'vl-common/src/types';

export const userFetchResponse = (type: string, action: User | null, token: string | null, isRestricted: boolean) => ({
  type,
  user: action,
  token,
  isRestricted
});

// TODO: @Alastair Get a better type for user roles
export const userRole = (action: string | null) => ({
  type: actionTypes.AUTH_USER_SET_ROLE,
  role: action
});

export const userLogoutResponse = (error = null) => ({
  type: actionTypes.AUTH_LOGOUT_SUCCESS,
  user: null,
  token: null,
  policy: null,
  role: null,
  isRestricted: null,
  error
});
