import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import * as actions from '@actions';
import { useRuntimeConfig } from '@vl-core/hooks/useConfig';
import IdentityProviderButtons from '@components/Login/IdentityProviderButtons';
import { css } from '@emotion/react';
import { ConfirmationModal } from 'vl-common/src/components/Modals';
import useAuth from '@vl-core/useAuth';

interface LoginProps {
  open: boolean;
  onCancel: (e?: any) => void;
  customText?: string;
}

const PleaseLogIn = ({ open, onCancel, customText }: LoginProps) => {
  const { user } = useAuth();
  const { COGNITO, IDENTITY_PROVIDERS } = useRuntimeConfig();
  const dispatch = useDispatch();
  const router = useRouter();
  const [form] = Form.useForm();

  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [confirmDisabled, setConfirmDisabled] = useState(!user?.email);
  const [emailConfirmed, setEmailConfirmed] = useState(sessionStorage.getItem('emailConfirmed') === 'true');

  const onChange = async () => {
    try {
      await form.validateFields();
      setConfirmDisabled(!form.getFieldValue('username'));
    } catch (e) {
      setConfirmDisabled(true);
    }
  };

  const onClose = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setStep(0);
    setLoading(false);
    if (onCancel) {
      onCancel(e);
    }
  };

  const nextStep = (login?: boolean) => {
    if (login) {
      router.push('/login');
    } else {
      setStep(step + 1);
    }
  };

  const previousStep = () => {
    setStep(step - 1);
  };

  const switchToFullAccount = async () => {
    setLoading(true);
    try {
      await dispatch(actions.switchToFullAccount(form.getFieldValue('username')));
      sessionStorage.setItem('emailConfirmed', 'true');
      setEmailConfirmed(true);
      nextStep(false);
    } catch (e) {
      setLoading(false);
      throw e;
    }
  };

  const determineUserOptions = () => {
    let options = [
      <div
        key="close"
        className="button-container"
        css={css`
          display: grid !important;
          margin-bottom: 8px;
        `}
      >
        <Button
          data-testid="first-cancel-button"
          onClick={onClose}
          size="large"
          block
          css={{
            margin: '0.25em 0 0 0',
            textAlign: 'center',
            flexGrow: 1,
            marginBottom: '8px'
          }}
        >
          Close
        </Button>
      </div>
    ];
    if (COGNITO) {
      options = [
        <div key="normal" css={{ display: 'flex', marginBottom: '16px' }}>
          <Button
            data-testid="second-cancel-button"
            onClick={onClose}
            size="large"
            block
            css={{
              margin: '0.25em 0 0 0',
              textAlign: 'center',
              flexGrow: 1
            }}
          >
            Close
          </Button>
          <Button
            data-testid="cognito-button"
            onClick={() => (user?.auth_type === 'COGNITO' ? {} : nextStep())}
            type="primary"
            size="large"
            block
            css={{
              margin: '0.25em 0 0 0',
              textAlign: 'center',
              flexGrow: 1,
              marginLeft: '8px'
            }}
          >
            {user?.auth_type === 'COGNITO' ? (
              <a rel="noreferrer" target="_blank" href="/login">
                Login
              </a>
            ) : (
              'Create account'
            )}
          </Button>
        </div>
      ];
    }
    if (IDENTITY_PROVIDERS && IDENTITY_PROVIDERS.providers) {
      options.push(
        <div css={{ marginBottom: '8px' }}>
          {/* eslint-disable-next-line test-selectors/button */}
          <IdentityProviderButtons />
          <div css={{ marginTop: '8px', textAlign: 'center' }}>
            Please note that using this login option will redirect you to an external website.
          </div>
        </div>
      );
    }
    return options;
  };

  const steps = [
    {
      content: (
        <>
          {customText && <>{customText}</>}
          {user?.auth_type === 'COGNITO' && !customText && <>To complete this action, please log into your account.</>}
          {user?.auth_type === 'RESTRICTED' && !customText && (
            <>
              To complete this action and gain access to our full range of features, please login using one of the
              methods listed below. If you don't have an account you will be able to create one.
            </>
          )}
        </>
      ),
      footer: determineUserOptions()
    },
    {
      content: (
        <>
          <div css={{ marginBottom: '8px' }}>Please confirm your email address.</div>
          <Form
            name="confirm-email"
            form={form}
            initialValues={{
              username: user?.email
            }}
          >
            <Form.Item
              label="Confirm Email Address"
              name="username"
              validateTrigger="onChange"
              rules={[
                {
                  type: 'email',
                  message: 'Please input your e-mail address!'
                },
                {
                  required: true,
                  message: 'Please input your e-mail address!'
                }
              ]}
            >
              <Input type="email" size="large" autoComplete="off" onChange={onChange} disabled={emailConfirmed} />
            </Form.Item>
          </Form>
        </>
      ),
      footer: [
        <div key="normal" css={{ display: 'flex', marginBottom: '16px' }}>
          <Button
            data-testid="second-cancel-button"
            onClick={previousStep}
            size="large"
            block
            css={{
              margin: '0.25em 0 0 0',
              textAlign: 'center',
              flexGrow: 1
            }}
          >
            Back
          </Button>
          <Button
            data-testid="cognito-button"
            onClick={switchToFullAccount}
            type="primary"
            size="large"
            disabled={confirmDisabled || loading || emailConfirmed}
            block
            css={{
              margin: '0.25em 0 0 0',
              textAlign: 'center',
              flexGrow: 1,
              marginLeft: '8px'
            }}
          >
            Confirm
          </Button>
        </div>
      ]
    },
    {
      content: <>We have sent you an email with instructions on how to create your full account.</>,
      footer: [
        <div key="normal" css={{ display: 'flex', marginBottom: '16px' }}>
          <Button
            data-testid="second-cancel-button"
            onClick={onClose}
            size="large"
            block
            css={{
              margin: '0.25em 0 0 0',
              textAlign: 'center',
              flexGrow: 1
            }}
          >
            Close
          </Button>
        </div>
      ]
    }
  ];

  return (
    <ConfirmationModal
      open={open}
      onCancel={onClose}
      footer={[...steps[step].footer]}
      title="Please log in"
      data-testid="sign-in-modal"
    >
      {steps[step].content}
    </ConfirmationModal>
  );
};

export default PleaseLogIn;
