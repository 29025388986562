import { destroyCookie, parseCookies, setCookie } from 'nookies';
import getConfig from 'next/config';
import { Amplify } from 'aws-amplify';

let localStorgeCtx = null;

class UniversalCookieStorage {
  static setContext(ctx) {
    localStorgeCtx = ctx;
  }

  static setItem(key, value) {
    let newValue = value;
    if (typeof newValue !== 'string' && typeof newValue === 'boolean' && typeof newValue === 'number') {
      newValue = JSON.stringify(value);
    }
    setCookie(localStorgeCtx || null, key, newValue, {
      path: '/',
      secure: process.env.NODE_ENV === 'production'
    });
  }

  static getItem(key) {
    const cookies = localStorgeCtx ? parseCookies(localStorgeCtx) : parseCookies();
    return cookies[key];
  }

  static removeItem(key) {
    const cookies = localStorgeCtx ? parseCookies(localStorgeCtx) : parseCookies();
    destroyCookie(localStorgeCtx || null, key, {
      path: '/'
    });
    return cookies[key];
  }

  static clear() {
    const cookies = localStorgeCtx ? parseCookies(localStorgeCtx) : parseCookies();
    const keys = Object.keys(cookies);
    keys.forEach((key) => {
      destroyCookie(localStorgeCtx || null, key, {
        path: '/'
      });
    });
    return {};
  }
}

const configureAmplify = (ctx, LUCY_ENV) => {
  console.log('Configuring Amplify');
  const { publicRuntimeConfig } = getConfig();
  const {
    APP_REGION,
    APP_USER_POOL_ID,
    APP_CLIENT_ID,
    APP_CLIENT_ID2,
    APP_FLOW,
    AWS_ENDPOINT_NAME,
    AWS_ENDPOINT_URL,
    API_URL,
    AMPLIFY_LOG_LEVEL
  } = publicRuntimeConfig;

  if (AMPLIFY_LOG_LEVEL) {
    Amplify.Logger.LOG_LEVEL = AMPLIFY_LOG_LEVEL;
  }

  UniversalCookieStorage.setContext(ctx);
  // VL uses the localStorage property in various places. NB: if you pass in sessionStorage (which we do) then
  // this is going to redefine localStorage as sessionStorage. Yuk.
  // Object.defineProperty(window, 'localStorage', {
  //   value: ctx,
  //   configurable: true,
  //   enumerable: true,
  //   writable: false
  // });

  const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
  );

  function getRedirectSignInLink() {
    if (LUCY_ENV?.includes('pre')) {
      return 'https://preapi.virtuallucyweb.co.uk/v1/axapatlogout';
    }
    if (LUCY_ENV?.includes('nprod')) {
      return `${isLocalhost ? window.location.origin : identityProviders.redirectSignOut}/login/`;
    }
    if (LUCY_ENV?.includes('prod')) {
      return 'https://prodapi.virtuallucyweb.co.uk/v1/axapatlogout';
    }

    return `${isLocalhost ? window.location.origin : identityProviders.redirectSignOut}/login/`;
  }

  const identityProviders = publicRuntimeConfig.IDENTITY_PROVIDERS;
  const oauth = identityProviders
    ? {
        oauth: {
          domain: identityProviders.domain,
          scope: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
          redirectSignIn: `${isLocalhost ? window.location.origin : identityProviders.redirectSignIn}/login/`,
          redirectSignOut: getRedirectSignInLink(),
          responseType: 'code'
        }
      }
    : {};

  Amplify.configure({
    Auth: {
      mandatorySignId: true,
      region: APP_REGION,
      userPoolId: APP_USER_POOL_ID,
      userPoolWebClientId: sessionStorage.getItem('restricted') ? APP_CLIENT_ID2 : APP_CLIENT_ID,
      authenticationFlowType: APP_FLOW,
      ...oauth
    },
    API: {
      endpoints: [
        {
          name: AWS_ENDPOINT_NAME,
          endpoint: AWS_ENDPOINT_URL || API_URL
        }
      ]
    },
    storage: ctx,
    clientMetadata: { clientOrigin: window.location.origin }
  });

  console.log('Amplify.configure completes');
};

export default configureAmplify;
