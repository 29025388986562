import React, { SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { filterColumn } from '../components/Table/Filter';
import { usePathname } from '@vl-core/hooks/usePathname';
import { useSessionStorage } from 'vl-common/src/hooks/useSessionStorage';
import { TableColumnType } from 'antd';

type FalsableColumn<Row> =
  | (TableColumnType<Row> & {
      filterable?: any;
      dateFilter?: any;
      dataSourceKey?: string;
      filterKey?: string;
      display?: React.ReactNode;
      hasActiveFilter?: boolean;
      dob?: boolean;
    })
  | false;

// remove nullish properties from an object
function removeNullishValues(o: Object) {
  Object.entries(o)
    .filter(([, v]) => !v)
    .forEach(([k]) => delete o[k]);

  return o;
}

const INITIAL_STATE = {
  page: 1
};

function useQueryStateInLocalStorage(activeTab: number) {
  const page = usePathname();

  const key = useMemo(() => {
    return JSON.stringify({ tableFilter: { page, activeTab } });
  }, [activeTab, page]);
  const [value, setValue] = useSessionStorage(key, INITIAL_STATE);

  return [value, setValue] as const;
}

export function useColumnFilters<Row>(columns: FalsableColumn<Row>[], activeTab = 1) {
  const [query, setQuery] = useQueryStateInLocalStorage(activeTab);
  const [tableKey, setTableKey] = useState(0);
  const pageNumber = query?.page || 1;

  const setPageNumber = useCallback((page: number | SetStateAction<number>) => {
    if (typeof page === 'number') {
      setQuery((query) => ({ ...query, page }));
      return;
    }

    setQuery((query) => ({ ...query, page: page(query.page || 1) }));
  }, []);

  const filteredColumns = useMemo(() => {
    const cols = columns.filter((c) => c) as Exclude<FalsableColumn<Row>, false>[];
    return cols
      .map((c) => {
        if (c.filterable) {
          const {
            exact,
            queryKey,
            dateStartFilterKey = '',
            dateEndFilterKey = ''
          } = c.filterable === true ? { exact: false, queryKey: c.dataIndex } : c.filterable;
          const filteredValue = query[queryKey || c.key];
          const dateFiltering = query[dateStartFilterKey] || query[dateEndFilterKey];
          const hasActiveFilter = dateFiltering || ((c.filterable.queryKey || c.key) in query && filteredValue);

          return {
            ...c,
            ...filterColumn(c.display, queryKey, setQuery, query, exact, c.dateFilter, c.dob, c.filterable),
            hasActiveFilter
          };
        }
        if (c.filters) {
          const filteredValue = query[c.filterKey] || c.defaultFilteredValue;
          return {
            ...c,
            filteredValue,
            hasActiveFilter: c.filterKey in query && filteredValue
          };
        }
        return { ...c, filteredValue: null };
      })
      .map(({ hasActiveFilter, display, ...column }) => {
        if (hasActiveFilter) {
          return {
            display: <div style={{ color: 'var(--productPrimary)' }}>{display}</div>,
            hasActiveFilter,
            ...column
          };
        }
        return { display, hasActiveFilter, ...column };
      });
  }, [columns, setQuery, query]);

  const handleTableChange = useCallback(
    (_pagination, filters, sorter, _extra) => {
      const cols = columns.filter((c) => c) as Exclude<FalsableColumn<Row>, false>[];
      const entries = Object.keys(filters)
        .map((key) => cols.find((c) => c.dataSourceKey === key))
        .filter((col) => col.filters)
        .map((col) => [col.filterKey || col.filterable.queryKey, filters[col.dataSourceKey]])
        .filter(([key, _value]) => key);

      const orderTypes = { ascend: 'asc', descend: 'desc' };
      if (Object.keys(sorter).length) {
        entries.push(['sort', sorter.order ? sorter.columnKey : null], ['order', orderTypes[sorter.order] || null]);
      }
      setQuery((query) => {
        return removeNullishValues({ ...query, ...Object.fromEntries(entries), page: 1 });
      });
    },
    [columns]
  );

  return {
    columns: filteredColumns,
    query,
    handleTableChange,
    setQuery,
    clearFilters: useCallback(async () => {
      setQuery(INITIAL_STATE);
      setTableKey((key) => key + 1);
      // I strongly suspect this is a bug in Ant Design. Their recommended way to clear filters is to force
      // a re-render of the table (see https://github.com/ant-design/ant-design/issues/18001). It would
      // appear that there is some internal state that is now persisting between renders which now requires
      // two re-renders.
      await new Promise((resolve) => setTimeout(resolve, 0));
      setTableKey((key) => key + 1);
    }, [setQuery, setTableKey]),
    clearFilter: useCallback(
      async (filter) => {
        const copiedQuery = { ...query, page: 1 };
        delete copiedQuery[filter];
        setQuery(copiedQuery);
        setTableKey((key) => key + 1);
        await new Promise((resolve) => setTimeout(resolve, 0));
        setTableKey((key) => key + 1);
      },
      [query, setQuery]
    ),
    pageNumber,
    setPageNumber,
    tableKey,
    setTableKey
  };
}

export default useColumnFilters;
