import * as actions from '@actions';
import { useDispatch } from 'react-redux';
import { useCallback, useMemo } from 'react';
import useIsRestricted from '@hooks/useIsRestricted';
import useAuth from '@vl-core/useAuth';
import { User } from 'vl-common/src/types';
import { getRuntimeConfig } from 'vl-common/src/hooks/Runtime';
import { Auth } from 'aws-amplify';

const removeNullValues = <T extends Record<string, unknown>>(attributes: T): T => {
  return JSON.parse(JSON.stringify(attributes), (key, value) => (value === null ? undefined : value));
};

const checkForPerfCookieConsent = () => {
  if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
    return localStorage.getItem('cookie-consent') === 'all' || localStorage.getItem('cookie-consent') === 'per';
  }
  return false;
};

export function useSetTourAttributes() {
  const isRestricted = useIsRestricted();
  const dispatch = useDispatch();
  const defaultUser = useMemo(() => ({}), []);
  const user = (useAuth().user || defaultUser) as User;

  return useCallback(
    async (policiesJustAccepted = false) => setTourAttributes(user, isRestricted, dispatch, policiesJustAccepted),
    [dispatch, user, isRestricted]
  );
}

async function setTourAttributes(user: User, isRestricted: boolean, dispatch: any, policiesJustAccepted = false) {
  const { email, user_type_code, policies_accepted: policiesAccepted } = user;

  if ((!policiesAccepted && !policiesJustAccepted) || isRestricted || Auth.authenticatedUser) return;

  const res = await dispatch(actions.getUserTourData());
  const { TOUR_PROVIDER } = getRuntimeConfig();
  const attributes = removeNullValues(res);

  if (
    TOUR_PROVIDER === 'STONLY' &&
    !Auth.authenticatedUser &&
    checkForPerfCookieConsent() &&
    !sessionStorage.getItem('tour-attributes-set')
  ) {
    window?.StonlyWidget?.('identify', email!, {
      role: user_type_code,
      ...attributes
    });
    sessionStorage.setItem('tour-attributes-set', 'true');
  }
}

export default useSetTourAttributes;
