import * as z from 'zod';
import { CallTypeCodeEnum } from './enums/callTypeCode';
import { ApptTypeCodeEnum } from './enums/apptTypeCode';
import { CatCode, CatCodeEnum } from './enums/catCode';
import { axiosInstance, toastOnError } from '@vl-core/api';
import { Options } from './helpers/type-helpers';
import { joinKeys } from './helpers/joinKeys';
import { safeParse } from './helpers/safeParse';
import { BooleanCoercionWithDefault } from '../hooks/Runtime';

export const path = '/meta/appointments/APPUSER';

export const ApptActionCodesSchema = z.enum(['BKVCON', 'INVREQ', 'PRESCR', 'DISCHG', 'REF2AE', 'REFF2F', 'TRCARE']);
export type ApptActionCodes = z.infer<typeof ApptActionCodesSchema>;

const V1ApptOutcomesSchema = z.object({
  appt_outcome_code: z.string(),
  appt_outcome_desc: z.string(),
  appt_gdpr_flag: z.coerce.boolean(),
  appt_raised_task_flag: z.coerce.boolean(),
  appt_raised_task_description: z.string().nullable().optional(),
  appt_auth_optional_flag: z.coerce.boolean(),
  appt_auth_required_flag: z.coerce.boolean(),
  appt_auth_f2f_flag: z.coerce.boolean(),
  appt_f2f_consultant_flag: z.coerce.boolean(),
  appt_f2f_physio_flag: z.coerce.boolean(),
  appt_ireq_flag: z.coerce.boolean(),
  appt_pifu_flag: z.coerce.boolean()
});
export type V1ApptOutcome = z.infer<typeof V1ApptOutcomesSchema>;

const V2ApptOutcomesSchema = z.object({
  appt_outcome_code: z.string(),
  appt_outcome_desc: z.string(),
  appt_actions: z.array(
    z.object({
      appt_action_code: ApptActionCodesSchema,
      appt_action_desc: z.string(),
      enabled: z.coerce.boolean(),
      mandatory: z.coerce.boolean(),
      action_subtypes: z
        .array(
          z.object({
            action_subtype_code: z.string(),
            action_subtype_desc: z.string(),
            auth_enabled: z.coerce.boolean()
          })
        )
        .nullable()
        .default([])
    })
  )
});
export type V2ApptOutcome = z.infer<typeof V2ApptOutcomesSchema>;

export type ApptOutcome = V1ApptOutcome | V2ApptOutcome;

const ApptCancelReasonsSchema = z.object({
  appt_cancel_code: z.string(),
  appt_cancel_desc: z.string()
});

const ApptTypesSchema = z.object({
  appt_type_code: ApptTypeCodeEnum,
  appt_type_desc: z.string()
});

const ApptStatiSchema = z.object({
  appt_status_code: z.string(),
  appt_status_desc: z.string()
});

const CallTypeCodeSchema = z.object({
  call_type_code: CallTypeCodeEnum,
  call_type_desc: z.string()
});

const CatsSchema = z.object({
  cat_code: CatCodeEnum,
  cat_name: z.string(),
  // case_id_mandatory is returned as "true" or "false" and was being incorrectly coerced to true if returned as 'false'
  case_id_mandatory: BooleanCoercionWithDefault(false) as unknown as z.ZodBoolean
});

const ClinSubtypeSchema = z.object({
  user_subtype_code: z.string(),
  user_subtype_desc: z.string()
});

const ApptPrioritiesSchema = z.object({
  appt_pri_no: z.number(),
  appt_pri_desc: z.string()
});

const ApptRttSchema = z.object({
  appt_rtt_no: z.number(),
  appt_rtt_desc: z.string()
});

const PifuOutcomesSchema = z.unknown();

const AppointmentsBaseMetaSchema = {
  appt_stati: z.array(ApptStatiSchema).nullable(),
  appt_types: z.array(ApptTypesSchema).nullable(),
  call_types: z.array(CallTypeCodeSchema).nullable(),
  appt_cancel_reasons: z.array(ApptCancelReasonsSchema).nullable(),
  appt_priorities: z.array(ApptPrioritiesSchema).nullable(),
  appt_rtt: z.array(ApptRttSchema).nullable(),
  pifu_outcomes: z.array(PifuOutcomesSchema).nullable(),
  clin_subtypes: z.array(ClinSubtypeSchema).nullable(),
  case_reviews: z.coerce.boolean(),
  f2f_referral: z.coerce.boolean(),
  genadv: z.coerce.boolean(),
  gp_connect: z.coerce.boolean(),
  appt_meds: z.coerce.boolean(),
  appt_prescriptions: z.coerce.boolean(),
  appt_complex_flags: z.coerce.boolean(),
  appt_red_flags: z.coerce.boolean(),
  pifu_self_book: z.coerce.boolean(),
  requests_tab: z.coerce.boolean(),
  notes_tab: z.coerce.boolean(),
  cats: z.array(CatsSchema).nullable()
};

const AppointmentsMetaSchema = z.discriminatedUnion('appt_version', [
  z.object({ ...AppointmentsBaseMetaSchema, appt_version: z.literal(1), appt_outcomes: z.array(V1ApptOutcomesSchema) }),
  z.object({ ...AppointmentsBaseMetaSchema, appt_version: z.literal(2), appt_outcomes: z.array(V2ApptOutcomesSchema) })
]);
export type AppointmentsMeta = z.infer<typeof AppointmentsMetaSchema>;

export const ResponseSchema = z.object({
  meta: z.array(AppointmentsMetaSchema)
});
export type Response = {
  meta: AppointmentsMeta[];
};

export const ParamsSchema = z.object({
  case_id: z.number().optional(),
  appt_id: z.number().optional(),
  pat_guid: z.string().optional(),
  speccat: z.string().optional(),
  auth_flow: z.boolean().optional()
});

export type Params = {
  case_id?: number;
  appt_id?: number;
  pat_guid?: string;
  speccat?: CatCode;
  auth_flow?: boolean;
};

export const getMetaAppointments = (params?: Params, options?: Options) =>
  axiosInstance
    .get(path, {
      params,
      ...options?.axiosConfig
    })
    .then((res) => safeParse(res.data, z.array(AppointmentsMetaSchema)));

export const getMetaAppointmentsQuery = (params?: Params, options?: Options) =>
  ({
    queryKey: joinKeys(path, params),
    queryFn: () => getMetaAppointments(params, options),
    select: (data: AppointmentsMeta[]) => data[0],
    useErrorBoundary: options?.skipToast ? false : toastOnError,
    staleTime: Infinity
  }) as const;

// Tests
let rs = {} as z.infer<typeof ResponseSchema>;
let rt = {} as Response;

rs = rt;
rt = rs;
