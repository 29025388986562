import * as actionTypes from '@actionTypes';
import { z } from 'zod';
import Auth from 'aws-amplify';
import { fetchData } from './action';

/** @deprecated */
export const setWebcamActive = (isActive) => ({
  type: actionTypes.APPOINTMENT_WEBCAM_STATE,
  payload: isActive
});

/** @deprecated */
export const setTheirStatus = (status) => ({
  type: actionTypes.APPOINTMENT_THEIR_STATUS,
  payload: status
});

export const clinicianStartAppointment = (clinician_user_guid, appointment_id) => {
  return sendTwilioEvent(clinician_user_guid, appointment_id, 'CASTART', 'Clinician started appointment');
};

export const sendPushNotification = (user_guid, appt_id) => {
  const options = {
    params: 'webpush',
    method: 'POST',
    postBody: {
      user_guid,
      appt_id,
      template_code: 'RINGER'
    }
  };
  return fetchData(options);
};

export const sendTwilioEvent = (user_guid, appointment_id, event, message = '') => {
  if (Auth.authenticatedUser) return () => Promise.resolve({});

  const options = {
    params: 'users/appt/twilio/event',
    method: 'PUT',
    postBody: {
      user_guid,
      appt_id: appointment_id,
      success: true,
      event,
      message
    }
  };
  return fetchData(options);
};

export const sendCallID = ({
  appt_id,
  ext_call_id,
  call_type_code
}: {
  appt_id: number;
  ext_call_id: string;
  call_type_code: string;
}) => {
  const options = {
    params: 'clinicians/appt/calls/id/APPUSER',
    method: 'POST',
    postBody: { appt_id, ext_call_id, call_type_code }
  };
  return fetchData(options);
};

export const sendChimeLog = ({
  user_guid,
  appt_id,
  message,
  logLevel = 'INFO',
  timestampMs
}: {
  user_guid: string;
  appt_id: number;
  message: string;
  logLevel?: string;
  timestampMs: number;
}) => {
  const options = {
    params: 'logs/chime',
    method: 'POST',
    postBody: {
      user_guid,
      appt_id,
      logs: [
        {
          sequenceNumber: 0,
          logLevel,
          message,
          timestampMs
        }
      ]
    }
  };
  return fetchData(options);
};

export const sendTwilioID = ({ user_guid, appointment_id, twilio_id, call_type }) => {
  const options = {
    params: 'users/appt/twilio/id',
    method: 'POST',
    postBody: {
      user_guid,
      appt_id: appointment_id,
      twilio_id,
      call_type
    }
  };
  return fetchData(options);
};

export const getTodaysAppointmentsAndStatuses = () => {
  const options = {
    params: 'booking/appointment/status/APPUSER',
    method: 'GET'
  };
  return fetchData(options);
};

export const getAppointmentStatus = (appt_id: number) => {
  const options = {
    params: `booking/appointment/status/APPUSER/${appt_id}`,
    method: 'GET'
  };
  return fetchData(
    options,
    z.object({
      appointment_stati: z.array(
        z.object({
          case_id: z.number(),
          appt_id: z.number(),
          call_type_code: z.string(),
          appt_start_date: z.coerce.date(),
          appt_end_date: z.coerce.date(),
          appointment_completed: z.boolean(),
          appointment_delayed: z.boolean(),
          appointment_delay_mins: z.number(),
          patient_user_guid: z.string(),
          clinician_user_guid: z.string(),
          clin_start: z.coerce.date().nullable(),
          clin_end: z.coerce.date().nullable(),
          clin_audio_start: z.coerce.date().nullable(),
          clin_audio_end: z.coerce.date().nullable(),
          clin_usrwait: z.coerce.date().nullable(),
          clin_last_login_date: z.coerce.date(),
          clin_last_logout_date: z.coerce.date(),
          pat_start: z.coerce.date().nullable(),
          pat_end: z.coerce.date().nullable(),
          pat_usrwait: z.coerce.date().nullable(),
          pat_last_login_date: z.coerce.date(),
          pat_last_logout_date: z.coerce.date(),
          pat_restricted: z.boolean()
        })
      )
    })
  );
};

export const getTwilioEvents = (appt_id, user_type_code, event = undefined) => {
  const options = event
    ? {
        params: `users/appt/event/APPUSER?appt_id=${appt_id}&event=${event}`,
        method: 'GET'
      }
    : {
        params: `users/appt/event/APPUSER?appt_id=${appt_id}&user_type_code=${user_type_code}`,
        method: 'GET',
        postBody: {
          appt_id,
          user_type_code
        }
      };
  return fetchData(
    options,
    z.object({
      events: z.array(z.object({ event: z.enum(['USRPOPUP', 'USRWAIT', 'USRCONN', 'USRENDED', 'CAEND', 'CASTART']) }))
    })
  );
};

export const delayAppointment = (appt_id) => {
  const options = {
    params: 'clinicians/appt/delay',
    method: 'POST',
    postBody: {
      appt_id
    }
  };
  return fetchData(options, z.object({ appt_id: z.number(), case_id: z.number(), delay_minutes: z.number() }));
};

// todo - getting a CORS error on this at the moment
export const tokengen = (room, identity) => {
  const options = {
    params: 'tokengen',
    method: 'GET',
    nobearer: true,
    postBody: {
      room,
      identity
    }
  };
  return fetchData(options);
};

export const directBookRefer = () => {
  const options = {
    params: 'referrers/db/refer/APPUSER',
    method: 'GET'
  };
  return fetchData(options);
};

export const takeAppointment = (appt_id: number) => {
  const options = {
    params: `booking/appointment/take/APPUSER/${appt_id}`,
    method: 'PUT',
    postBody: {}
  };
  return fetchData(options);
};

/** Clincians only */
export const sendOneTimeLink = (appt_id: number) => {
  const options = {
    params: `clinicians/appt/videolink/APPUSER?appt_id=${appt_id}`,
    method: 'POST',
    postBody: {},
    type: actionTypes.ACTION_SUCCESS,
    errorType: actionTypes.ACTION_FAIL
  };
  return fetchData(options);
};
