import React from 'react';
import { Button, Row, Col, Card } from 'antd';
import { RightOutlined, LeftOutlined } from 'antd/icons';

const Pagination = ({
  pageNumber,
  setPageNumber,
  isLastPage
}: {
  pageNumber: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  isLastPage: boolean;
}) => {
  return (
    <div style={{ margin: 20 }}>
      <Row justify="space-between">
        <Col span={8}>
          <Button
            type="primary"
            style={{ padding: '5px 20px' }}
            disabled={pageNumber <= 1}
            onClick={() => setPageNumber((val) => val - 1)}
            className="pagination-button"
            data-testid="previous-page"
          >
            <LeftOutlined />
            Previous
          </Button>
        </Col>
        <Col>
          <Card bodyStyle={{ padding: '0.5em 1em' }} className="pagination-page-number">
            {pageNumber}
          </Card>
        </Col>
        <Col span={8}>
          <Button
            type="primary"
            disabled={isLastPage}
            onClick={() => {
              setPageNumber((val) => val + 1);
            }}
            style={{ float: 'right' }}
            className="pagination-button"
            data-testid="next-page"
          >
            Next
            <RightOutlined />
          </Button>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col span={8}>
          <Button
            type="primary"
            disabled={pageNumber <= 1}
            onClick={() => setPageNumber(1)}
            className="pagination-button"
            data-testid="first-page"
          >
            <LeftOutlined />
            First page
          </Button>
        </Col>
      </Row>
    </div>
  );
};
export default Pagination;
