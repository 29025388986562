import { useQuery } from '@tanstack/react-query';
import { w3cwebsocket as WebSocket } from 'websocket';
import { useCallback, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { getLogger } from 'loglevel';
import { useRuntimeConfig } from '@vl-core/hooks/useConfig';
import useCallStatus from '@hooks/useCallStatus';
import { MAX_RECONNECTION_COUNT, RECONNECTION_DELAY_MS } from '@hooks/websocketConnectionConfig';

// optional additional delay before returning the underlying WebSocket, simulating a slow network connection
const NETWORK_TEST_WEB_SOCKET_DELAY_SECONDS = 0;

const log = getLogger(__filename);

export class MockWebSocketWithSubscriptions {
  static MOCKED_WEB_SOCKETS = false;

  CONNECTING = 0;

  OPEN = 1;

  CLOSING = 2;

  CLOSED = 3;

  readyState = 0;

  explicitlyClosed = false;

  stateHandlers = [];

  onopen() {
    //
  }

  onclose() {
    //
  }

  onerror() {
    //
  }

  heartbeat() {
    //
  }

  close() {
    //
  }

  subscribeToState() {
    //
  }

  unsubscribeToState(handler) {
    //
  }

  sendAction(action, message, options?) {
    //
  }

  subscribe(h) {
    //
  }

  unsubscribe(handler) {
    //
  }
}

// NB: I had issues defining methods on this class - there's something strange about extending WebSocket.
// Define new methods as fields rather than on the prototype chain.
class WebSocketWithSubscriptions extends WebSocket {
  handlers = [];

  stateHandlers = [];

  explicitlyClosed = false;

  public unsubscribe: (handler) => void;

  public sendAction: (action, message, options?) => void;

  public heartbeat: () => void;

  public subscribe: (h) => void;

  public subscribeToState: (h) => void;

  public unsubscribeToState: (handler) => void;

  public static reconnectionCount = 0;

  static appendQueryParameters(url, role, appointment_id, clinician_user_guid, patient_user_guid, token) {
    const u = new URL(url);

    u.searchParams.append('role', role);
    u.searchParams.append('appointment_id', appointment_id);
    u.searchParams.append('clinician_user_guid', clinician_user_guid);
    u.searchParams.append('patient_user_guid', patient_user_guid);
    u.searchParams.append('authorizer', token);

    return u.toString();
  }

  constructor(
    private role,
    private appointment_id,
    private clinician_user_guid,
    private patient_user_guid,
    token,
    url
  ) {
    super(
      WebSocketWithSubscriptions.appendQueryParameters(
        url,
        role,
        appointment_id,
        clinician_user_guid,
        patient_user_guid,
        token
      )
    );

    this.onopen = () => {
      log.log('WebSocket onopen()');
      this.stateHandlers.forEach((h) => h(true));
    };

    this.onmessage = (message) => {
      this.handlers.forEach((h) => h(message));
    };

    this.sendAction = (action, message, options = {}) => {
      const msg = {
        action,
        appointmentID: this.appointment_id,
        role: this.role,
        clinicianGUID: this.clinician_user_guid,
        participantGUID: this.role === 'patient' ? this.patient_user_guid : this.clinician_user_guid,
        message,
        ...options
      };

      if (this.readyState !== this.OPEN) {
        const stateString = {
          [this.OPEN]: 'OPEN',
          [this.CONNECTING]: 'CONNECTING',
          [this.CLOSING]: 'CLOSING',
          [this.CLOSED]: 'CLOSED'
        };

        console.log(`Could not send message since WebSocket was not open (${stateString[this.readyState]}):`, msg);
        return;
      }

      this.send(JSON.stringify(msg));
    };

    this.heartbeat = () => {
      this.sendAction('heartbeat', {});
    };

    this.subscribe = (h) => {
      this.handlers.push(h);
    };

    this.unsubscribe = (handler) => {
      this.handlers = this.handlers.filter((h) => h !== handler);
    };

    this.subscribeToState = (h) => {
      h(this.readyState === this.OPEN);

      if (this.stateHandlers.indexOf(h) >= 0) {
        log.error('Odd, found handler in stateHandlers already');
      }

      this.stateHandlers.push(h);
    };

    this.unsubscribeToState = (handler) => {
      this.stateHandlers = this.stateHandlers.filter((h) => h !== handler);
    };
  }
}

export function useSharedAppointmentWebSocket(appointmentIdentifiers, role, onMessage, url = undefined) {
  const { TWILIO_BACKEND_URL } = useRuntimeConfig() as any;
  const { appointment_id, clinician_user_guid, patient_user_guid } = appointmentIdentifiers;
  const [heartbeat, setIntervalTimer] = useState<NodeJS.Timeout>();
  const status = useCallStatus(appointmentIdentifiers);
  const fetch = useCallback(async () => {
    const tokens = await Auth.currentSession();
    const token = tokens.getIdToken().getJwtToken();

    if (NETWORK_TEST_WEB_SOCKET_DELAY_SECONDS) {
      await new Promise((resolve) => setTimeout(resolve, NETWORK_TEST_WEB_SOCKET_DELAY_SECONDS * 1000));
      console.log('WS Socket open delay 2 completes');
    }

    const ws = MockWebSocketWithSubscriptions.MOCKED_WEB_SOCKETS
      ? new MockWebSocketWithSubscriptions()
      : new WebSocketWithSubscriptions(
          role,
          appointment_id,
          clinician_user_guid,
          patient_user_guid,
          token,
          url || TWILIO_BACKEND_URL
        );

    setIntervalTimer(
      setInterval(
        () => {
          if (ws.readyState === ws.OPEN) {
            ws.heartbeat();
          }
        },
        1 * 60 * 1000
      )
    );

    return ws;
  }, [appointment_id, clinician_user_guid, patient_user_guid, role, url]);
  const result = useQuery<WebSocketWithSubscriptions | MockWebSocketWithSubscriptions>(
    ['shared-websocket', appointment_id, role],
    fetch,
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: status !== 'completed'
    }
  );
  const { refetch, data: client } = result;

  const reconnect = async (e?: Error) => {
    WebSocketWithSubscriptions.reconnectionCount += 1;

    if (WebSocketWithSubscriptions.reconnectionCount >= MAX_RECONNECTION_COUNT) {
      console.log('Exceeded MAX_RECONNECTION_COUNT.', WebSocketWithSubscriptions.reconnectionCount);
      return;
    }

    console.error('A WebSocket error occurred, reconnecting', e);
    await new Promise((resolve) => setTimeout(resolve, RECONNECTION_DELAY_MS));
    refetch().then();
  };

  // recover from errors and/or unexpected closures
  useEffect(() => {
    if (!client) return () => {};

    client.onerror = async (e) => {
      log.error('An error occurred');
      log.error(e);
      reconnect(e).then();
    };

    client.onclose = async () => {
      clearInterval(heartbeat);
      setConnected(false);
      if (client.explicitlyClosed) {
        log.error('Twilio WebSocket closed');
        client.stateHandlers.forEach((h) => h(false));
        return;
      }

      // this.explicitlyClosed === false implies someone else closed the WebSocket. The safest thing to do is to start again.
      reconnect().then();
    };

    // make things easier for the garbage collector
    return () => {
      client.onerror = null;
      client.onclose = null;
    };
  }, [client, heartbeat, refetch]);

  const [connected, setConnected] = useState(false);

  const close = useCallback(() => {
    if (client) {
      client.explicitlyClosed = true;
      setIntervalTimer((intervalTimer) => {
        if (intervalTimer) {
          clearInterval(intervalTimer);
        }
        return undefined;
      });
      setConnected(false);
      if (client.readyState === client.OPEN) client.close();
    }
  }, [client]);

  useEffect(() => {
    if (client) {
      client.subscribeToState((s) => {
        // todo - investigate why this is needed
        setConnected(s);
        setTimeout(() => setConnected(s), 0);
      });

      return () => client.unsubscribeToState(setConnected);
    }

    return () => {};
  }, [client]);

  const send = useCallback(
    (action, message, options?) => {
      if (client && connected) {
        client.sendAction(action, message, options);
      }
    },
    [client, connected]
  );

  return {
    client,
    connected,
    send: connected && send,
    close
  };
}

export default useSharedAppointmentWebSocket;
