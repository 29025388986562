import React, { useContext } from 'react';
import { ThemeContext } from '@lib/context/theme';

const DynamicStylesheet = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <link
      href={`${theme?.stylesheet}?v=${encodeURIComponent(process.env.REACT_APP_UI_VERSION ?? '')}`}
      rel="stylesheet"
    />
  );
};

export default DynamicStylesheet;
