import useAppointmentEvents from '@hooks/useAppointmentEvents';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import subSeconds from 'date-fns/subSeconds';
import { useEffect, useState } from 'react';
import { PartyStatus } from '@hooks/useOtherPartyStatus';
import { CallStatus } from '@hooks/useCallStatus';
import useMyRole from '@hooks/useMyRole';
import { useRuntimeConfig } from '@vl-core/useRuntimeConfig';

const partyStatus = (partyStatus, lastLoggedIn, lastLoggedOut) =>
  partyStatus
    ? {
        status: partyStatus.control.partyStatus as PartyStatus,
        timestamp: new Date(partyStatus.timestamp)
      }
    : {
        status: (lastLoggedIn > lastLoggedOut ? 'connected' : 'disconnected') as PartyStatus,
        timestamp: new Date(lastLoggedIn > lastLoggedOut ? lastLoggedIn : lastLoggedOut)
      };

const participantStatus = (status) => {
  return {
    status: (status ? 'connected' : 'disconnected') as PartyStatus
  };
};

const hasPassedRejoinTimeLimit = (apptEndDate: Date, limitInMinutes: number): boolean => {
  if (!apptEndDate || !limitInMinutes) {
    return false;
  }

  const today = new Date();
  const minutesDifference = differenceInMinutes(today, apptEndDate);

  return minutesDifference > limitInMinutes;
};

const MAX_DIALING_DURATION_SECONDS = 30;

type AdjustedCallStatusType = {
  status: CallStatus;
  timestamp: Date;
  reason?: string;
};

/**
 * Track party status, call status and appointment delays via a singleton
 * @param appointment
 */
export function useAppointmentStatus(appointment) {
  const myRole = useMyRole();
  const { events } = useAppointmentEvents(appointment);
  const { VIDEO_NO_CASTART_AFTER } = useRuntimeConfig();
  const base = events[events.length - 1] || {};
  let connectedParticipants = null;
  const controlEvents = events
    .filter(({ message }) => message?.startsWith('control&'))
    .map(({ message, timestamp }) => ({
      control: JSON.parse(message.toString().split('&')[1]),
      timestamp: new Date(timestamp)
    }));
  if (events && events?.length > 0 && events?.[0]?.message) {
    const mostRecentStatusMessage = events?.filter((event) => {
      if (event?.message?.startsWith('control&')) {
        const messageJSON = JSON.parse(event?.message?.toString()?.split('&')?.[1] || '{}');
        if (messageJSON?.connectedParticipants) {
          return true;
        }
      }
      return false;
    })?.[0];
    if (mostRecentStatusMessage) {
      const messageJSON = JSON.parse(mostRecentStatusMessage?.message?.toString()?.split('&')?.[1] || '{}');
      connectedParticipants = messageJSON?.connectedParticipants;
    }
  }
  const callStatus = controlEvents.find(({ control }) => control?.callStatus);
  const delayStatus = controlEvents.find(({ control }) => control?.delay);
  const { clin_start, clin_end, appt_end_date } = base;
  const defaultCallStatus = clin_start
    ? {
        status: (clin_end ? 'completed' : 'in-progress') as CallStatus,
        timestamp: new Date(clin_end || clin_start)
      }
    : {
        status: 'not-started' as CallStatus,
        timestamp: new Date()
      };
  const patientStatus = controlEvents.find(({ control }) => control?.partyStatus && control?.iam === 'patient');
  const clinicianStatus = controlEvents.find(({ control }) => control?.partyStatus && control?.iam === 'clinician');
  // dummy state variable that allows this hook to trigger a re-render
  const [, setRefresh] = useState(0);
  const refreshAppointmentStatus = () => setRefresh((r) => r + 1);
  const dialingStartTime = callStatus?.control.callStatus === 'calling' && new Date(callStatus?.control.timestamp);
  const waitingForPickupDuration = dialingStartTime ? differenceInSeconds(new Date(), dialingStartTime) : undefined;
  const adjustedCallStatus: AdjustedCallStatusType = callStatus
    ? {
        status: callStatus.control.callStatus as CallStatus,
        timestamp: callStatus.timestamp,
        reason: callStatus.control.event
      }
    : defaultCallStatus;

  // when the call is dialing (waiting for the other party to pickup), ensure that the dialing will terminate
  // after 30 seconds
  useEffect(() => {
    if (!Number.isNaN(waitingForPickupDuration) && waitingForPickupDuration <= MAX_DIALING_DURATION_SECONDS) {
      const timer = setTimeout(
        () => refreshAppointmentStatus(),
        (MAX_DIALING_DURATION_SECONDS + 1 - waitingForPickupDuration) * 1000
      );

      return () => clearTimeout(timer);
    }

    return () => {};
  }, [waitingForPickupDuration]);

  // if it ever looks like the dialing has gone on for more that MAX_DIALING_DURATION_SECONDS then return a call status of not-started
  if (callStatus?.control.callStatus === 'calling' && waitingForPickupDuration >= MAX_DIALING_DURATION_SECONDS) {
    adjustedCallStatus.status = 'not-started';
    adjustedCallStatus.timestamp = subSeconds(adjustedCallStatus.timestamp, waitingForPickupDuration * 1000);
    adjustedCallStatus.reason = 'UNANSWERED';
  }

  // if the latest message is a "connectedParticipants" status update
  if (myRole === 'patient' && controlEvents[0]?.control.connectedParticipants) {
    // if I was displaying the ringer
    if (callStatus?.control.callStatus === 'calling') {
      // but the clinician has disappeared, then dispose of the ringer
      if (!connectedParticipants?.clinician) {
        adjustedCallStatus.status = 'not-started';
        adjustedCallStatus.reason = 'CADECLINED';
      }
    }
  }

  // Check if the call was started but not ended. If the time limit for rejoining has passed,
  // mark the status as complete to prevent further rejoining.
  const isPassedRejoinTimeLimit = hasPassedRejoinTimeLimit(appt_end_date, Number(VIDEO_NO_CASTART_AFTER));
  if (clin_start && !clin_end && isPassedRejoinTimeLimit) {
    adjustedCallStatus.status = 'completed';
  }

  return {
    base,
    controlEvents,
    callStatus: adjustedCallStatus,
    patientStatus: connectedParticipants
      ? participantStatus(connectedParticipants?.patient)
      : partyStatus(patientStatus, base.pat_last_login_date, base.pat_last_logout_date),
    clinicianStatus: connectedParticipants
      ? participantStatus(connectedParticipants?.clinician)
      : partyStatus(clinicianStatus, base.clin_last_login_date, base.clin_last_logout_date),
    delayMins: Number(delayStatus?.control.delay || base.appointment_delay_mins)
  };
}

export default useAppointmentStatus;
