import * as actionTypes from '@actionTypes';
import produce from 'immer';

export const initialState = {
  outboundQueue: [] /** a stack of messages, used as a queue, ready to be sent to other party */,
  messages: [] /** a stack of all message strings ever sent to this participant */,
  displayLastMessage: false /** should the topmost message be displayed? */
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PARTICIPANT_ADD_MESSAGE:
      return produce(state, (draft) => {
        draft.messages.push(action.payload);
        draft.displayLastMessage = true;
      });
    case actionTypes.PARTICIPANT_DISPLAY_LAST_MESSAGE:
      return produce(state, (draft) => {
        draft.displayLastMessage = true;
      });
    case actionTypes.PARTICIPANT_HIDE_MESSAGE:
      return produce(state, (draft) => {
        draft.displayLastMessage = false;
      });
    case actionTypes.PARTICIPANT_ENQUEUE_OUTGOING_MESSAGE:
      return { ...state, outboundQueue: [...state.outboundQueue, action.payload] };
    case actionTypes.PARTICIPANT_DISCARD_OUTGOING_MESSAGE:
      return { ...state, outboundQueue: state.outboundQueue.slice(0, -1) };
    default:
      return state;
  }
};

export default reducer;
