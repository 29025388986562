import { useRuntimeConfig } from 'vl-core/useRuntimeConfig';
import semver from 'semver';

export const useIsPWAEnabled = () => {
  const { PWA_FEATURE_FLAG, PWA_MINIMUM_IOS_VERSION } = useRuntimeConfig();

  // If PWA_FEATURE_FLAG is not explicitly true, return false
  if (!PWA_FEATURE_FLAG) return false;

  // If PWA_FEATURE_FLAG is explicitly true and OS is not iOS, return false
  if (!getIsIOS(navigator.userAgent)) return false;

  // If PWA_MINIMUM_IOS_VERSION is not set, return false
  if (!PWA_MINIMUM_IOS_VERSION) return false;

  // If PWA_MINIMUM_IOS_VERSION is set and OS is iOS, return true if iOS version is greater than or equal to PWA_MINIMUM_IOS_VERSION
  const iOSVersion = getiOSVersion(navigator.userAgent);
  return semver.gte(iOSVersion, PWA_MINIMUM_IOS_VERSION);
};

function getiOSVersion(userAgent: string) {
  const match = userAgent.match(/(iPhone|iPad|iPod);.*? OS (\d+)/);

  if (match && match.length === 3) {
    return match[2];
  }

  return '0';
}

function getIsIOS(userAgent: string) {
  // @ts-expect-error - MSStream is not defined in TS
  return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
}
