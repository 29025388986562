import React, { useEffect, useState } from 'react';
import { Modal, Button, Select, Row, Col } from 'antd';
import TestFilesJSON from '../../public/harIndex.json';

interface TestingModalProps {
  visible: boolean;
  onCancel: () => void;
  enterTestingMode: (file: string, type: string) => void;
  exitTestingMode: () => void;
  inTestingMode: boolean;
  setInTestingMode: React.Dispatch<React.SetStateAction<boolean>>;
}

const { Option } = Select;

const TestingModal = ({
  visible,
  onCancel,
  enterTestingMode,
  exitTestingMode,
  inTestingMode,
  setInTestingMode
}: TestingModalProps) => {
  const [selectedFileName, setSelectedFileName] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedUserType, setSelectedUserType] = useState('');

  useEffect(() => {
    if (visible) {
      setSelectedUserType(sessionStorage.getItem('har')?.split('/')?.[0] || '');
      setInTestingMode(sessionStorage.getItem('mocked') === 'true');
      setSelectedFileName(sessionStorage.getItem('har')?.split('/')?.[1] || '');
    }
  }, [visible]);

  useEffect(() => {
    setSelectedFile(TestFilesJSON?.[selectedUserType]?.find((file) => file.filename === selectedFileName));
  }, [selectedFileName]);

  return (
    <Modal title="Testing mode" open={visible} onCancel={onCancel} footer={[]}>
      <>
        <Row>
          Please confirm your testing setup:
          <Select
            data-testid="testing-user-type-select"
            onChange={(type) => {
              setSelectedUserType(type);
              setSelectedFile(null);
              setSelectedFileName('');
            }}
            style={{ width: '100%' }}
            value={selectedUserType}
          >
            <Option value="">
              <span style={{ color: 'grey' }}>Select Test User Type...</span>
            </Option>
            <Option value="clinician">Clinician</Option>
            <Option value="patient">Patient</Option>
          </Select>
          {selectedUserType !== '' && (
            <Select
              data-testid="testing-mode-select"
              onChange={(item) => setSelectedFileName(item)}
              style={{ width: '100%', marginTop: '8px' }}
              value={selectedFileName}
            >
              <Option value="">
                <span style={{ color: 'grey' }}>Select Test File...</span>
              </Option>
              {TestFilesJSON?.[selectedUserType]?.map((file) => (
                <Option key={file.filename} value={file.filename}>
                  {file.testName} ({file.filename})
                </Option>
              ))}
            </Select>
          )}
          {selectedFile && (
            <div style={{ marginTop: '8px', width: '100%' }}>
              <Row>
                <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                  Description:
                </Col>
                <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                  {selectedFile.description}
                </Col>
              </Row>
              <Row>
                <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                  File Name:
                </Col>
                <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                  {selectedFile.filename}
                </Col>
              </Row>
              <Row>
                <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                  User Name:
                </Col>
                <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                  {selectedFile.userName}
                </Col>
              </Row>
              <Row>
                <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                  User Role:
                </Col>
                <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                  {selectedFile.userRole}
                </Col>
              </Row>
              <Row>
                <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                  Specialty:
                </Col>
                <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                  {selectedFile.specialty || 'N/A'}
                </Col>
              </Row>
              <Row>
                <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                  Environment:
                </Col>
                <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                  {selectedFile.env}
                </Col>
              </Row>
            </div>
          )}
        </Row>
        <Row style={{ width: '100%', marginTop: '16px' }}>
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <Button
              data-testid="close-testing-modal-button"
              style={{ marginRight: '8px' }}
              onClick={() => {
                onCancel();
              }}
            >
              Close
            </Button>
            {inTestingMode && (
              <Button
                data-testid="exit-testing-button"
                style={{ marginRight: '8px' }}
                onClick={() => {
                  exitTestingMode();
                  setSelectedFileName('');
                  setSelectedFile(null);
                  setSelectedUserType('');
                }}
              >
                Exit testing mode
              </Button>
            )}
            <Button
              type="primary"
              data-testid="enter-testing-button"
              disabled={!selectedFileName || inTestingMode}
              onClick={() => enterTestingMode(selectedFileName, selectedUserType)}
            >
              Enter testing mode
            </Button>
          </div>
        </Row>
      </>
    </Modal>
  );
};

export default TestingModal;
