import React from 'react';
import { Modal, ModalFuncProps, ModalProps } from 'antd';

type ConfirmationModalProps = ModalProps;

export const ConfirmationModal = (props: ConfirmationModalProps) => <Modal centered {...props} />;

export const confirmationModal = ({ ...otherProps }: ModalFuncProps) =>
  Modal.confirm({
    centered: true,

    ...otherProps
  });
