import { useCallback, useState, useEffect } from 'react';

const useWindowEvent = <K extends string>(
  type: K,
  listener: K extends keyof WindowEventMap
    ? (this: Window, ev: WindowEventMap[K]) => void
    : (this: Window, ev: CustomEvent) => void,
  options?: boolean | AddEventListenerOptions
) => {
  useEffect(() => {
    window.addEventListener(type as any, listener, options);
    return () => window.removeEventListener(type as any, listener, options);
  }, [type, listener, options]);
};

export const useViewportSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const setSize = () => setWindowSize({ width: window.innerWidth || 0, height: window.innerHeight || 0 });
  useWindowEvent('resize', setSize, { passive: true });
  useWindowEvent('orientationchange', setSize, { passive: true });

  useEffect(setSize, [window.innerWidth, window.innerHeight]);

  return windowSize;
};
