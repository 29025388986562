import * as actionTypes from '@actionTypes';
import { updateObject } from '@helpers/updateObject';

/* Initial */
export const initialState = {
  loading: false,
  error: null,
  satis: null,
  clinicians: null,
  preass: null,
  satisfaction: null,
  cases: null
};

/* Request Start */
const requestStart = (state, _action) =>
  updateObject(state, {
    loading: true,
    error: null
  });

/* Request Error */
const requestError = (state, action) =>
  updateObject(state, {
    loading: false,
    action
  });

/* Request Success */
const requestSuccess = (state, action) => updateObject(state, { loading: false, ...action });

/* Reducer */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PATIENTS_REQUEST_START:
      return requestStart(state, action);
    case actionTypes.PATIENTS_SATISFACTION_SURVEY_QUESTION_FAIL:
    case actionTypes.PATIENTS_SATISFACTION_SURVEY_FAIL:
    case actionTypes.PATIENTS_PREASSETMENTS_FAIL:
    case actionTypes.PATIENTS_PREASSETMENTS_QUESTIONS_FAIL:
    case actionTypes.PATIENTS_PROFILE_FAIL:
    case actionTypes.PATIENTS_ALLCLINICIAN_FAIL:
    case actionTypes.PATIENTS_TIMELINECASE_FAIL:
      return requestError(state, action);
    case actionTypes.PATIENTS_PREASSETMENTS_SUCCESS:
      return requestSuccess(state, { preAssetments: action.payload });
    case actionTypes.PATIENTS_ALLCLINICIAN_SUCCESS:
      return requestSuccess(state, { clinicians: action.payload });
    case actionTypes.PATIENTS_TIMELINECASE_SUCCESS:
      return requestSuccess(state, { cases: action.payload });
    default:
      return state;
  }
};

export default reducer;
