import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useDefault } from '@hooks/useDefault';
import { useCallback, useEffect, useState } from 'react';

function getListFromResult(data) {
  if (!data) return null;

  if (Array.isArray(data)) return data;
  if (data?.cr_queues) return data.cr_queues;

  const arrayValues = Object.values(data).filter((d) => Array.isArray(d));

  if (arrayValues.length !== 1) {
    return null;
  }

  return arrayValues[0];
}

export function usePagedQuery<T = unknown>(thunk, familyKeys, pageNumber, setPageNumber, pageSize = 10) {
  const [totalPages, setTotalPages] = useState(Infinity);
  const [lastPage, setLastPage] = useState(false);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const result = useQuery<T>([familyKeys, thunk.options, pageNumber, pageSize], () => dispatch(thunk), {
    keepPreviousData: true
  });
  // @ts-ignore
  const resultDefault = useDefault(result.data?.payload, {});
  // @ts-ignore
  const rows = getListFromResult(result.data?.payload || result.data) as T[];
  const list = useDefault(rows, []);
  const { isSuccess, isLoading } = result;
  useEffect(() => {
    if (isSuccess) {
      if (rows === null) {
        setTotalPages(pageNumber);
      }
      if (rows?.length > 0 && rows.length !== pageSize) {
        setTotalPages(pageNumber);
      }
      if (rows?.length > 0 && rows.length === pageSize) {
        setTotalPages(Infinity);
      }
    }
  }, [rows, isSuccess, pageSize, pageNumber]);

  useEffect(() => {
    if (pageNumber > totalPages) setPageNumber(totalPages);
  }, [pageNumber, totalPages, setPageNumber]);

  useEffect(() => {
    setLastPage(pageNumber === totalPages);
  }, [pageNumber, totalPages]);

  return {
    page: list,
    prev: useCallback(() => setPageNumber((p) => Math.max(p - 1, 0)), [setPageNumber]),
    next: useCallback(() => setPageNumber((p) => Math.min(p + 1, totalPages)), [setPageNumber, totalPages]),
    totalPages,
    lastPage,
    rawResult: resultDefault,
    refetch: useCallback(() => {
      setTotalPages(Infinity);
      setLastPage(false);
      setPageNumber(1);
      queryClient.invalidateQueries([familyKeys]);
      result.refetch();
    }, []),
    isLoading
  };
}

export default usePagedQuery;
