import React, { useState } from 'react';
import { Button } from 'antd';
import { Article } from '@hooks/useArticle';
import { ConfirmationModal } from 'vl-common/src/components/Modals';
import CookieList from './CookieList';

interface CookieSettingsProps {
  acceptCookies: (cookies?: string[]) => void;
  setSettingsOpen: (open: boolean) => void;
  setBannerOpen: (open: boolean) => void;
  article?: Article;
}

const CookieSettings = ({
  acceptCookies,
  setSettingsOpen,
  setBannerOpen,
  article
}: CookieSettingsProps): JSX.Element => {
  const [cookies, setCookies] = useState<
    Record<
      string,
      {
        accepted: boolean;
        editable?: boolean;
        description: string;
        title: string;
      }
    >
  >({
    essential: {
      accepted: true,
      editable: false,
      description:
        'These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually ' +
        'only set in response to actions made by you which amount to a request for services, such as setting your privacy ' +
        'preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, ' +
        'but some parts of the site will not then work. These cookies do not store any personally identifiable information.',
      title: 'Essential'
    },
    performance: {
      accepted: !!(
        localStorage.getItem('cookie-consent')?.includes('all') ||
        localStorage.getItem('cookie-consent')?.includes('per')
      ),
      editable: true,
      description:
        'These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. ' +
        'They help us to know which pages are the most and least popular and see how visitors move around the site. All information ' +
        'these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies we will not know when ' +
        'you have visited our site, and will not be able to monitor its performance.',
      title: 'Performance'
    }
  });
  const acceptAllCookies = (): void => {
    acceptCookies();
  };

  const updateCookieSettings = () => {
    acceptCookies(Object.keys(cookies).filter((cookie) => cookies[cookie as keyof typeof cookies].accepted));
  };

  return (
    <ConfirmationModal
      title="Privacy Preference Center"
      open
      onCancel={() => {
        setSettingsOpen(false);
        setBannerOpen(true);
      }}
      footer={[
        <Button
          key="close"
          onClick={() => {
            setSettingsOpen(false);
            setBannerOpen(true);
          }}
          data-testid="close"
        >
          Close
        </Button>,
        <Button key="update" onClick={updateCookieSettings} data-testid="confirm-selection">
          Confirm choices
        </Button>,
        <Button key="accept" onClick={acceptAllCookies} type="primary" data-testid="accept-all">
          Accept all cookies
        </Button>
      ]}
    >
      <div>
        {article && <div className="accessibility-statement" dangerouslySetInnerHTML={{ __html: article.body }} />}
        <CookieList cookies={cookies} setCookies={setCookies} />
      </div>
    </ConfirmationModal>
  );
};

export default CookieSettings;
