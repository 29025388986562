import * as z from 'zod';
import { GetParamsSchema, UserSchema } from './shared';
import { CatCodeEnum } from './enums/catCode';
import { TelPrefNoEnum } from './enums/telPrefNo';
import { ClinicianTypeCodeEnum } from './enums/clinicianTypeCode';
import { SpecAreaCodeEnum } from './enums/specAreaCode';

const AdditionalLanguagesSchema = z.object({
  lang_code: z.string(),
  lang_desc: z.string()
});

const ClinicalMeasureSchema = z.object({
  ranking_code: z.string(),
  ranking_desc: z.string(),
  min_rank_value: z.number(),
  max_rank_value: z.number(),
  rank_value: z.number()
});

export const ClinicianSchema = z.object({
  clin_user_guid: z.string(),
  clin_lucy_id: z.string(),
  consultant_id: z.string().nullable(),
  title: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  full_name: z.string(),
  email: z.string(),
  bio: z.string().nullable(),
  qualifications: z.string().nullable(),
  qualification_codes: z.string().nullable(),
  cat_code: CatCodeEnum,
  cat_name: z.string(),
  spec_areas: z.string().nullable(),
  spec_area_codes: z.string().nullable(),
  hospitals: z.string().nullable(),
  regions: z.string().nullable(),
  lang_code: z.string(),
  lang_desc: z.string(),
  add_langs: z.array(AdditionalLanguagesSchema).nullable(),
  subtype: ClinicianTypeCodeEnum,
  subtype_desc: z.string(),
  user_role_code: z.string(),
  user_role_desc: z.string(),
  avatar_url: z.string(),
  signature_url: z.string(),
  gmc: z.string().nullable(),
  hcpc: z.string().nullable(),
  tel_mobile_no: z.string().nullable(),
  tel_other_no: z.string().nullable(),
  client_id: z.number(),
  client_name: z.string(),
  status: z.string(),
  temp_password: z.boolean(),
  slot_duration: z.number(),
  employer_code: z.string().nullable(),
  employer_desc: z.string().nullable(),
  ident_prov_code: z.string(),
  ident_prov_desc: z.string(),
  provider_name: z.string(),
  sex_code: z.string(),
  sex_desc: z.string(),
  info_tags: z.string().nullable(),
  filter_tags: z.string().nullable(),
  geo_region: z.string(),
  timezone: z.string(),
  first_slot: z.string().nullable(),
  fastest_flag: z.boolean(),
  prems_rank: z.number().nullable(),
  last_seen_flag: z.boolean(),
  best_match_flag: z.boolean(),
  clinical_measures: z.array(ClinicalMeasureSchema),
  link1: z.string().nullable(),
  link2: z.string().nullable(),
  pref_notification: TelPrefNoEnum
});

export const ResponseSchema = z.object({
  clinicians: z.array(ClinicianSchema)
});
export type Response = z.infer<typeof ResponseSchema>;

export const ParamsSchema = GetParamsSchema.extend({
  role: z.string().max(100).optional(),
  subtype: ClinicianTypeCodeEnum.optional(),
  spec_cat: CatCodeEnum.optional(),
  specarea: SpecAreaCodeEnum.optional(),
  name: z.string().optional(),
  hospital: z.string().optional(),
  datetime: z.string().optional()
});
export type Params = z.infer<typeof ParamsSchema>;
