import React from 'react';
import { Modal, ModalFuncProps, ModalProps } from 'antd';

type InfoModalProps = ModalProps;

export const InfoModal = ({ onOk, ...rest }: InfoModalProps) => (
  <Modal centered onOk={onOk} onCancel={onOk} cancelButtonProps={{ style: { display: 'none' } }} {...rest} />
);

export const infoModal = ({ ...otherProps }: ModalFuncProps) =>
  Modal.info({
    ...otherProps
  });
