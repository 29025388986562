import { useQuery } from '@tanstack/react-query';
import { getAvatar } from '@actions';
import { useState, useEffect } from 'react';
import { useDispatch } from 'wrapped-redux';

export default function useAvatar(userId: string): string {
  const dispatch = useDispatch();
  const avatarData = useQuery<any>(
    ['avatar', userId],
    () => (userId ? dispatch(getAvatar(userId)) : Promise.resolve(null)),
    {
      cacheTime: Infinity,
      staleTime: Infinity
    }
  );
  const [avatar, setAvatar] = useState('');

  useEffect(() => {
    if (avatarData.data && avatarData.data.base64) {
      setAvatar(`data:${avatarData.data.ContentType};base64,${avatarData.data.base64}`);
    }
  }, [avatarData]);

  return avatar;
}
