import * as actionTypes from './actionTypes';
import { fetchData } from './action';

export const requestStart = (type) => ({
  type
});

export const requestFail = (type, error) => ({
  error
});

/* patientsRescheduleConfirm */
export const rescheduleConfirm = (request) => {
  const requestOption = {
    params: 'booking/appointment/reschedule',
    method: 'POST',
    postBody: request,
    actionType: actionTypes.RESCHEDULE_CONFIRM_SUCCESS,
    errorType: actionTypes.RESCHEDULE_CONFIRM_FAIL
  };
  return fetchData(requestOption);
};

/* scheduleConfirm */
export const scheduleConfirm = (request) => {
  const requestOption = {
    params: 'booking/appointment',
    method: 'POST',
    postBody: request,
    actionType: actionTypes.RESCHEDULE_CONFIRM_SUCCESS,
    errorType: actionTypes.RESCHEDULE_CONFIRM_FAIL
  };
  return fetchData(requestOption);
};

/* patientsRescheduleCancelMeta */
export const rescheduleCancelMeta = () => {
  const requestOption = {
    params: 'meta/appointments/APPUSER',
    actionType: actionTypes.RESCHEDULE_CANCELMETA_SUCCESS,
    errorType: actionTypes.RESCHEDULE_CANCELMETA_FAIL
  };
  return fetchData(requestOption);
};

/* patientsRescheduleCancel */
export const rescheduleCancel = (request) => {
  const requestOption = {
    params: 'booking/appointment/cancel',
    method: 'POST',
    postBody: request,
    actionType: actionTypes.RESCHEDULE_CANCEL_SUCCESS,
    errorType: actionTypes.RESCHEDULE_CANCEL_FAIL
  };
  return fetchData(requestOption);
};

export const rescheduleCalendar = (request) => {
  const parameter = Object.keys(request)
    .map((key) => `${key}=${request[key]}`)
    .join('&');
  const requestOption = {
    params: `booking/appointment/slots/APPUSER?${parameter}`,
    actionType: actionTypes.RESCHEDULE_CALENDAR_SUCCESS,
    errorType: actionTypes.RESCHEDULE_CALENDAR_FAIL
  };
  return fetchData(requestOption);
};

/* createTask */
export const cancelAppoinmentTask = (request) => {
  const requestOption = {
    params: 'tasks/create',
    method: 'PUT',
    postBody: request,
    actionType: actionTypes.RESCHEDULE_CANCELAPPOINTMENT_TASK_SUCCESS,
    errorType: actionTypes.RESCHEDULE_CANCELAPPOINTMENT_TASK_FAIL
  };
  return fetchData(requestOption);
};
