import React from 'react';
import { Button, Drawer } from 'antd';
import styled from '@emotion/styled';
import { CloseOutlined } from 'antd/icons';

interface MoreMenuProps {
  open: boolean;
  onClose: () => void;
  options: {
    name: string;
    action: () => void;
    active: boolean;
    testid: string;
    Icon: any;
    restricted?: boolean;
  }[];
}

const StyledButton = styled.button<{ active: boolean }>`
  width: 100%;
  border: 0;
  height: 20%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 8px;
  padding: 4px 16px;
  font-size: 18px;
  background-color: ${({ active }) => (active ? '#ffffff' : 'var(--productPrimary)')};
  color: ${({ active }) => (active ? 'var(--productPrimary)' : '#ffffff')};
  border: 2px solid var(--productPrimary);
`;

const StyledCloseDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
`;

export const MoreMenu = ({ open, onClose, options }: MoreMenuProps) => {
  return (
    <Drawer
      placement="bottom"
      open={open}
      onClose={onClose}
      title="More"
      closable={false}
      extra={<CloseOutlined onClick={onClose} style={{ color: 'rgba(0, 0, 0, 0.45)' }} />}
    >
      {options.map((option) => (
        <StyledButton
          onClick={() => {
            option.action();
            onClose();
          }}
          key={option.testid}
          data-testid={option.testid}
          active={option.active}
        >
          <option.Icon aria-hidden />
          <span style={{ marginLeft: '16px' }}>{option.name}</span>
        </StyledButton>
      ))}
      <StyledCloseDiv>
        <Button onClick={onClose} data-testid="close-more-menu-button">
          Close
        </Button>
      </StyledCloseDiv>
    </Drawer>
  );
};
