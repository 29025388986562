import { useEffect, useState } from 'react';
import { TableColumnType } from 'antd';

export type BreakPointName = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export function useReactiveColumns<Row>(tableColumn: TableColumnType<Row>[], currentBreakPoint: BreakPointName) {
  const [displayColumnList, setDisplayColumnList] = useState([] as TableColumnType<Row>[]);
  const [hideColumnList, setHideColumnList] = useState([] as TableColumnType<Row>[]);
  const displayColumns = (lhs: BreakPointName, rhs: BreakPointName) => {
    const largerBreakPoints = {
      xs: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
      sm: ['sm', 'md', 'lg', 'xl', 'xxl'],
      md: ['md', 'lg', 'xl', 'xxl'],
      lg: ['lg', 'xl', 'xxl'],
      xl: ['xl', 'xxl'],
      xxl: ['xxl']
    };
    if (!lhs) {
      return true;
    }
    const larger = largerBreakPoints[lhs];
    return larger.includes(rhs);
  };

  useEffect(() => {
    setDisplayColumnList(tableColumn.filter((item) => displayColumns(item.responsive?.[0], currentBreakPoint)));
    setHideColumnList(tableColumn.filter((item) => !displayColumns(item.responsive?.[0], currentBreakPoint)));
  }, [currentBreakPoint, tableColumn]);

  return [displayColumnList, hideColumnList];
}
export default useReactiveColumns;
