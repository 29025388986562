import { z } from 'zod';

export const TodoSchema = z.object({
  todo_type: z.string(),
  todo_weight: z.number(),
  action_desc: z.string(),
  status: z.string(),
  case_id: z.number(),
  appt_id: z.number().nullable(),
  datetime: z.coerce.date(),
  surveyjs_def_id: z.number().nullable(),
  survey_id: z.number().nullable(),
  spec_cat_code: z.string(),
  spec_area_code: z.string().nullable(),
  appt_type_code: z.string().nullable(),
  user_subtype_code: z.string().nullable(),
  appt_auth_from_date: z.coerce.date().nullable(),
  appt_auth_end_date: z.coerce.date().nullable(),
  clinician_user_guid: z.string().nullable(),
  condition: z.string(),
  test_id: z.string().nullable(),
  survey_guest_editable: z.boolean().nullable(),
  card_details_mandatory: z.boolean().nullable(),
  file_guid: z.string()
});
export type Todo = z.infer<typeof TodoSchema>;
