import getConfig from 'next/config';

export function tryDeriveEndpointName() {
  const { SETTINGS_URL } = process.env;

  return (
    getConfig().publicRuntimeConfig.AWS_ENDPOINT_NAME ??
    SETTINGS_URL?.replace(/(http|https):\/\//g, '').replace(/api\.virtuallucyweb\.co\.uk\/.*/g, '')
  );
}

export const API_ENDPOINT = tryDeriveEndpointName();

export default API_ENDPOINT;
