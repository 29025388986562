import { useEffect, useState } from 'react';

const useZoom = () => {
  const [zoom, setZoom] = useState<number>(0);

  useEffect(() => {
    const handleResize = () => {
      // Explanation on how the zoom is calculated https://www.geeksforgeeks.org/how-to-detect-page-zoom-level-in-all-modern-browsers-using-javascript/
      const zoom = ((window.outerWidth - 10) / window.innerWidth) * 100;
      setZoom(zoom);
    };

    window.addEventListener('resize', () => {
      setTimeout(() => {
        handleResize();
      }, 100);
    });

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return zoom;
};

export default useZoom;
