import * as actions from '@actions';
import * as actionTypes from '@actionTypes';
import { useDispatch } from 'react-redux';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import useIsRestricted from '@hooks/useIsRestricted';

const fetchData = async (dispatch, rows, isRestricted, allow_teams) => {
  if (isRestricted) return [];

  const result = await dispatch(
    actions.getUserAppointment('USER_NEXT_APPOINTMENT', {
      sort: 'datetime',
      rows,
      upcoming: true,
      cancelled: false,
      allow_teams: allow_teams ? 1 : 0
    })
  );

  if (result.type === actionTypes.USER_NEXT_APPOINTMENT_SUCCESS && result.payload) {
    return result.payload;
  }

  // todo - handle errors generically ...
  return [];
};

type Appointment = any;

export default function useUserNextAppointments(
  rows,
  refetchInterval: false | number = false,
  allowTeams = true
): Appointment[] {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const isRestricted = useIsRestricted();
  const result = useQuery<any>(
    ['useUserNextAppointments', rows, allowTeams],
    () => fetchData(dispatch, rows, isRestricted, allowTeams),
    {
      refetchInterval,
      cacheTime: Infinity,
      staleTime: Infinity
    }
  );

  return [
    result.data,
    useCallback(() => queryClient.invalidateQueries(['useUserNextAppointments', rows]), [rows, queryClient])
  ];
}
