import * as actionTypes from '@actionTypes';
import { updateObject } from '@helpers/updateObject';

/* Initial */
export const initialState = {
  loading: false,
  error: null,
  calendar: null
};

/* Request Error */
const requestError = (state, action) =>
  updateObject(state, {
    loading: false,
    action
  });

/* Request Success */
const requestSuccess = (state, action) => updateObject(state, { loading: false, ...action });

/* Reducer */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESCHEDULE_CALENDAR_FAIL:
    case actionTypes.RESCHEDULE_CANCEL_FAIL:
    case actionTypes.RESCHEDULE_CANCELMETA_FAIL:
    case actionTypes.RESCHEDULE_CONFIRM_FAIL:
    case actionTypes.RESCHEDULE_CANCELAPPOINTMENT_TASK_FAIL:
      return requestError(state, null);
    case actionTypes.RESCHEDULE_CALENDAR_SUCCESS:
      return requestSuccess(state, { calendar: action.payload });
    default:
      return state;
  }
};

export default reducer;
