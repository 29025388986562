import { useCallback, useEffect, useState } from 'react';
import useMounted from '@hooks/useMounted';
import { alreadyToasted } from 'vl-common/src/lib/toastUtils';
import { useToaster, toast } from 'react-hot-toast';
import { GlobalStore } from '@store';
import { useDispatch as useLegacyDispatch, useSelector, Provider, useStore } from 'ALIASED-react-redux';
import { ErrorWithResponse } from 'store/actions/action';
import { ThunkAction } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { useNotification } from '../contexts/NotificationContext/NotificationContext';

export interface Dispatch<A extends Action = AnyAction> {
  <T>(thunkAction: ThunkAction<T, any, null, A>, throwToBoundary?: boolean): Promise<T>;
  <Action extends A>(action: Action, throwToBoundary?: boolean): Action;
  <T, Action extends A>(action: Action | ThunkAction<T, any, null, A>, throwToBoundary?: boolean): Action | T;

  setError?: (error: ErrorWithResponse) => void;
}

function useDispatch() {
  const dispatch = useLegacyDispatch();
  const [error, setError] = useState<ErrorWithResponse>();
  const isMounted = useMounted();
  const displayNotification = useNotification();

  const wrappedDispatch = useCallback<Dispatch>(
    (
      // Sneaky any because this argument isn't inferred
      action: any,
      throwToBoundary = true
    ) => {
      // @ts-expect-error - This _is_ putting a setError method on the parent dispatch (not entirely sure why)
      dispatch.setError = setError;

      if (typeof action === 'function') {
        // @ts-expect-error - Regrettable how many ignores are needed here
        action.setError = (a) => {
          if (!throwToBoundary) {
            console.log('%No throw to boundary', 'color: green', a);
            return;
          }

          console.log('%cError Captured', 'color: green', a);

          if (isMounted()) {
            setError(a);
            console.log('%cShould be toasted', 'color: green');
          } else {
            console.log(
              '%cShould be ignored, because the calling component is no longer mounted. It might error anyway.',
              'color: green'
            );
          }
        };
      }

      if (action instanceof Error) {
        if (isMounted()) {
          setError(action as any);
          console.log('%cShould be toasted', 'color: green');
        } else {
          console.log(
            '%cShould be ignored, because the calling component is no longer mounted. It might error anyway.',
            'color: green'
          );
        }

        return Promise.resolve();
      }

      return dispatch(action);
    },
    [dispatch, isMounted]
  );

  wrappedDispatch.setError = setError;

  const { toasts } = useToaster();

  useEffect(() => {
    if (error) {
      // Stops toast errors after logout. See VL2-2174
      if (GlobalStore.getState().auth.hasSignedOut) return;

      console.log('Error Detected in render (good):', error);

      if (alreadyToasted(error, toasts)) {
        console.log('(already toasted)');
        return;
      }

      delete error.stack;
      Object.assign(error, { _suppressLogging: true });
      setError(undefined);

      const originalResponse = error?.response;
      const defaultTextError = 'Something went wrong';
      if (originalResponse?.status >= 400) {
        if (originalResponse.bodyUsed) {
          toast.error(defaultTextError, { duration: Infinity });
          return;
        }
        originalResponse
          ?.clone?.()
          .json()
          .then((json: { [k: string]: any }) => {
            const message = json?.errorMessage || json?.message;
            if (message) {
              displayNotification('error', 'Error', message);
            } else {
              toast.error(defaultTextError, { duration: Infinity });
            }
          })
          .catch((jsonError: Error) => {
            // Handle errors that might occur while parsing JSON
            toast.error(jsonError as any, { duration: Infinity });
          });
      } else {
        toast.error(defaultTextError, { duration: Infinity });
        // throw error;
      }
    }
  }, [error, toasts]);

  return wrappedDispatch;
}

export { useDispatch, useSelector, Provider, useStore };
