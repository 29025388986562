const _themes = {
  aunity: {
    logo: '/static/images/NHS.png',
    stylesheet: '/static/nhs.css'
  },
  axa: {
    logo: '/static/images/AXA.png',
    stylesheet: '/static/axa.css'
  },
  bob: {
    logo: '/static/images/NHS.png',
    stylesheet: '/static/nhs.css'
  },
  dcg: {
    logo: '/static/images/LDC.png',
    stylesheet: '/static/ldc.css'
  },
  demo: {
    logo: '/static/images/NHS.png',
    stylesheet: '/static/nhs.css'
  },
  hbs: {
    logo: '/static/images/HBS.png',
    stylesheet: '/static/hbs.css'
  },
  hbsgast: {
    logo: '/static/images/HBS.png',
    stylesheet: '/static/hbs.css'
  },
  medway: {
    logo: '/static/images/NHS.png',
    stylesheet: '/static/nhs.css'
  },
  helena: {
    logo: '/static/images/STH.png',
    stylesheet: '/static/sth.css'
  }
} as const;

type Themes = {
  [K in keyof typeof _themes]: {
    id: K;
    logo?: string;
    stylesheet: string;
  };
};

export default Array.from(Object.entries(_themes)).reduce((acc, [key, value]) => {
  acc[key] = {
    id: key,
    ...value
  };
  return acc;
}, {} as Themes);
