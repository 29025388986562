// @ts-check
import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { HYDRATE } from 'next-redux-wrapper';
import cliniciansReducer, { initialState as cliniciansInitialState } from './reducers/clinicians';
import patientsReducer, { initialState as patientsInitialState } from './reducers/patients';
import rescheduleReducer, { initialState as rescheduleInitialState } from './reducers/reschedule';
import participantReducer, { initialState as participantInitialState } from './reducers/participant';
import authReducer, { initialState as authInitialState } from './reducers/auth';
import appointmentReducer, { initialState as appointmentInitialState } from './reducers/appointment';

const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== 'production' && typeof window !== 'undefined') {
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

export const initialState = {
  auth: authInitialState,
  patients: patientsInitialState,
  clinicians: cliniciansInitialState,
  reschedule: rescheduleInitialState,
  participant: participantInitialState,
  appointment: appointmentInitialState
};

export type ReduxStore = typeof initialState;

const hydration = (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const appReducer = combineReducers({
  hydration,
  auth: authReducer,
  clinicians: cliniciansReducer,
  patients: patientsReducer,
  reschedule: rescheduleReducer,
  participant: participantReducer,
  appointment: appointmentReducer
});

export const rootReducer = (state, action) => appReducer(state, action);

// eslint-disable-next-line import/no-mutable-exports
export let GlobalStore;

export default function initializeStore(startState = initialState) {
  GlobalStore = createStore(rootReducer, startState, bindMiddleware([thunkMiddleware]));

  if (typeof window !== 'undefined') {
    globalThis.GlobalStore = GlobalStore;
  }

  return GlobalStore;
}
