import { Collapse, Switch } from 'antd';
import React from 'react';

const { Panel } = Collapse;
type cookie = {
  title: string;
  description: string;
  accepted: boolean;
  editable?: boolean;
};

interface CookieListProps {
  cookies: { [key: string]: cookie };
  setCookies: React.Dispatch<React.SetStateAction<{ [key: string]: cookie }>>;
}

const CookieList = ({ cookies, setCookies }: CookieListProps): JSX.Element => {
  const CookieToggle = (cookie: string): JSX.Element => {
    return (
      <Switch
        defaultChecked={cookies[cookie].accepted}
        disabled={!cookies[cookie].editable}
        onChange={(checked, e) => {
          e.stopPropagation();

          const updatedCookies = { ...cookies };
          updatedCookies[cookie].accepted = checked;

          setCookies({ ...updatedCookies });
        }}
      />
    );
  };
  return (
    <>
      <Collapse accordion>
        {Object.keys(cookies).map((cookie) => {
          return (
            <Panel key={cookie} header={`${cookies[cookie]?.title} Cookies`} extra={CookieToggle(cookie)}>
              {cookies[cookie]?.description}
            </Panel>
          );
        })}
      </Collapse>
    </>
  );
};

export default CookieList;
