import React from 'react';
import { Modal, Result, Button, ModalFuncProps, ModalProps, ResultProps } from 'antd';

type ErrorModalProps = Omit<ModalProps, 'onOk' | 'footer'> & {
  'data-testid'?: String;
  subTitle?: React.ReactNode;
  resultProps?: Omit<ResultProps, 'extra' | 'status' | 'subtitle'>;
};

export const ErrorModal = ({
  title,
  subTitle = null,
  onCancel,
  cancelText = 'OK',
  'data-testid': dataTestid = '',
  resultProps,
  ...rest
}: ErrorModalProps) => (
  <Modal centered footer={null} closable={false} onCancel={onCancel} {...rest}>
    <Result
      status="error"
      title={title}
      subTitle={subTitle}
      extra={[
        <Button key="submit" onClick={onCancel} data-testid={dataTestid}>
          {cancelText}
        </Button>
      ]}
      {...resultProps}
    />
  </Modal>
);

export const errorModal = ({ ...otherProps }: ModalFuncProps) =>
  Modal.error({
    centered: true,
    ...otherProps
  });
