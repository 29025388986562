import React from 'react';
import { toast, ToastBar, Toaster } from 'react-hot-toast';
import { toastDismissed } from 'vl-common/src/lib/toastUtils';

export const DismissibleToast = () => (
  <div>
    <Toaster
      reverseOrder={false}
      position="top-center"
      toastOptions={{
        style: {
          borderRadius: '8px',
          background: '#333',
          color: '#fff',
          maxWidth: '90vw'
        }
      }}
    >
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => {
            console.log(message);
            return (
              <div
                role="button"
                tabIndex={-1}
                style={{ display: 'flex', flexDirection: 'row' }}
                onClick={() => {
                  if (t.type !== 'loading') {
                    toast.dismiss(t.id);
                    toastDismissed(t);
                  }
                }}
              >
                {icon}
                {message}
              </div>
            );
          }}
        </ToastBar>
      )}
    </Toaster>
  </div>
);
