// file.only
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { PartyStatus } from '@hooks/useOtherPartyStatus';
import { useTwilioWS } from '@hooks/twilioWS';
import useMyRole from '@hooks/useMyRole';
import { useDispatch } from 'react-redux';
import * as actions from '@actions';
import { getLogger } from 'loglevel';
import { useCancelledAppointments } from '@hooks/useCancelledAppointments';
import useAppointmentStatus from '@hooks/useAppointmentStatus';

const log = getLogger(__filename);

/**
 * Tracks changes to my status (either "connected" or "in-session") and notifies the
 * other party via the WebSocket.
 *
 * @param appointment
 * @returns status
 */
function useMyStatusUpdater(appointment, disabled?: boolean): PartyStatus {
  const { appointment_id, patient_user_guid, clinician_user_guid } = appointment;
  const router = useRouter();
  const { pathname, query } = router;
  const { clinicianStatus } = useAppointmentStatus(appointment);
  const [status, setStatus] = useState<PartyStatus>(clinicianStatus.status);
  const role = useMyRole();
  const user_guid = role === 'clinician' ? clinician_user_guid : patient_user_guid;
  const dispatch = useDispatch();
  const { sendControlMessage, client, connected } = useTwilioWS(appointment);
  const { cancelledAppointments } = useCancelledAppointments();

  useEffect(() => {
    if (disabled) return;

    async function update(status) {
      setStatus(status);
      if (client.readyState === client.OPEN) {
        sendControlMessage({ partyStatus: status, iam: role });
      } else {
        log.log('Closed!');
      }
    }

    if (client && connected && client.readyState === client.OPEN) {
      if (pathname.endsWith('/session') && query.req) {
        const id = Buffer.from(`${query.req}`, 'base64').toString();

        if (id === appointment_id.toString()) {
          update('in-session').then();
          return;
        }
      }
      update('connected').then();
    }
  }, [appointment_id, pathname, query, dispatch, user_guid, sendControlMessage, role, client, connected]);

  useEffect(() => {
    if (disabled) return;

    async function updateStatus() {
      await dispatch(
        actions.sendTwilioEvent(user_guid, appointment_id, status === 'in-session' ? 'USRWAIT' : 'USRPOPUP')
      ).catch((err) => console.error('sendTwilioEvent fails in useMyStatusUpdater', err));
    }

    // don't bother updating the status unless it changes
    if (!cancelledAppointments[appointment_id] && clinicianStatus.status !== status) {
      updateStatus().then();
    }
  }, [appointment_id, cancelledAppointments, dispatch, status, user_guid, clinicianStatus.status]);

  return status;
}

export default useMyStatusUpdater;
