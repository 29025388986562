import { useRouter } from 'next/router';

type Role = 'clinician' | 'patient';

/**
 * @returns role either 'clinician' or 'patient'
 */
function useMyRole(): Role {
  const { pathname } = useRouter();

  return pathname.startsWith('/clinician') ? 'clinician' : 'patient';
}

export default useMyRole;
